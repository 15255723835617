import { getSingleCompany } from '../../services/companyService';


export async function getCompanyDetails(dispatch, id) {
  try {
    dispatch({ type: 'REQUEST_COMPANY_DETAILS' });
    let response = await getSingleCompany(id);
  
    if (response.data) {
      dispatch({ type: 'COMPANY_DETAILS_SUCCESS', payload: response.data });
      return response.data;
    }
  
    dispatch({ type: 'COMPANY_DETAILS_ERROR', error: response.data.errors[0] });
    return;
  } catch (error) {
    dispatch({ type: 'COMPANY_DETAILS_ERROR', error: error });    
  }
}
  