import React, { useEffect, useState } from "react";
import MockupLayout from "../../../../Components/MockupLayout";
import CustomAccordion from "../../../../Components/CustomAccordion";
import { Form, FormGroup, Row } from "react-bootstrap";
import { getQuoteDetails, useQuotesState, useAccountState, getTripSettings } from "../../../../Context";
import SimpleTable from "../../../../Components/SimpleTable";
import { formatCurrency, totalAmountValues } from "../../../../utils/helper";
import ErrorMessage from "../../../../Components/ErrorMessage";
import Loading from "../../../../Components/Loading";
import * as dayjs from "dayjs";
import moment from 'moment';
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
dayjs.extend(utc)
dayjs.extend(timezone)

const QuoteDetails = (props) => {
    const quoteId = props.match.params.id;
    const [collapseItem, handleCollapse] = useState({ 1: false })
    const [extraInformation, setExtraInformation] = useState({
        totalAvailableCapacity: 0,
        totalMiles: 0,
        quotationTotal: 0,
        address: "",
        passengers: 0
    });
    const { quotesDispatch, quotes: { quoteDetails, loading, errorMessage } } = useQuotesState();
    const { AccountsDispatch, tripSettings: { tripSettings } } = useAccountState();

    useEffect(() => {
        (async () => {
            await getTripSettings(AccountsDispatch);
            let details = await getQuoteDetails(quotesDispatch, quoteId);
            if (details) {
                let information = {
                    totalAvailableCapacity: 0,
                    totalMiles: 0,
                    quotationTotal: 0,
                    address: "",
                    passengers: 0
                };
                information.totalMiles = (details.segments.reduce((totalMiles, segment) => totalMiles + Number(segment.miles), 0)).toFixed(2);
                let addonTotal = details.addonRequests.reduce((addonTotal, addon) => addonTotal + Number(addon.total), 0);
                let vehicleTotal = details.vehicleRequests.reduce((vehicleTotal, vehicle) => vehicleTotal + Number(vehicle.total), 0);
                information.quotationTotal = addonTotal + vehicleTotal;
                information.totalAvailableCapacity = details.vehicleRequests.reduce((totalCapacity, vehicle) => totalCapacity + (Number(vehicle.quantity) * Number(vehicle.capacity)), 0);
                let customerAddress = [details.customerAddressStreet1, details.customerAddressStreet2, details.customerAddressState, details.customerAddressCity, details.customerAddressZip].filter(value => value).join(', ');
                information.address = customerAddress;
                information.passengers = details.passengers
                setExtraInformation(information);
            }
        })()
    }, [quotesDispatch, quoteId, AccountsDispatch])

    const handleItemCollapse = (e) => {
        const { id } = e.target
        handleCollapse({ ...collapseItem, [id]: !collapseItem[id] })
    }

    useEffect(() => {
        quoteDetails.segments && quoteDetails.segments.sort(function (a, b) {
            let keyA = moment(a.departureTime),
                keyB = moment(b.departureTime);
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
        });
    }, [quoteDetails.segments]);

    const renderTotal = (item) => {
        return `$${formatCurrency(Number(item["total"]))}`;
    }

    return (
        <MockupLayout
            title={`Quote ${quoteDetails.qbReferenceNumberRID ? `${quoteDetails.qbReferenceNumberRID}-${quoteDetails.refCount}` : 'Details'}`}
            titleIconClass={"fas fa-file-alt"}
            textColor={tripSettings?.textColor}
        >
            {
                errorMessage ? <ErrorMessage message={errorMessage} /> : null
            }

            <CustomAccordion
                id={1}
                title="Trip Details"
                collapseItem={collapseItem}
                handleItemCollapse={handleItemCollapse}
                sectionColor={tripSettings?.sectionColor}
                className="px-2"
            >
                <Row className="mx-1">
                    <dl className="col-12 details-list">
                        {/*Trip Reference*/}
                        <FormGroup>
                            <Form.Label>Trip Reference</Form.Label>
                            <Form.Control
                                type="text"
                                value={quoteDetails.tripReference ? quoteDetails.tripReference : 0}
                                readOnly
                            />
                        </FormGroup>
                    </dl>
                    <dl className="col-12 col-md-4 details-list">
                        {/*Quotation Total*/}
                        <FormGroup>
                            <Form.Label>Quotation Total</Form.Label>
                            <Form.Control
                                type="text"
                                value={extraInformation.quotationTotal ? `$${formatCurrency(Number(extraInformation.quotationTotal))}` : ""}
                                readOnly
                            />
                        </FormGroup>
                    </dl>
                    <dl className="col-12 col-md-4 details-list">
                        {/*Salesperson*/}
                        <FormGroup>
                            <Form.Label>Salesperson</Form.Label>
                            <Form.Control
                                type="text"
                                value={quoteDetails.SalesPerson ? quoteDetails.SalesPerson : ""}
                                readOnly
                            />
                        </FormGroup>
                    </dl>
                    <dl className="col-12 col-md-4 details-list">
                        {/*Total Miles*/}
                        <FormGroup>
                            <Form.Label>Total Miles</Form.Label>
                            <Form.Control
                                type="text"
                                value={extraInformation.totalMiles ? extraInformation.totalMiles : ""}
                                readOnly
                            />
                        </FormGroup>
                    </dl>
                </Row>

                <SimpleTable
                    loading={loading}
                    onClick={() => { }}
                    data={quoteDetails.segments}
                    mainColor={tripSettings?.mainColor}
                    bordered={false}
                    wrap={true}
                    showFooter={false}
                    fieldNames={[
                        ["startAddress", "Start Address"],
                        ["departureTime", "Departure Time"],
                        ["destinationAddress", "Destination Address"],
                        ["arrivalTime", "Arrival Time"],
                    ]}
                />
            </CustomAccordion>

            <CustomAccordion
                id={2}
                title="Vehicle Information"
                collapseItem={collapseItem}
                handleItemCollapse={handleItemCollapse}
                sectionColor={tripSettings?.sectionColor}
            >
                <Row className="mx-1">
                    <dl className="col-6 col-md-4 details-list">
                        {/*Total Passengers*/}
                        <FormGroup>
                            <Form.Label>Passengers</Form.Label>
                            <Form.Control
                                type="text"
                                value={extraInformation.passengers ? extraInformation.passengers : ""}
                                readOnly
                            />
                        </FormGroup>
                    </dl>
                    <dl className="col-6 col-md-4 details-list">
                        {/*Total Available Capacity*/}
                        <FormGroup>
                            <Form.Label>Total Available Capacity</Form.Label>
                            <Form.Control
                                type="text"
                                value={extraInformation.totalAvailableCapacity ? extraInformation.totalAvailableCapacity : ""}
                                readOnly
                            />
                        </FormGroup>
                    </dl>
                </Row>
                <SimpleTable
                    loading={loading}
                    onClick={() => { }}
                    data={quoteDetails.vehicleRequests}
                    mainColor={tripSettings?.mainColor}
                    bordered={false}
                    showFooter={true}
                    fieldNames={[
                        ["displayName", "Vehicle Description", "", "TOTAL"],
                        ["quantity", "Quantity"],
                        ["capacity", "Available Capacity"],
                        ["total", "Total", renderTotal, totalAmountValues(quoteDetails.vehicleRequests, 'total')]
                    ]}
                />
            </CustomAccordion>

            <CustomAccordion
                id={3}
                title="Addons"
                collapseItem={collapseItem}
                handleItemCollapse={handleItemCollapse}
                sectionColor={tripSettings?.sectionColor}
            >
                <SimpleTable
                    loading={loading}
                    onClick={() => { }}
                    data={quoteDetails.addonRequests}
                    mainColor={tripSettings?.mainColor}
                    bordered={false}
                    showFooter={true}
                    fieldNames={[
                        ["AddonType", "Description", "", "TOTAL"],
                        ["quantity", "Quantity"],
                        ["total", "Total", renderTotal, totalAmountValues(quoteDetails.addonRequests, 'total')]
                    ]}
                />
            </CustomAccordion>


            <Loading loading={loading} />
        </MockupLayout>
    )
}

export default QuoteDetails;
