import React, { useEffect, useState } from "react";
import MockupLayout from "../../../../Components/MockupLayout";
import CustomAccordion from "../../../../Components/CustomAccordion";
import SimpleTable from "../../../../Components/SimpleTable";
import { formatCurrency, totalAmountValues } from "../../../../utils/helper";
import { getInvoices, useInvoicesState, filterInvoices, getTripSettings, useAccountState } from "../../../../Context";
import "./index.scss"
import { getCustomerDetails } from "../../../../config";
import { Col, Form, Row } from "react-bootstrap";
import AddTransaction from "../../../../Components/AddTransaction";
import Loading from "../../../../Components/Loading";
import moment from "moment";

const MockupInvoices = ({ history }) => {
    const [collapseItem, handleCollapse] = useState({ 1: false })
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const { invoice: { invoices, invoicesCopy }, invoiceDispatch } = useInvoicesState()
    const { AccountsDispatch, tripSettings: { tripSettings } } = useAccountState();
    const styles = { cancelled: { backgroundColor: "rgb(240, 128, 128)" } }
    const user = getCustomerDetails()
    const [invoiceSelectedFilter, setInvoiceSelectedFilter] = useState("last30Days");
    const [loading, setLoading] = useState(false);


    let InvoicefilterOptions = [
        { title: "Today", value: "today" },
        { title: "Yesterday", value: "yesterday" },
        { title: "Last 7 Days", value: "last7Days" },
        { title: "Last 30 Days", value: "last30Days" },
        { title: "Last 60 Days", value: "last60Days" },
        { title: "Last 180 Days", value: "last180Days" },
        { title: "All Time", value: "allTime" },
    ]

    useEffect(() => {
        (async () => {
            setLoading(true)
            await getTripSettings(AccountsDispatch);
            await getInvoices(invoiceDispatch);
            setLoading(false)
        })()
    }, [AccountsDispatch, invoiceDispatch])

    useEffect(() => {
        filterInvoices(invoiceDispatch, invoicesCopy, invoiceSelectedFilter);
    }, [invoiceDispatch, invoiceSelectedFilter, invoicesCopy]);

    const refreshInvoices = async () => {
        setLoading(true)
        await getInvoices(invoiceDispatch);
        setLoading(false)
    }

    const rowStyle = (item) => {
        return styles[item.cancelledAt ? "cancelled" : ""];
    }

    const handleItemCollapse = (e) => {
        const { id } = e.target
        handleCollapse({ ...collapseItem, [id]: !collapseItem[id] })
    }

    const renderTotal = (item, key) => {
        return item[key] ? `$${formatCurrency(Number(item[key]))}` : "$0.00";
    }

    // const onPaymentClick = (e, item) => {
    //     e.stopPropagation();
    //     setSelectedInvoice(item);
    // }

    const onPaymentClick = (e, item) => {
        window.open(`${window.location.origin}/p/pay-invoice?ts=${moment(item.createdAt).unix()}&invoiceId=${item.id}`, "_blank")
    }

    const onInvoiceFilterChange = (e) => {
        filterInvoices(invoiceDispatch, invoicesCopy, e.target.value)
        setInvoiceSelectedFilter(e.target.value)
    }

    const renderMakePayment = (item) => {
        return item.invoicedAmount - item.paidAmount > 0 && !item.disableOnlinePayment ? <i className="fa fa-credit-card" onClick={(e) => onPaymentClick(e, item)} /> : "";
    }
    const fieldNames = [
        ["invoiceNumber", "Invoice Number", "", "TOT"],
        ["invoiceTotal", "Invoice Total", ((item) => renderTotal(item, "invoiceTotal")), totalAmountValues(invoices, 'invoiceTotal')],
        ["date", "Date"],
        ["makePayment", "Make Payment", renderMakePayment]
    ]
    if (user.showParentCompany) {
        fieldNames.unshift(["parentCompany", "Parent Company"])
        fieldNames.unshift(["company", "Company"])
    } else if (user.showCompany) {
        fieldNames.unshift(["company", "Company"])
    }

    return (
        tripSettings ?
            <MockupLayout
                title={"Invoices"}
                titleIconClass={"fas fa-file-invoice"}
                textColor={tripSettings?.textColor}
            >
                <CustomAccordion
                    id={1}
                    title={"Invoices"}
                    collapseItem={collapseItem}
                    handleItemCollapse={handleItemCollapse}
                    sectionColor={tripSettings?.sectionColor}
                >
                    <Row>
                        <Col>
                            <Form.Control
                                as="select"
                                value={invoiceSelectedFilter}
                                className="w-25 mb-2"
                                onChange={onInvoiceFilterChange}
                            >
                                {InvoicefilterOptions.map(filter => <option key={filter.value} value={filter.value}>{filter.title}</option>)}

                            </Form.Control>
                        </Col>
                    </Row>
                    <SimpleTable
                        fieldNames={fieldNames}
                        rowStyle={rowStyle}
                        data={invoices.filter(item => !item?.isVoided)}
                        showFooter={true}
                        bordered={true}
                        loading={loading}
                        mainColor={tripSettings?.mainColor}
                    />
                </CustomAccordion>
                {
                    (selectedInvoice && selectedInvoice.id) &&
                    <AddTransaction
                        invoiceId={selectedInvoice.id}
                        invoiceDetails={selectedInvoice}
                        onCloseClick={() => setSelectedInvoice(null)}
                        refreshInvoices={refreshInvoices}
                    />
                }
            </MockupLayout> :
            <Loading />
    )
}

export default MockupInvoices;