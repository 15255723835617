export const initialStateGeneralLedgerCode = {
    generalLedgerCodes: [],
    loading: false,
    errorMessage: null
};

export const GeneralLedgerCodeReducer = (state = initialStateGeneralLedgerCode, action) => {
    switch (action.type) {
        case "REQUEST_FETCH_GENERAL_LEDGER_CODES": {
            return {
                ...state,
                loading: true
            }
        }
        case "FETCH_GENERAL_LEDGER_CODES_SUCCESS": {
            return {
                ...state,
                generalLedgerCodes: action.payload,
                loading: false
            }
        }
        case "FETCH_GENERAL_LEDGER_CODES_ERROR": {
            return {
                ...state,
                errorMessage: action.error,
                loading: false
            }
        }
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};