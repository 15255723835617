import React, { useEffect, useState } from "react";
import MockupLayout from "../../../../Components/MockupLayout";
import CustomAccordion from "../../../../Components/CustomAccordion";
import SimpleTable from "../../../../Components/SimpleTable";
import PaymentDialog from "../../../../Components/PaymentDialog";
import Loading from "../../../../Components/Loading";
import { totalAmountValues, formatCurrency } from "../../../../utils/helper";
import {
    useGeneralLedgerCodeState, useBookingState, getBookings, getGeneralLedgerCodes,
    getTripSettings, useAccountState
} from "../../../../Context";
import "./index.scss"
import { getCustomerDetails } from "../../../../config";
import { Form } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
const MockupBookings = ({ history }) => {
    const [collapseItem, handleCollapse] = useState({ 1: false })
    const { bookingsDispatch, bookings: { priorBookings, upcomingBookings } } = useBookingState();
    const { generalLedgerCodeDispatch, generalLedgerCode: { generalLedgerCodes } } = useGeneralLedgerCodeState();
    const { AccountsDispatch, tripSettings: { tripSettings } } = useAccountState();
    const [upcomingFilter, setUpcomingFilter] = useState("next30Days")
    const [pastFilter, setPastFilter] = useState("last30Days")
    const [selectedBooking, setSelectedBooking] = useState(null);
    const [loading, setLoading] = useState(false);
    const styles = { cancelled: { backgroundColor: "rgb(240, 128, 128)" } }
    const user = getCustomerDetails()


    useEffect(() => {
        (async () => {
            setLoading(true)
            await getTripSettings(AccountsDispatch);
            await getBookings(bookingsDispatch, upcomingFilter, pastFilter);
            await getGeneralLedgerCodes(generalLedgerCodeDispatch);
            setLoading(false)
        })()
    }, [bookingsDispatch, generalLedgerCodeDispatch, upcomingFilter, pastFilter, AccountsDispatch])

    const rowStyle = (item) => {
        return styles[item.cancelledAt ? "cancelled" : ""];
    }
    const handleItemCollapse = (e) => {
        const { id } = e.target
        handleCollapse({ ...collapseItem, [id]: !collapseItem[id] })
    }

    const refreshBookings = async () => {
        await getBookings(bookingsDispatch);
    }

    const renderTotal = (item, key) => {
        return item[key] ? `$${formatCurrency(Number(item[key]))}` : "$0.00";
    }

    const onRowClick = (item) => {
        history.push(`/mockup/bookings/${item.id}`)
    }
    const fieldNamesUpcoming = [
        ["bookingId", "Booking #", "", "TOT"],
        ["tripReference", "Trip Reference"],
        ["vehiclesTotal", "# Of Vehicles"],
        ["departureTime", "Earliest Departure Date"],
        ["totalAfterTax", "Booking Total", (item) => renderTotal(item, 'totalAfterTax'), totalAmountValues(upcomingBookings, 'totalAfterTax')],
        ["amountOutstanding", "Amount Outstanding", (item) => renderTotal(item, "amountOutstanding"), totalAmountValues(upcomingBookings, 'amountOutstanding')],
        //["makePayment", "Make Payment", renderMakePayment]
    ]
    const fieldNamesPrior = [
        ["bookingId", "Booking #", "", "TOT"],
        ["tripReference", "Trip Reference"],
        ["vehiclesTotal", "# Of Vehicles"],
        ["departureTime", "Earliest Departure Date"],
        ["totalAfterTax", "Booking Total", (item) => renderTotal(item, 'totalAfterTax'), totalAmountValues(priorBookings, 'totalAfterTax')],
        ["amountOutstanding", "Amount Outstanding", (item) => renderTotal(item, "amountOutstanding"), totalAmountValues(priorBookings, 'amountOutstanding')],
        //["makePayment", "Make Payment", renderMakePayment]
    ]
    if (user.showParentCompany) {

        fieldNamesPrior.unshift(["parentCompany", "Parent Company"])
        fieldNamesPrior.unshift(["company", "Company"])
        fieldNamesUpcoming.unshift(["parentCompany", "Parent Company"])
        fieldNamesUpcoming.unshift(["company", "Company"])
    } else if (user.showCompany) {
        fieldNamesPrior.unshift(["company", "Company"])
        fieldNamesUpcoming.unshift(["company", "Company"])
    }

    return (
        !loading && tripSettings ?
            <div className="w-100 content-section">

                <div className="px-3 mt-2">

                    <Tabs
                        defaultActiveKey="Upcoming"
                        id="uncontrolled-tab-example-2"
                        className="inner-tabs"
                    >
                        <Tab eventKey="Upcoming" title="Upcoming">
                            <div className='d-flex align-items-center mb-2' >
                                <Form.Control
                                    as="select"
                                    value={upcomingFilter}
                                    className="w-50"
                                    onChange={(e) => setUpcomingFilter(e.target.value)}
                                >
                                    <option value="today">Today</option>
                                    <option value="tommorow">Tomorrow</option>
                                    <option value="next7Days">Next 7 Days</option>
                                    <option value="next30Days">Next 30 Days</option>
                                    <option value="next60Days">Next 60 Days</option>
                                    <option value="next180Days">Next 180 Days</option>
                                    <option value="allTime">All Time</option>
                                </Form.Control>
                            </div>
                            {upcomingBookings.map((item) => (
                                <div className='d-flex list-div mt-2' onClick={() => { onRowClick(item) }}>

                                    <div className='col-8'>
                                        <div style={{ ...rowStyle(item) }} className={`color-line`}>

                                        </div>
                                        <div className='card-l'>
                                            <span className='card-lt'>{item.bookingId}</span>
                                            <span className='card-lb'>{item.departureTime}</span>
                                        </div>
                                    </div>
                                    <div className='col-4 text-right'>
                                        <div className='card-r'>
                                            <span className='card-lt q-total'>{renderTotal(item, "amountOutstanding")}</span>
                                            <span className='card-rb'>{item.vehiclesTotal} Vehicle(s)</span></div>
                                    </div>

                                </div>
                            ))}
                            {upcomingBookings.length === 0 ? <div className="text-center mt-2 not-found">No Data Found</div> : ``}

                        </Tab>
                        <Tab eventKey="Past" title="Past" >
                            <div className='d-flex align-items-center mb-2' >
                                <Form.Control
                                    as="select"
                                    value={pastFilter}
                                    className="w-50"
                                    onChange={(e) => setPastFilter(e.target.value)}
                                >
                                    <option value="today">Today</option>
                                    <option value="yesterday">Yesterday</option>
                                    <option value="last7Days">Last 7 Days</option>
                                    <option value="last30Days">Last 30 Days</option>
                                    <option value="last60Days">Last 60 Days</option>
                                    <option value="last180Days">Last 180 Days</option>
                                    <option value="allTime">All Time</option>
                                </Form.Control>
                            </div>
                            {priorBookings.map((item) => (
                                <div className='d-flex list-div mt-2' onClick={() => { onRowClick(item) }}>

                                    <div className='col-8'>
                                        <div style={{ ...rowStyle(item) }} className={`color-line`}>

                                        </div>
                                        <div className='card-l'>
                                            <span className='card-lt'>{item.bookingId}</span>
                                            <span className='card-lb'>{item.departureTime}</span>
                                        </div>
                                    </div>
                                    <div className='col-4 text-right'>
                                        <div className='card-r'>
                                            <span className='card-lt q-total'>{renderTotal(item, "amountOutstanding")}</span>
                                            <span className='card-rb'>{item.vehiclesTotal} Vehicle(s)</span></div>
                                    </div>

                                </div>
                            ))}
                            {priorBookings.length === 0 ? <div className="text-center mt-2 not-found">No Data Found</div> : ``}

                        </Tab>

                    </Tabs>


                    {
                        (selectedBooking && selectedBooking.id) &&
                        <PaymentDialog
                            id={selectedBooking.id}
                            bookingId={selectedBooking.bookingId}
                            amountOutstanding={selectedBooking.amountOutstanding}
                            generalLedgerCodes={generalLedgerCodes}
                            onCloseClick={() => setSelectedBooking(null)}
                            refreshBookings={refreshBookings}
                            total={selectedBooking.totalAfterTax}
                        />
                    }
                </div>
            </div>
            : <Loading loading={loading} />
    )
}

export default MockupBookings;