export const initialStateCompanies = {
    companies: [],
    companyDetails: {},
    loading: false,
    errorMessage: null
  };
   
  export const CompanyReducer = (state = initialStateCompanies, action) => {
    switch (action.type) {
      case "GET_COMPANIES_SUCCESS":
        return {
          ...state,
          companies: action.payload,
          loading: false
        };
      
      case "GET_COMPANIES_ERROR":
        return {
          ...state,
          loading: false,
          errorMessage: action.error
        };

      case "ADD_COMPANY_SUCCESS":
        const comapanyList = [...state.companies];
        comapanyList.push(action.payload);
        return {
          ...state,
          loading: false,
          companies: comapanyList
        };
          
      case "ADD_COMPANY_ERROR":
        return {
          ...state,
          loading: false,
          errorMessage: action.error
        };
      
      case "UPDATE_COMPANY_SUCCESS":
        const comapanyDetails = [...state.companies];
        return {
          ...state,
          loading: false,
          companies: comapanyDetails,
          companyDetails:action.payload
        };
          
      case "UPDATE_COMPANY_ERROR":
        return {
          ...state,
          loading: false,
          errorMessage: action.error
        };
      
      case "DELETE_COMPANY_SUCCESS":
        const companyInfo = [...state.companies];
        const idx = companyInfo.findIndex((c) => c.id === action.payload);
        if(idx !== -1) {
          companyInfo.splice(idx, 1);
        }
        return {
          ...state,
          companies: companyInfo,
          loading: false
        };
  
      case "DELETE_COMPANY_ERROR":
        return {
          ...state,
          loading: false,
          errorMessage: action.error
        };

      case "REQUEST_COMPANY_DETAILS":
        return {
          ...state,
          loading: true,
          companyDetails: {}
        }

      case "COMPANY_DETAILS_SUCCESS":
        return {
          ...state,
          loading: false,
          companyDetails: action.payload
        }

      case "COMPANY_DETAILS_ERROR":
        return {
          ...state,
          loading: false,
          errorMessage: action.error
        }

      default:
        throw new Error(`Unhandled action type: ${action.type}`);
    }
  };