import service from './baseService';
import { Service } from './baseService';

export const makePaymentService = (data, companyID) => {
    let params = { };
    params.companyID = companyID;    
    return service.post(`/makePayment`, data, { params });
};

export const makeBookingPortalPaymentService = (data, companyID) => {
    let params = {};
    params.companyID = companyID;  
    return Service.post(`/customer/makePayment`, data, { params });
};


