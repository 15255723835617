import { createContext, useContext } from "react";

export const MockupThemeContext = createContext();
export const AuthStateContext = createContext();
export const BookingContext = createContext();
export const QuotesContext = createContext();
export const GeneralLedgerCodeContext = createContext();
export const TransactionsContext = createContext();
export const VehicleAssignmentsContext = createContext();
export const InvoicesContext = createContext();
export const TransactionTypesContext = createContext();
export const AccountsContext = createContext();
export const MakePaymentContext = createContext();
export const CompanyContext = createContext();

export function useMockupThemeState() {
    const context = useContext(MockupThemeContext);
    if(context === undefined) {
        throw new Error("useMockupThemeState must be within an AuthProvider");
    }
    return context;
}

export function useAuthState() {
    const context = useContext(AuthStateContext);
    if (context === undefined) {
      throw new Error("useAuthState must be used within a AuthProvider");
    }
   
    return context;
}

export function useBookingState() {
    const context = useContext(BookingContext);
    if(context === undefined) {
        throw new Error('useBookingState must be within a AuthProvider');
    }
    return context;
}

export function useQuotesState() {
    const context = useContext(QuotesContext);
    if(context === undefined) {
        throw new Error("useQuotesState must be within an AuthProvider");
    }
    return context;
}

export function useGeneralLedgerCodeState() {
    const context = useContext(GeneralLedgerCodeContext);
    if(context === undefined) {
        throw new Error("useGeneralLedgerCodeState must be within an AuthProvider");
    }
    return context;
}

export function useTransactionsState() {
    const context = useContext(TransactionsContext);
    if(!context) throw new Error("useTransactionsState must be within an AuthProvider");
    return context;
}

export function useVehicleAssignmentsState() {
    const context = useContext(VehicleAssignmentsContext);
    if(!context) throw new Error("useVehicleAssignmentsState must be within an AuthProvider");
    return context;
}

export function useInvoicesState() {
    const context = useContext(InvoicesContext);
    if(!context) throw new Error("useInvoicesState must be within an AuthProvider");
    return context;
}

export function useTransactionTypesState() {
    const context = useContext(TransactionTypesContext);
    if (!context) {
      throw new Error("useTransactionTypesState must be within an AuthProvider");
    }
    return context;
  }
  
  export function useAccountState() {
    const context = useContext(AccountsContext);
    if (context === undefined) {
      throw new Error("useAccountState must be used within a AuthProvider");
    }
  
    return context;
  }

  export function useMakePaymentState() {
    const context = useContext(MakePaymentContext);
    if (context === undefined) {
      throw new Error("useMakePaymentState must be used within a AuthProvider");
    }
    return context;
  }
  
  export function useCompanyState() {
    const context = useContext(CompanyContext);
    if (context === undefined) {
      throw new Error("useCompanyState must be used within a AuthProvider");
    }
  
    return context;
  }