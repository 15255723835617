import { Service } from './baseService';
import { getEnterpriseId } from '../config';

export const loginService = (credentials) => {
    return Service.post('/customer-auth/login', credentials, { params: { enterpriseId: getEnterpriseId() } });
}

export const getContactService = (data) => {
    return Service.post('/customer-auth/get-contact', data, { params: { enterpriseId: getEnterpriseId() } });
}

export const updateContactService = (data, contactId) => {
    return Service.put(`/customer-auth/update-contact/${contactId}`, data, { params: { enterpriseId: getEnterpriseId() } });
}

export const checkPassword = (data, id) => {
    return Service.post(`/customer-auth/check-password/${id}`, { data });
}

export const sendForgetPasswordEmailService = (payload) => {
    return Service.post('/customer-auth/forgot-password-mail', payload);
};
export const loginImageService = () => {
    return Service.get('/auth/login-image');
};