import React, { useEffect, useState } from "react";
import MockupLayout from "../../../../Components/MockupLayout";
import CustomAccordion from "../../../../Components/CustomAccordion";
import { Form, FormGroup, Row, Button } from "react-bootstrap";
import { getQuoteDetails, useQuotesState, useAccountState, getTripSettings } from "../../../../Context";
import SimpleTable from "../../../../Components/SimpleTable";
import { formatCurrency, totalAmountValues } from "../../../../utils/helper";
import ErrorMessage from "../../../../Components/ErrorMessage";
import Loading from "../../../../Components/Loading";
import * as dayjs from "dayjs";
import moment from 'moment';
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import "./style.scss";
dayjs.extend(utc)
dayjs.extend(timezone)

const QuoteDetails = (props) => {
    const quoteId = props.match.params.id;
    const [collapseItem, handleCollapse] = useState({ 1: false })
    const [extraInformation, setExtraInformation] = useState({
        totalAvailableCapacity: 0,
        totalMiles: 0,
        quotationTotal: 0,
        address: "",
        passengers: 0
    });
    const { quotesDispatch, quotes: { quoteDetails, loading, errorMessage } } = useQuotesState();
    const { AccountsDispatch, tripSettings: { tripSettings } } = useAccountState();

    useEffect(() => {
        (async () => {
            await getTripSettings(AccountsDispatch);
            let details = await getQuoteDetails(quotesDispatch, quoteId);
            if (details) {
                let information = {
                    totalAvailableCapacity: 0,
                    totalMiles: 0,
                    quotationTotal: 0,
                    address: "",
                    passengers: 0
                };
                information.totalMiles = (details.segments.reduce((totalMiles, segment) => totalMiles + Number(segment.miles), 0)).toFixed(2);
                let addonTotal = details.addonRequests.reduce((addonTotal, addon) => addonTotal + Number(addon.total), 0);
                let vehicleTotal = details.vehicleRequests.reduce((vehicleTotal, vehicle) => vehicleTotal + Number(vehicle.total), 0);
                information.quotationTotal = addonTotal + vehicleTotal;
                information.totalAvailableCapacity = details.vehicleRequests.reduce((totalCapacity, vehicle) => totalCapacity + (Number(vehicle.quantity) * Number(vehicle.capacity)), 0);
                let customerAddress = [details.customerAddressStreet1, details.customerAddressStreet2, details.customerAddressState, details.customerAddressCity, details.customerAddressZip].filter(value => value).join(', ');
                information.address = customerAddress;
                information.passengers = details.passengers
                setExtraInformation(information);
            }
        })()
    }, [quotesDispatch, quoteId, AccountsDispatch])

    const handleItemCollapse = (e) => {
        const { id } = e.target
        handleCollapse({ ...collapseItem, [id]: !collapseItem[id] })
    }

    useEffect(() => {
        quoteDetails.segments && quoteDetails.segments.sort(function (a, b) {
            let keyA = moment(a.departureTime),
                keyB = moment(b.departureTime);
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
        });
    }, [quoteDetails.segments]);

    const renderTotal = (item) => {
        return `$${formatCurrency(Number(item["total"]))}`;
    }

    return (
        <div className="w-100 content-section">

            <div className="px-3 mt-2">
                <Button
                    onClick={() => { props.history.push(`/mockup/quotes`) }}
                    style={{ backgroundColor: `${tripSettings.buttonColor}`, borderColor: 'transparent' }}
                >
                    <i className="fas fa-chevron-left" /> BACK TO QUOTES
                </Button>
                {
                    errorMessage ? <ErrorMessage message={errorMessage} /> : null
                }

                <div className='col-12 head-div mt-2' style={{ background: tripSettings?.sectionColor }}>

                    <div>
                        <span className='sg-card-lt'>{"Trip Details"}</span>
                    </div>
                </div>
                <div className='col-12 '>

                    <span className='dtail-bold'>{quoteDetails.quoteId}</span>
                    <span className='dtail'>Trip Reference: {quoteDetails.mobilePhone}</span>
                    <span className='dtail'>Quotation Total: {`$${formatCurrency(Number(extraInformation.quotationTotal))}`}</span>
                    <span className='dtail'>Salesperson: {quoteDetails.SalesPerson}</span>
                    <span className='dtail'>Total Miles: {quoteDetails.totalMiles}</span>
                </div>
                <div className="m-2">
                    <div className='d-flex head-div mt-2' style={{ background: tripSettings?.mainColor }}>

                        <div className='col-8 item-col'>

                            <div className='card-l'>
                                <span className='sg-card-lt'>{"location"}</span>
                            </div>
                        </div>
                        <div className='col-4 item-col'>
                            <div className='card-r'>
                                <span className='sg-card-rt'>{"departure"}</span>
                            </div>
                        </div>

                    </div>
                    {quoteDetails.segments && quoteDetails.segments.map((segment) => (
                        <div className='d-flex oe-list'>

                            <div className='col-8 item-col'>

                                <div className='card-l'>
                                    <span className='sg'>{segment.startAddress}</span>
                                </div>
                            </div>
                            <div className='col-4 item-col'>
                                <div className='card-r'>
                                    <span className='sg'>{moment(segment.departureTime, 'MM-DD-YYYY HH:mm').format('HH:mm (MM/DD)')}</span>
                                </div>
                            </div>

                        </div>
                    ))}
                </div>
                <div className='col-12 head-div mt-2' style={{ background: tripSettings?.sectionColor }}>

                    <div>
                        <span className='sg-card-lt'>{"Vehicle Information"}</span>
                    </div>
                </div>
                <div className='col-12 '>

                    <span className='dtail'>Passengers: {extraInformation.passengers}</span>
                    <span className='dtail'>Total Available Capacity: {extraInformation.totalAvailableCapacity}</span>
                </div>
                {quoteDetails.vehicleRequests?.length > 0 ?
                    <div className="m-2">
                        <div className='d-flex head-div mt-2' style={{ background: tripSettings?.mainColor }}>

                            <div className='col-6 item-col'>

                                <div className='card-l'>
                                    <span className='sg-card-lt'>{"Vehicle Description"}</span>
                                </div>
                            </div>

                            <div className='col-2 item-col'>

                                <div className='card-l'>
                                    <span className='sg-card-lt'>{"Qty"}</span>
                                </div>
                            </div>
                            <div className='col-4 item-col'>
                                <div className='card-r'>
                                    <span className='sg-card-rt'>{"Total"}</span>
                                </div>
                            </div>

                        </div>
                        {quoteDetails.vehicleRequests && quoteDetails.vehicleRequests.map((vehicle) => (
                            <div className='d-flex oe-list'>

                                <div className='col-6 item-col'>

                                    <div className='card-l'>
                                        <span className='sg'>{`${vehicle.displayName}(${vehicle.capacity})`}</span>
                                    </div>
                                </div>
                                <div className='col-2 item-col'>

                                    <div className='card-l'>
                                        <span className='sg'>{`${vehicle.quantity}`}</span>
                                    </div>
                                </div>
                                <div className='col-4 item-col'>
                                    <div className='card-r'>
                                        <span className='sg'>{renderTotal(vehicle)}</span>
                                    </div>
                                </div>

                            </div>
                        ))}
                    </div> : ''
                }
                {quoteDetails.addonRequests?.length > 0 ? (
                    <>
                        <div className='col-12 head-div mt-2'>
                            <div>
                                <span className='sg-card-lt'>{"Addons"}</span>
                            </div>
                        </div>
                        <div className="m-2">
                            <div className='d-flex head-div mt-2'>

                                <div className='col-6 item-col'>

                                    <div className='card-l'>
                                        <span className='sg-card-lt'>{"Description"}</span>
                                    </div>
                                </div>
                                <div className='col-2 item-col'>

                                    <div className='card-l'>
                                        <span className='sg-card-lt'>{"Qty"}</span>
                                    </div>
                                </div>
                                <div className='col-4 item-col'>
                                    <div className='card-r'>
                                        <span className='sg-card-rt'>{"Total"}</span>
                                    </div>
                                </div>

                            </div>
                            {quoteDetails.addonRequests && quoteDetails.addonRequests.map((addon) => (
                                <div className='d-flex'>

                                    <div className='col-6 item-col'>

                                        <div className='card-l'>
                                            <span className='sg'>{`${addon.AddonType}`}</span>
                                        </div>
                                    </div>
                                    <div className='col-2 item-col'>

                                        <div className='card-l'>
                                            <span className='sg'>{`${addon.quantity}`}</span>
                                        </div>
                                    </div>
                                    <div className='col-4 item-col'>
                                        <div className='card-r'>
                                            <span className='sg'>{renderTotal(addon)}</span>
                                        </div>
                                    </div>

                                </div>
                            ))}
                        </div>
                    </>
                ) : ''}



                <Loading loading={loading} />
            </div>
        </div>
    )
}

export default QuoteDetails;
