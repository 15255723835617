import React, { useState, useEffect } from "react";
import { Modal, Row, Col, FormGroup, Form, Button, Container, Spinner, InputGroup } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import {
    addTransaction,
    deleteTransaction,
    makePayment,
    useMakePaymentState,
    getStates,getCountries,
    useTransactionsState,
} from "../../Context";
import { DatePicker } from 'react-tempusdominus-bootstrap'
import "react-datepicker/dist/react-datepicker.css";
import { formatCurrency,formatToFixed } from "../../utils/helper";

const PaymentDialog = ({
    onCloseClick = () => { },
    total = 0,
    amountOutstanding = 0,
    bookingId = 1234,
    id,
    refreshBookings,
}) => {
    const [formState, setFormState] = useState({ stage: 0, paymentType: "", amount: "", id: "" });
    const { register, handleSubmit, formState: { errors }, control, reset } = useForm({
        defaultValues: {
            date: new Date(),
            amount:formatToFixed(amountOutstanding)
        }
    });
    const [loading, setLoading] = useState(false);
    const { transactionsDispatch, transactions: { states, countries } } = useTransactionsState();
    const { paymentDispatch } = useMakePaymentState();
    useEffect(() => {
        (async () => {
            const user = JSON.parse(localStorage.getItem('user'))
            reset({
                firstName: user?.firstName || '',
                lastName: user?.lastName || ''
            })
            await getStates(transactionsDispatch)
            await getCountries(transactionsDispatch)
        })()
        return () => { }
    }, [transactionsDispatch, reset])


    const handleSubmitPayment = async (e) => {
        e.preventDefault();
        await handleSubmit(async (data) => {
            try {
                setLoading(true);
                let transactionData = {
                    ...data,
                    bookingID: id,
                    transactionTypeID: 3
                };
                // console.log(transactionData);
                let transaction = await addTransaction(transactionsDispatch, transactionData);
                transactionData.companyID = transaction.companyID;
                transactionData.orderID = transaction.id;
                transactionData.invoice = bookingId;
                transactionData.productRefNum = bookingId;
                transactionData.unitPrice = transaction.amount;
                transactionData.ecomind = 'T';
                transactionData.customerID = '';
                transactionData.PONum = '';
                let response = await makePayment(paymentDispatch, transactionData, transaction.companyID)
                if (typeof response == 'undefined') {
                    window.swal("Transaction Failed", "We're Sorry. We have experienced an unknown error. Please wait a few minutes and try again. If the issue persists, please get in touch with us.", "error");
                    await deleteTransaction(transactionsDispatch, transaction.id);
                    setLoading(false);
                    return;
                }
                else if (response.errors && response.errors.length > 0) {
                    window.swal("Transaction Declined", response.errors[0].msg, "error");
                    await deleteTransaction(transactionsDispatch, transaction.id);
                    setLoading(false);
                    return;
                }
                else if (response.error) {
                    window.swal("Transaction Declined", response.error, "error");
                    await deleteTransaction(transactionsDispatch, transaction.id);
                    setLoading(false);
                    return;
                }
                else if (response.Result === 'AxiosError') {
                    window.swal("Transaction Failed", "There is an error authorizing the payment gateway. Contact us to resolve this issue.", "error");
                    await deleteTransaction(transactionsDispatch, transaction.id);
                    setLoading(false);
                    return;
                }
                else if (response.Result === 'Declined') {
                    window.swal("Transaction Declined", response.StatusCP, "error");
                    await deleteTransaction(transactionsDispatch, transaction.id);
                    setLoading(false);
                    return;
                }
                await refreshBookings();
                setFormState({
                    ...formState,
                    stage: 2,
                    id: transaction.id,
                    amount: transaction.amount
                });
                setLoading(false);
            } catch (e) {
                console.log(e);
            }
        })()
    }

    const paymentForms = <Row>
        <Col xs={4} className="bg-white text-center d-flex flex-column justify-content-between">
            <div>
                <p className="font-weight-bold mb-0 mt-3">Booking ID</p>
                <h3 className="dark-green">{bookingId}</h3>
                <p className="font-weight-bold mb-0 mt-3">Booking Total</p>
                <h3 className="dark-green">{`$${formatCurrency(total)}`}</h3>
                <hr />
                <p className="font-weight-bold mb-0 my-auto">Outstanding Balance</p>
                <h3 className="dark-green">{`$${formatCurrency(amountOutstanding)}`}</h3>
            </div>
        </Col>
        <Col xs={8} className="light-gray-bg d-flex justify-content-center align-items-center">
            <div className="w-100">
                <form onSubmit={(e) => handleSubmitPayment(e)}>
                    <Row className="gap-1">
                        <Col xs={12}>
                            <FormGroup>
                                <Form.Label className="text-dark">Date</Form.Label>
                                <Controller
                                    render={() =>
                                        <div className="test">
                                            <DatePicker
                                                readOnly
                                                date={new Date()}
                                                className={'w-100'}
                                            />
                                        </div>}
                                    name='date'
                                    control={control}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Form.Label className="text-dark">
                                    Payment Amount<span className="text-danger"> *</span>
                                </Form.Label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>$</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        type="number"
                                        step="0.01"
                                        placeholder="XXX.XX"
                                        name="amount"
                                        min="0.00"
                                        isInvalid={errors.amount?.message}
                                        {...register('amount', { required: "Amount is required", min: 0 })}
                                    />
                                </InputGroup>
                                <p className="text-danger">
                                    {errors.amount?.message}
                                </p>
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Form.Label className="text-dark">Card Number<span className="text-danger"> *</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    id="cardNumber"
                                    name="cardNumber"
                                    maxLength={16}
                                    placeholder="XXXX-XXXX-XXXX-XXXX"
                                    isInvalid={errors.cardNumber?.message}
                                    {...register('cardNumber', { required: "Card Number is required" })}
                                />
                                <p className="text-danger">
                                    {errors.cardNumber?.message}
                                </p>
                            </FormGroup>
                        </Col>
                        <Col xs={4}>
                            <FormGroup>
                                <Form.Label className="text-dark">Card Type<span className="text-danger"> *</span></Form.Label>
                                <Form.Control as="select"
                                    id="cardType"
                                    name="cardType"
                                    isInvalid={errors.cardType?.message}
                                    {...register('cardType', { required: "true" })} >
                                    <option value=''></option>
                                    <option value='American Express'>American Express</option>
                                    <option value='Discover'>Discover</option>
                                    <option value='Mastercard'>Mastercard</option>
                                    <option value='Visa'>Visa</option>
                                </Form.Control>
                            </FormGroup>
                        </Col>
                        <Col xs={4}>
                            <FormGroup>
                                <Form.Label className="text-dark">CVV<span className="text-danger"> *</span></Form.Label>
                                <Form.Control
                                    type="number"
                                    id="cvv"
                                    name="cvv"
                                    placeholder="XXX"
                                    maxLength={3}
                                    isInvalid={errors.cvv?.message}
                                    {...register('cvv', { required: "true" })}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={4}>
                            <FormGroup>
                                <Form.Label className="text-dark">Card Expiration<span className="text-danger"> *</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    id="cardExpiration"
                                    name="cardExpiration"
                                    placeholder="MMYY"
                                    maxLength={4}
                                    isInvalid={errors.cardExpiration?.message}
                                    {...register('cardExpiration', { required: "true" })}
                                />
                            </FormGroup>
                        </Col>

                        <Col xs={6}>
                            <FormGroup>
                                <Form.Label className="text-dark">First Name<span className="text-danger"> *</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    id="firstName"
                                    name="firstName"
                                    placeholder="First Name"
                                    isInvalid={errors.firstName?.message}
                                    {...register('firstName', { required: "true" })}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <Form.Label className="text-dark">Last Name<span className="text-danger"> *</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    id="lastName"
                                    name="lastName"
                                    placeholder="Last Name"
                                    isInvalid={errors.lastName?.message}
                                    {...register('lastName', { required: "true" })}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Form.Label className="text-dark">Street-1<span className="text-danger"> *</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    id="street"
                                    name="street"
                                    placeholder="Street-1"
                                    ref={register}
                                    isInvalid={errors.street?.message}
                                    {...register('street', { required: "true" })}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Form.Label className="text-dark">Street-2</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="street2"
                                    name="street2"
                                    placeholder="Street-2"
                                    {...register}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={4}>
                            <FormGroup>
                                <Form.Label className="text-dark">City<span className="text-danger"> *</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    id="city"
                                    name="city"
                                    placeholder="City"
                                    isInvalid={errors.city?.message}
                                    {...register('city', { required: "true" })}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={4}>
                            <FormGroup>
                                <Form.Label className="text-dark">State<span className="text-danger"> *</span></Form.Label>
                                <Form.Control
                                    as="select"
                                    id="state"
                                    name="state"
                                    placeholder="State"
                                    isInvalid={errors.state?.message}
                                    {...register('state', { required: "true" })}
                                >
                                    {states.length && states.map((r, i) =>
                                        <option key={i} value={r.id}>{r.abbreviation}</option>)
                                    }
                                </Form.Control>
                            </FormGroup>
                        </Col>
                        <Col xs={4}>
                            <FormGroup>
                                <Form.Label className="text-dark">Zip<span className="text-danger"> *</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    id="zip"
                                    name="zip"
                                    placeholder="Zip"
                                    isInvalid={errors.zip?.message}
                                    {...register('zip', { required: "true" })}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Form.Label className="text-dark">Country</Form.Label>
                                <Form.Control
                                    as="select"
                                    id="country"
                                    name="country"
                                    placeholder="Country"
                                    {...register}
                                >
                                    {countries && countries.length > 0 && countries.map((r, i) =>
                                        <option key={i} value={r.alpha2Code}>{r.name}</option>)
                                    }
                                </Form.Control>
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Form.Label className="text-dark">Company</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="company"
                                    name="company"
                                    placeholder="Company"
                                    {...register}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Form.Label className="text-dark">Email</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="email"
                                    name="email"
                                    placeholder="Email"
                                    {...register}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Button block type="submit" className="w-100 mt-2">
                        <i className="fas fa-pen" /> SUBMIT PAYMENT
                    </Button>
                </form>
            </div>
        </Col>
    </Row>

    const confirmation = <div className="light-gray-bg p-3 w-100 text-center">
        <i className="fa fa-thumbs-up text-dark fa-4x mt-2" />
        <h3 className="dark-green mb-2">Payment Successfully Applied</h3>
        <hr />
        <p className="mb-0 mt-3">Confirmation Number</p>
        <h4 className="dark-green mb-3">{formState.id}</h4>
        <p className="mb-0 mt-3">Amount Paid</p>
        <h4 className="dark-green mb-3">{`$${formatCurrency(formState.amount)}`}</h4>

        <p className="mb-0 my-3">A confirmation email has also been sent to the address on file.</p>
    </div>

    return (
        <Modal className="rounded warning-modal" animation={false} size="lg" show={true} onHide={onCloseClick} centered>
            <Modal.Header className="alt-green-bg text-white">
                <Modal.Title>
                    <i className="fa fa-credit-card-alt text-dark mx-2" />
                    Make a Payment
                </Modal.Title>
                <i className="fas fa-times-circle text-white closeButton" onClick={onCloseClick} />
            </Modal.Header>
            <Modal.Body>
                {
                    loading ?
                        <Container className="mb-5 text-center">
                            <Spinner animation="border" variant="primary" className="mt-5" />
                        </Container>
                        : formState.stage === 2
                            ? confirmation
                            : paymentForms
                }
            </Modal.Body>
        </Modal>
    )
}

document.addEventListener('keydown', function (e) {
    if (e.keyCode === 119) { // F8
        debugger;
    }
}, {
    capture: true
});

export default PaymentDialog;
