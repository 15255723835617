import Service from './baseService';
import { getCompanyId } from '../config';

export const getBookingsService = (upcomingFilter, pastFilter) => {
    let params = { upcomingFilter, pastFilter };

    return Service.get('/customer/bookings', { params });
}

export const getBookingPaymentDetailsService = (id) => {
    return Service.get(`/customer/bookings/payment/${id}`);
}

export const getBookingDetailsService = (id) => {
    return Service.get(`/customer/bookings/${id}`, { params: { companyId: getCompanyId() } });
}

export const printSignedBookingService = (bookingId, signedContractId, companyId, user) => {
    let params = { companyId: companyId ? companyId : getCompanyId(), signedContractId };
    if (user) params.user = user

    return Service.post(`/customer/bookings/get-signedPrint/${bookingId}`, {}, { params, responseType: 'arraybuffer' });
}

export const getBookingConfirmationDetailsService = (bookingId) => {
    return Service.get(`/customer/bookings/confirmation/${bookingId}`);
};

export const getBookingDisclaimersDetailsService = (bookingId) => {
    return Service.get(`/customer/bookings/booking-disclaimer/${bookingId}`);
};

