import { Spinner, Table } from "react-bootstrap";
import React from "react";

// fieldNames = [[ key, label, customRenderFunc, footerContent ]]
const SimpleTable = ({ fieldNames = [], data = [], onClick = () => { }, showFooter = false, bordered = false,
    loading = false, rowStyle = () => { }, wrap = false, mainColor }) => {
    let tableContent = <>
        <tbody>
            {
                data && data.length > 0
                    ?
                    (
                        data.map((item, i) => (
                            <tr key={i} className={"text-nowrap cursor-pointer"} onClick={() => onClick(item)} style={rowStyle(item)}>
                                {
                                    fieldNames.map(([key, label, customRenderFunc], j) =>
                                        typeof customRenderFunc === 'function'
                                            ? <td className={"text-nowrap text-center"} key={j}>{customRenderFunc(item)}</td>
                                            : <td className={"text-nowrap text-center"} key={j}>{item[key]}</td>
                                    )
                                }
                            </tr>
                        ))
                    )
                    : <tr><td colSpan={fieldNames.length} className="text-center bold">No Data Found</td></tr>
            }
        </tbody>
        {
            showFooter &&
            (
                <tfoot className="text-white" style={{ backgroundColor: `${mainColor}` }}>
                    <tr>
                        {
                            fieldNames.map(([key, label, customRenderFunc, footerContent], i) => {
                                return (
                                    <th key={i} className="text-center">
                                        {footerContent ? footerContent : ""}
                                    </th>
                                )
                            })
                        }
                    </tr>
                </tfoot>
            )
        }
    </>
    let loadingContent = <tbody>
        <tr>
            <td colSpan={fieldNames.length} className="text-center">
                <Spinner animation="border" variant="primary" />
            </td>
        </tr>
    </tbody>
    return (
        <div style={{ overflow: 'auto' }}>
            <Table striped bordered={bordered}>
                <thead className="text-white" style={{ backgroundColor: `${mainColor}` }}>
                    <tr style={{ whiteSpace: wrap ? "nowrap" : "unset", cursor: "pointer" }}>
                        {
                            fieldNames.map(([key, label], i) => { // field = [key, label]
                                return (
                                    <th key={i} className="text-center">
                                        {label}
                                    </th>
                                )
                            })
                        }
                    </tr>
                </thead>
                {
                    loading ? loadingContent : tableContent
                }
            </Table>
        </div>
    )
}

export default SimpleTable;