import React, { useEffect, useState } from "react";
import moment from "moment";
import { Container, Modal, Row, Spinner, Button, FormGroup, Form} from "react-bootstrap";
import {
    getBookingDetails, getGeneralLedgerCodes, useBookingState, useGeneralLedgerCodeState,
    useAccountState, getTripSettings
} from "../../../../Context";
import { formatToFixed } from "../../../../utils/helper";
import { sumBy } from 'lodash';
import PaymentDialog from "../../../../Components/PaymentDialog";
import Loading from "../../../../Components/Loading";
import * as dayjs from "dayjs";
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import BookingAssignment from "../../../../Components/BookingAssignment";
import MockupLayout from "../../../../Components/MockupLayout";
import CustomAccordion from "../../../../Components/CustomAccordion";
import { addSignedContractService } from "../../../../services/signedContractsService";
import { getContactsService } from "../../../../services/contactServices"
import { getBookingDisclaimersDetailsService } from "../../../../services/bookingsService";
dayjs.extend(utc)
dayjs.extend(timezone)

const BookingDetails = (props) => {
    const bookingId = props.match.params.id;
    const [collapseItem, handleCollapse] = useState({ 1: false })
    const [makePayment, setMakePayment] = useState(false);
    const { bookingsDispatch, bookings: { bookingDetails, addonAssignments, dispatches } } = useBookingState();
    const { generalLedgerCodeDispatch, generalLedgerCode: { generalLedgerCodes } } = useGeneralLedgerCodeState();
    const { AccountsDispatch, tripSettings: { tripSettings } } = useAccountState();
    const [loading, setLoading] = useState(false);
    const [contacts, setContacts] = useState([])
    const [disclaimers, setDisclaimers] = useState([])
    const [termsConditions, setTermsConditions] = useState([])

    useEffect(() => {
        (async () => {
            setLoading(true)
            await getTripSettings(AccountsDispatch);
            await getGeneralLedgerCodes(generalLedgerCodeDispatch);
            let response = await getBookingDetails(bookingsDispatch, bookingId);
            let booking = await getBookingDisclaimersDetailsService(bookingId);
            let bookingData = booking.data;
            let disclaimers = bookingData?.disclaimerData
            let tc = bookingData?.termsData
            setDisclaimers(disclaimers)
            setTermsConditions(tc)
            if (response && response.details && response.details.companyId) {
                let contacts = await getContactsService(response.details.companyId)
                setContacts(contacts.data)
            }
            setLoading(false)
        })()
    }, [bookingsDispatch, generalLedgerCodeDispatch, bookingId, AccountsDispatch])

    const handleItemCollapse = (e) => {
        const { id } = e.target
        handleCollapse({ ...collapseItem, [id]: !collapseItem[id] })
    }

    const onVehicleClick = (item) => {
        props.history.push(`/mockup/bookings-vehicle-assignment/${item.id}?bookingId=${bookingId}`)
    }

    const handleDesclaimer = async () => {
        setLoading(true)
        let contact = contacts.find(contact => contact.id === Number(bookingDetails.primaryContactID));
        let d = Array.isArray(disclaimers) && disclaimers.length > 0 && disclaimers.find(disclaimer => disclaimer.isDefault);
        let tc = Array.isArray(termsConditions) && termsConditions.length > 0 && termsConditions.find(termsCondition => termsCondition.isDefault);

        if (contact && (contact.overrideDisclaimer === 1 || (d && contact.lastAcceptedDisclaimerID === d.id)) &&
            (contact.overrideTermsConditions === 1 || (tc && contact.lastAcceptedDisclaimerID === tc.id))) {
            let contract = {
                bookingID: bookingId,
                disclaimerAutoAccepted: 1,
                disclaimerAutoAcceptedAt: moment().format("YYYY-MM-DDTHH:mm:00"),
                termsConditionsAutoAccepted: 1,
                termsConditionsAutoAcceptedAt: moment().format("YYYY-MM-DDTHH:mm:00"),
            }
            let signedContract = await addSignedContractService(contract, bookingDetails.companyId);
            setLoading(false)
            if (signedContract && signedContract.data) {
                window.open(`${window.location.origin}/p/booking-confirmation?bid=${bookingId}&ts=${new Date().valueOf()}&signedContractId=${signedContract.data.id}`, "_blank")
            }
        }
        else {
            setLoading(false)
            window.open(`${window.location.origin}/p/booking-desclaimer?bid=${bookingId}&ts=${new Date().valueOf()}`, "_blank")
        }
    }

    const handleCheckOutPageClick = async () => {
        window.open(`${window.location.origin}/p/booking-payment?bid=${bookingId}&ts=${new Date().valueOf()}`, "_blank")
    }
    const handleConfirmationPageClick = async () => {
        window.open(`${window.location.origin}/p/booking-confirmation?bid=${bookingId}&ts=${new Date().valueOf()}`, "_blank")
    }

    const calculateTotals = (dispatchAss, addOnAss) => {
        let totalOfDispatchAssignments = sumBy(dispatchAss, (a) => parseFloat(a.vehiclePrice))
        let totalOfAddOnAssignments = sumBy(addOnAss, (a) => parseFloat(a.addOnPrice ? a.addOnPrice : 0) * parseFloat(a.quantity ? a.quantity : 1))
        return totalOfDispatchAssignments + totalOfAddOnAssignments;
    }

    const refreshBookingDetails = async () => {
        setLoading(true)
        await getBookingDetails(bookingsDispatch, bookingId);
        setLoading(false)
    }

    let total = calculateTotals(dispatches, addonAssignments);
    //bookingDetails.totalBeforeTaxAtBooking = formatToFixed(bookingDetails.totalBeforeTaxAtBooking);
    bookingDetails.depositAmount = formatToFixed(bookingDetails.depositAmount);
    bookingDetails.totalBeforeTax = formatToFixed(total);
    bookingDetails.totalTax = formatToFixed(bookingDetails.salesTaxPercentage ? (total * parseFloat(bookingDetails.salesTaxPercentage)) / 100 : 0);
    bookingDetails.totalAfterTax = formatToFixed(parseFloat(bookingDetails.totalBeforeTax) + parseFloat(bookingDetails.totalTax));
    // bookingDetails.amountOutstanding = formatToFixed(Math.abs(parseFloat(bookingDetails.amountPaid) - bookingDetails.totalAfterTax));

    return (
        <MockupLayout
            title={`Booking ${bookingDetails && bookingDetails.bookingId ? bookingDetails.bookingId : "Details"} ${bookingDetails.cancelledAt ? '(Cancelled)' : ''}`}
            titleIconClass={"fas fa-calendar-check"}
            textColor={tripSettings?.textColor}
            buttons={<>
                <Button
                    onClick={() => { props.history.push(`/mockup/bookings`) }}
                    style={{ backgroundColor: `${tripSettings.buttonColor}`, borderColor: 'transparent' }}
                >
                    BACK TO BOOKINGS
                </Button>
                <Button
                    id="bookings_disclaimer"
                    className="ml-2"
                    style={{ backgroundColor: `${tripSettings.buttonColor}`, borderColor: 'transparent' }}
                    onClick={handleDesclaimer}>
                    DISCLAIMER PAGE
                </Button>

                <Button
                    id="bookings_confirmation_page"
                    disabled={!bookingId}
                    className="ml-2"
                    onClick={handleConfirmationPageClick}
                    style={{ backgroundColor: `${tripSettings.buttonColor}`, borderColor: 'transparent' }}
                >
                    Confirmation Page
                </Button>
                <Button
                    id="bookings_checkout_page"
                    disabled={!bookingId}
                    className="ml-2"
                    onClick={handleCheckOutPageClick}
                    style={{ backgroundColor: `${tripSettings.buttonColor}`, borderColor: 'transparent' }}
                >
                    Checkout Page
                </Button>
            </>}
        >

            <CustomAccordion
                id={1}
                title="Trip Details"
                collapseItem={collapseItem}
                handleItemCollapse={handleItemCollapse}
                sectionColor={tripSettings?.sectionColor}
                className="px-2"
            >
                <Row className={`mx-1 ${bookingDetails.tripReference || bookingDetails.groupName ? '' : 'hidden'}`}>
                    <dl className={`col-12 col-md-4 details-list  ${bookingDetails.tripReference ? '' : 'hidden'}`}>
                        {/*Trip Description*/}
                        <FormGroup>
                            <Form.Label>Trip Reference</Form.Label>
                            <Form.Control
                                type="text"
                                value={bookingDetails.tripReference ? bookingDetails.tripReference : ""}
                                readOnly
                            />
                        </FormGroup>
                    </dl>
                    <dl className={`col-12 col-md-4 details-list  ${bookingDetails.groupName ? '' : 'hidden'}`}>
                        {/*Trip Description*/}
                        <FormGroup>
                            <Form.Label>Group Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={bookingDetails.groupName ? bookingDetails.groupName : ""}
                                readOnly
                            />
                        </FormGroup>
                    </dl>
                </Row>
                <Row className={`mx-1 ${bookingDetails.bookingQuoteId || bookingDetails.purchaseOrder ? '' : 'hidden'}`}>
                    <dl className={`col-12 col-md-4 details-list ${bookingDetails.bookingQuoteId ? '' : 'hidden'}`}>
                        {/*Booking Quote ID #*/}
                        <FormGroup>
                            <Form.Label>Associated Quote ID #</Form.Label>
                            <Form.Control
                                type="text"
                                value={bookingDetails.bookingQuoteId ? bookingDetails.bookingQuoteId : ""}
                                readOnly
                            />
                        </FormGroup>
                    </dl>
                    <dl className={`col-12 col-md-4 details-list ${bookingDetails.purchaseOrder ? '' : 'hidden'}`}>
                        {/*Purchase Order #*/}
                        <FormGroup>
                            <Form.Label>Purchase Order #</Form.Label>
                            <Form.Control
                                type="text"
                                value={bookingDetails.purchaseOrder ? bookingDetails.purchaseOrder : ""}
                                readOnly
                            />
                        </FormGroup>
                    </dl>
                </Row>
            </CustomAccordion>

            {/*Routes (Booking Vehicle Assignments)*/}
            <CustomAccordion id={2} key={2}
                collapseItem={collapseItem}
                handleItemCollapse={handleItemCollapse}
                sectionColor={tripSettings?.sectionColor}
                title="Routes"
            >
                <div className="px-md-4 py-2 overflow-auto">
                    <BookingAssignment
                        cancelled={bookingDetails.cancelledAt}
                        mainColor={tripSettings?.mainColor}
                        bookingId={bookingId}
                        onRowClick={onVehicleClick}
                    />
                </div>
            </CustomAccordion>

            <CustomAccordion
                id={3}
                title="Financial"
                handleItemCollapse={handleItemCollapse}
                sectionColor={tripSettings?.sectionColor}
                collapseItem={collapseItem}
            >
                <Row className="mx-1">
                    <dl className={`col-6 col-md-3 details-list}`}>
                        {/*Payment Status*/}
                        {bookingDetails.paymentStatus ? <>
                            <FormGroup>
                                <Form.Label>Payment Status</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={bookingDetails.paymentStatus ? bookingDetails.paymentStatus : ""}
                                    readOnly
                                />
                            </FormGroup>
                        </> : ""}

                        {/*Deposit Due Date*/}
                        {bookingDetails.depositDueDate ? <>
                            <FormGroup>
                                <Form.Label>Deposit Due Date</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={bookingDetails.depositDueDate ? bookingDetails.depositDueDate : ""}
                                    readOnly
                                />
                            </FormGroup>
                        </> : ""}
                        {/*Deposit Amount*/}
                        {bookingDetails.depositAmount ? <>
                            <FormGroup>
                                <Form.Label>Deposit Amount</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={bookingDetails.depositAmount ? bookingDetails.depositAmount : 0}
                                    readOnly
                                />
                            </FormGroup>
                        </> : ''}
                    </dl>
                    <dl className={`col-6 col-md-3 details-list`}>
                        {/*Payment Status*/}
                        {bookingDetails.finalPaymentDueDate ? <>
                            <FormGroup>
                                <Form.Label>Final Payment Due Date</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={bookingDetails.finalPaymentDueDate ? bookingDetails.finalPaymentDueDate : ""}
                                    readOnly
                                />
                            </FormGroup>
                        </> : ""}
                        {/*Total Before Tax*/}
                        <FormGroup>
                            <Form.Label>Total Before Tax</Form.Label>
                            <Form.Control
                                type="text"
                                value={bookingDetails.totalBeforeTax ? bookingDetails.totalBeforeTax : 0}
                                readOnly
                            />
                        </FormGroup>
                    </dl>
                    <dl className={`col-6 col-md-3 details-list`}>
                        {/*Total Tax*/}
                        <FormGroup>
                            <Form.Label>Total Tax</Form.Label>
                            <Form.Control
                                type="text"
                                value={bookingDetails.totalTax ? bookingDetails.totalTax : 0}
                                readOnly
                            />
                        </FormGroup>
                        {/*Amount Paid*/}
                        <FormGroup>
                            <Form.Label>Amount Paid</Form.Label>
                            <Form.Control
                                type="text"
                                value={bookingDetails.amountPaid ? bookingDetails.amountPaid : 0}
                                readOnly
                            />
                        </FormGroup>
                    </dl>
                    <dl className={`col-6 col-md-3 details-list`}>
                        {/*Total After Tax*/}
                        <FormGroup>
                            <Form.Label>Total After Tax</Form.Label>
                            <Form.Control
                                type="text"
                                value={bookingDetails.totalAfterTax ? bookingDetails.totalAfterTax : 0}
                                readOnly
                            />
                        </FormGroup>
                        {/*Amount Outstanding*/}
                        <FormGroup>
                            <Form.Label>Amount Outstanding</Form.Label>
                            <Form.Control
                                type="text"
                                value={bookingDetails.amountOutstanding ? bookingDetails.amountOutstanding : 0}
                                readOnly
                            />
                        </FormGroup>
                        {bookingDetails?.amountOutstanding && bookingDetails.amountOutstanding > 0 ?
                            <FormGroup>
                                <Form.Label>Make Payment</Form.Label>
                                <dd><Button variant="primary" onClick={() => setMakePayment(true)}>Make Payment</Button></dd>
                            </FormGroup>
                            : ''}
                    </dl>
                </Row>
            </CustomAccordion>
            {
                loading && <Loading loading={loading} />

            }

            {
                makePayment &&
                <PaymentDialog
                    id={bookingDetails.id}
                    bookingId={bookingDetails.bookingId}
                    amountOutstanding={bookingDetails.amountOutstanding}
                    generalLedgerCodes={generalLedgerCodes}
                    onCloseClick={() => setMakePayment(false)}
                    refreshBookings={refreshBookingDetails}
                    total={bookingDetails.totalAfterTax}
                />
            }

            {
                loading &&
                <Container className="mb-5 text-center">
                    <Modal show={true} animation={false} className="loading-modal text-center">
                        <Spinner animation="border" variant="primary" />
                    </Modal>
                </Container>
            }
        </MockupLayout>
    )
}

export default BookingDetails;