import React, { useEffect, useState } from "react";
import moment from "moment";
import { Container, Modal, Row, Spinner, Button, FormGroup, Form } from "react-bootstrap";
import {
    getBookingDetails, getGeneralLedgerCodes, useBookingState, useGeneralLedgerCodeState,
    useAccountState, getTripSettings, getVehicleAddignments, useVehicleAssignmentsState
} from "../../../../Context";
import { formatToFixed } from "../../../../utils/helper";
import { sumBy } from 'lodash';
import PaymentDialog from "../../../../Components/PaymentDialog";
import Loading from "../../../../Components/Loading";
import * as dayjs from "dayjs";
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import BookingAssignment from "../../../../Components/BookingAssignment";
import MockupLayout from "../../../../Components/MockupLayout";
import CustomAccordion from "../../../../Components/CustomAccordion";
import { addSignedContractService } from "../../../../services/signedContractsService";
import { getContactsService } from "../../../../services/contactServices"
import { getBookingDisclaimersDetailsService } from "../../../../services/bookingsService";
import "./style.scss"
dayjs.extend(utc)
dayjs.extend(timezone)

const BookingDetails = (props) => {
    const bookingId = props.match.params.id;
    const [collapseItem, handleCollapse] = useState({ 1: false })
    const [makePayment, setMakePayment] = useState(false);
    const { bookingsDispatch, bookings: { bookingDetails, addonAssignments, dispatches } } = useBookingState();
    const { generalLedgerCodeDispatch, generalLedgerCode: { generalLedgerCodes } } = useGeneralLedgerCodeState();
    const { AccountsDispatch, tripSettings: { tripSettings } } = useAccountState();
    const [loading, setLoading] = useState(false);
    const [contacts, setContacts] = useState([])
    const [disclaimers, setDisclaimers] = useState([])
    const [termsConditions, setTermsConditions] = useState([])
    const { vehicleAssignmentsDispatch } = useVehicleAssignmentsState();
    const [bookingVehicleAssignments, setBookingVehicleAssignments] = useState([]);

    useEffect(() => {
        (async () => {
            setLoading(true)
            await getTripSettings(AccountsDispatch);
            await getGeneralLedgerCodes(generalLedgerCodeDispatch);
            let response = await getBookingDetails(bookingsDispatch, bookingId);
            let booking = await getBookingDisclaimersDetailsService(bookingId);
            const data = await getVehicleAddignments(vehicleAssignmentsDispatch, bookingId)
            setBookingVehicleAssignments(data)
            let bookingData = booking.data;
            let disclaimers = bookingData?.disclaimerData
            let tc = bookingData?.termsData
            setDisclaimers(disclaimers)
            setTermsConditions(tc)
            if (response && response.details && response.details.companyId) {
                let contacts = await getContactsService(response.details.companyId)
                setContacts(contacts.data)
            }
            setLoading(false)
        })()
    }, [bookingsDispatch, generalLedgerCodeDispatch, bookingId, AccountsDispatch, vehicleAssignmentsDispatch])

    const handleItemCollapse = (e) => {
        const { id } = e.target
        handleCollapse({ ...collapseItem, [id]: !collapseItem[id] })
    }

    const onVehicleClick = (item) => {
        props.history.push(`/mockup/bookings-vehicle-assignment/${item.id}?bookingId=${bookingId}`)
    }

    const handleDesclaimer = async () => {
        setLoading(true)
        let contact = contacts.find(contact => contact.id === Number(bookingDetails.primaryContactID));
        let d = Array.isArray(disclaimers) && disclaimers.length > 0 && disclaimers.find(disclaimer => disclaimer.isDefault);
        let tc = Array.isArray(termsConditions) && termsConditions.length > 0 && termsConditions.find(termsCondition => termsCondition.isDefault);

        if (contact && (contact.overrideDisclaimer === 1 || (d && contact.lastAcceptedDisclaimerID === d.id)) &&
            (contact.overrideTermsConditions === 1 || (tc && contact.lastAcceptedDisclaimerID === tc.id))) {
            let contract = {
                bookingID: bookingId,
                disclaimerAutoAccepted: 1,
                disclaimerAutoAcceptedAt: moment().format("YYYY-MM-DDTHH:mm:00"),
                termsConditionsAutoAccepted: 1,
                termsConditionsAutoAcceptedAt: moment().format("YYYY-MM-DDTHH:mm:00"),
            }
            let signedContract = await addSignedContractService(contract, bookingDetails.companyId);
            setLoading(false)
            if (signedContract && signedContract.data) {
                window.open(`${window.location.origin}/p/booking-confirmation?bid=${bookingId}&ts=${new Date().valueOf()}&signedContractId=${signedContract.data.id}`, "_blank")
            }
        }
        else {
            setLoading(false)
            window.open(`${window.location.origin}/p/booking-desclaimer?bid=${bookingId}&ts=${new Date().valueOf()}`, "_blank")
        }
    }

    const handleCheckOutPageClick = async () => {
        window.open(`${window.location.origin}/p/booking-payment?bid=${bookingId}&ts=${new Date().valueOf()}`, "_blank")
    }
    const handleConfirmationPageClick = async () => {
        window.open(`${window.location.origin}/p/booking-confirmation?bid=${bookingId}&ts=${new Date().valueOf()}`, "_blank")
    }

    const calculateTotals = (dispatchAss, addOnAss) => {
        let totalOfDispatchAssignments = sumBy(dispatchAss, (a) => parseFloat(a.vehiclePrice))
        let totalOfAddOnAssignments = sumBy(addOnAss, (a) => parseFloat(a.addOnPrice ? a.addOnPrice : 0) * parseFloat(a.quantity ? a.quantity : 1))
        return totalOfDispatchAssignments + totalOfAddOnAssignments;
    }

    const refreshBookingDetails = async () => {
        setLoading(true)
        await getBookingDetails(bookingsDispatch, bookingId);
        setLoading(false)
    }

    let total = calculateTotals(dispatches, addonAssignments);
    //bookingDetails.totalBeforeTaxAtBooking = formatToFixed(bookingDetails.totalBeforeTaxAtBooking);
    bookingDetails.depositAmount = formatToFixed(bookingDetails.depositAmount);
    bookingDetails.totalBeforeTax = formatToFixed(total);
    bookingDetails.totalTax = formatToFixed(bookingDetails.salesTaxPercentage ? (total * parseFloat(bookingDetails.salesTaxPercentage)) / 100 : 0);
    bookingDetails.totalAfterTax = formatToFixed(parseFloat(bookingDetails.totalBeforeTax) + parseFloat(bookingDetails.totalTax));
    // bookingDetails.amountOutstanding = formatToFixed(Math.abs(parseFloat(bookingDetails.amountPaid) - bookingDetails.totalAfterTax));

    return (
        <div className="w-100 content-section">

            <div className="px-3 mt-2">
                <Button
                    onClick={() => { props.history.push(`/mockup/bookings`) }}
                    style={{ backgroundColor: `${tripSettings.buttonColor}`, borderColor: 'transparent' }}
                >
                    <i className="fas fa-chevron-left" /> BACK TO BOOKINGS
                </Button>

                <Button
                    id="bookings_disclaimer"
                    className=""
                    style={{ backgroundColor: `${tripSettings.buttonColor}`, borderColor: 'transparent' }}
                    onClick={handleDesclaimer}>
                    DISCLAIMER PAGE
                </Button>

                <Button
                    id="bookings_confirmation_page"
                    disabled={!bookingId}
                    className=""
                    onClick={handleConfirmationPageClick}
                    style={{ backgroundColor: `${tripSettings.buttonColor}`, borderColor: 'transparent' }}
                >
                    Confirmation Page
                </Button>
                <Button
                    id="bookings_checkout_page"
                    disabled={!bookingId}
                    className=""
                    onClick={handleCheckOutPageClick}
                    style={{ backgroundColor: `${tripSettings.buttonColor}`, borderColor: 'transparent' }}
                >
                    Checkout Page
                </Button>


                <div className='col-12 head-div mt-2' style={{ background: tripSettings?.sectionColor }}>

                    <div>
                        <span className='sg-card-lt'>Trip Details</span>
                    </div>
                </div>
                <div className='col-12 '>
                    {bookingDetails.tripReference ? <span className='dtail'>Trip Reference: {bookingDetails.tripReference}</span> : null}
                    {bookingDetails.groupName ? <span className='dtail'>Group Name: {bookingDetails.groupName}</span> : null}
                    {bookingDetails.bookingQuoteId ? <span className='dtail'>Associated Quote ID #: {bookingDetails.bookingQuoteId}</span> : null}
                    {bookingDetails.purchaseOrder ? <span className='dtail'>Purchase Order #: {bookingDetails.purchaseOrder}</span> : null}
                </div>

                <div className='col-12 head-div mt-2' style={{ background: tripSettings?.sectionColor }}>

                    <div>
                        <span className='sg-card-lt'>Financial</span>
                    </div>
                </div>
                <div className='col-12 '>
                    {bookingDetails.paymentStatus ? <span className='dtail'>Payment Status: {bookingDetails.paymentStatus}</span> : null}
                    {bookingDetails.depositDueDate ? <span className='dtail'>Deposit Due Date: {bookingDetails.depositDueDate}</span> : null}
                    {bookingDetails.depositAmount ? <span className='dtail'>Deposit Amount: {bookingDetails.depositAmount}</span> : null}
                    {bookingDetails.finalPaymentDueDate ? <span className='dtail'>Final Payment Due Date: {bookingDetails.finalPaymentDueDate}</span> : null}
                    <span className='dtail'>Total Before Tax: {bookingDetails.totalBeforeTax || 0}</span>
                    <span className='dtail'>Total Tax: {bookingDetails.totalTax || 0}</span>
                    <span className='dtail'>Amount Paid: {bookingDetails.amountPaid || 0}</span>
                    <span className='dtail'>Total After Tax: {bookingDetails.totalAfterTax || 0}</span>
                    <span className='dtail'>Amount Outstanding: {bookingDetails.amountOutstanding || 0}</span>
                </div>

                <div className='col-12 head-div mt-2' style={{ background: tripSettings?.sectionColor }}>

                    <div>
                        <span className='sg-card-lt'>Routes</span>
                    </div>
                </div>
                {bookingVehicleAssignments.map((item) => (
                    <div className='d-flex list-div mt-2' onClick={() => { onVehicleClick(item) }}>

                        <div className='col-6'>
                            <div className={`color-line`} style={{ background: tripSettings?.mainColor }}>

                            </div>
                            <div className='card-l'>
                                <span className='card-lt'>{item.bookingId}</span>
                                <span className='card-lb'>{item.routeDescription}</span>
                            </div>
                        </div>
                        <div className='col-6 text-right pl-2'>
                            <div className='card-r'>
                                <span className='card-rb'><span className="ttl">Pick-Up</span> {item.firstDepartureTime}</span>
                                <span className='card-rb'><span className="ttl">Drop-of</span> {item.lastArrivalTime}</span></div>
                        </div>

                    </div>
                ))}





                {
                    loading && <Loading loading={loading} />

                }

                {
                    makePayment &&
                    <PaymentDialog
                        id={bookingDetails.id}
                        bookingId={bookingDetails.bookingId}
                        amountOutstanding={bookingDetails.amountOutstanding}
                        generalLedgerCodes={generalLedgerCodes}
                        onCloseClick={() => setMakePayment(false)}
                        refreshBookings={refreshBookingDetails}
                        total={bookingDetails.totalAfterTax}
                    />
                }


                {
                    loading &&
                    <Container className="mb-5 text-center">
                        <Modal show={true} animation={false} className="loading-modal text-center">
                            <Spinner animation="border" variant="primary" />
                        </Modal>
                    </Container>
                }
            </div>
        </div>
    )
}

export default BookingDetails;