export const initialStateBookings = {
    bookingDetails: {},
    addonAssignments: [],
    dispatches: [],
    priorBookings: [],
    upcomingBookings: [],
    loading: false,
    errorMessage: null
};

export const BookingsReducer = (state = initialStateBookings, action) => {
    switch (action.type) {
        case "REQUEST_FETCH_BOOKINGS": {
            return {
                ...state,
                loading: true
            }
        }
        case "FETCH_BOOKINGS_SUCCESS": {
            return {
                ...state,
                priorBookings: action.prior,
                upcomingBookings: action.upcoming,
                loading: false
            }
        }
        case "FETCH_BOOKINGS_ERROR": {
            return {
                ...state,
                errorMessage: action.error,
                loading: false
            }
        }
        case "REQUEST_BOOKING_DETAILS": {
            return {
                ...state,
                loading: true,
                bookingDetails: {},
                bookingPaymentDetails: {},
                addonAssignments: [],
                dispatches: [],
            }
        }
        case "FETCH_BOOKING_DETAILS_SUCCESS": {
            return {
                ...state,
                bookingDetails: action.details,
                addonAssignments: action.addonAssignments,
                dispatches: action.dispatches,
                loading: false
            }
        }
        case "FETCH_BOOKING_DETAILS_ERROR": {
            return {
                ...state,
                errorMessage: action.error,
                loading: false
            }
        }
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};
