export const initialStateVehicleAssignment = {
    vehicleAssignments: [],
    vehicleAssignmentDetail: {},
    segments:[],
    addOns:[],
    documents:[],
    vehicles:[],
    loading: false,
    errorMessage: null
};

export const VehicleAssignmentReducer = (state = initialStateVehicleAssignment, action) => {
    switch (action.type) {
        case "REQUEST_FETCH_VEHICLE_ASSIGNMENTS": {
            return {
                ...state,
                loading: true
            }
        }
        case "FETCH_VEHICLE_ASSIGNMENTS_SUCCESS": {
            return {
                ...state,
                vehicleAssignments: action.payload,
                loading: false
            }
        }
        case "FETCH_SEGMENTS_SUCCESS": {
            return {
                ...state,
                segments: action.payload,
                loading: false
            }
        }
        case "FETCH_ADDONS_SUCCESS": {
            return {
                ...state,
                addOns: action.payload,
                loading: false
            }
        }
        case "FETCH_VEHICLES_SUCCESS": {
            return {
                ...state,
                vehicles: action.payload,
                loading: false
            }
        }
        case "FETCH_DOCUMENTS_SUCCESS": {
            return {
                ...state,
                documents: action.payload,
                loading: false
            }
        }
        case "FETCH_VEHICLE_ASSIGNMENTS_ERROR": {
            return {
                ...state,
                errorMessage: action.error,
                loading: false
            }
        }
        case "REQUEST_FETCH_VEHICLE_ASSIGNMENT_DETAILS": {
            return {
                ...state,
                loading: true
            }
        }
        case "FETCH_VEHICLE_ASSIGNMENT_DETAILS_SUCCESS": {
            return {
                ...state,
                vehicleAssignmentDetail: action.payload,
                loading: false
            }
        }
        case "FETCH_VEHICLE_ASSIGNMENT_DETAILS_ERROR": {
            return {
                ...state,
                errorMessage: action.error,
                loading: false
            }
        }
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};