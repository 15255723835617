import React from 'react';
import { Navbar, Row, Col, Image } from 'react-bootstrap';
import './style.scss';
import Loader from "../Loading";

const mobileFooter = ({ fixed = true, logo, loading, mainColor, companylogo }) => {
    return (
        !loading ? <>
            <footer className={`footer ${fixed ? 'position-fixed' : 'position-relative'}`}>

                <div className='d-flex justify-content-between' style={{ "textAlign": "center", "background": "#f4f5f4" }}>
                    <a className="navbar-brand d-flex align-items-center justify-content-center bg-white mr-0" href="#">
                        <Image src={companylogo} className="m-0 p-0 logo" style={{ width: '50px' }} />
                    </a>
                    <a target="_blank" href="https://tbndrives.com/" rel="noopener noreferrer">
                        <Image
                            src={'https://tbndrives.com/wp-content/uploads/2022/01/180x180.png'}
                            className="img-fluid footer-logo m-2"
                            style={{ width: '50px' }} />
                    </a>
                </div>

            </footer>
        </> : <Loader />
    )
}
export default mobileFooter;