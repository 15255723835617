import Service from './baseService';
import { getCompanyId } from '../config';

export const addTransactionService = (data) => {
    return Service.post('/customer/transactions', data);
}

export const createBookingPortalTransactionService = (data, companyId) => {
    return Service.post(`/customer/transactions/booking-portal`, data, { params: { companyId } });
}

export const deleteBookingPortalTransactionService = (id, companyId) => {
    return Service.delete(`/customer/transactions/booking-portal/${id}`, { params: { companyId } });
}

export const createInvoiceTransactionService = (data, companyId) => {
    return Service.post(`/customer/transactions/invoice-transaction`, data, { params: { companyId } });
}

export const deleteInvoiceTransactionService = (id, companyId) => {
    return Service.delete(`/customer/transactions/invoice-transaction/${id}`, { params: { companyId } });
}

export const createTransactionService = (data) => {
    return Service.post(`/customer/transactions`, data, { params: { companyId: getCompanyId() } });
}

export const deleteTransactionService = (id,invoiceId) => {
    return Service.delete(`/transactions/${id}`, { params: { roleId: 1, invoiceId} });
}

export const getStatesService = () => {
    return Service.get('/customer/transactions/states')
}

export const getCountriesService = () => {
    return Service.get(`customer/transactions/countries`);
};
