import React, { useEffect, useState } from 'react';
import { Row, Card, Button, Container, Modal, Spinner, Form, FormGroup } from "react-bootstrap";
import CustomAccordion from '../../../../Components/CustomAccordion';
import MockupListingLayout from '../../../../Components/MockupLayout';
import SimpleTable from '../../../../Components/SimpleTable';
import {
    getListAddOns, getListVehicleSegments, getVehicleAssignmentDetails, useVehicleAssignmentsState,
    useAccountState, getTripSettings
} from '../../../../Context';
import { getListDispatch, getListDispatchDocuments } from '../../../../Context/actions/vehicleAssignmentsActions';
import { calculateTotal, formatCurrency,convertQueryStringToObject } from '../../../../utils/helper';
import "./index.scss"


const BookingVehicleAssignments = (props) => {
    const [collapseItem, handleCollapse] = useState({ 1: false })
    const vehicleAssignmentId = props.match.params.id;
    const queryObj = convertQueryStringToObject(props.location.search);
    const { vehicleAssignments: { vehicleAssignmentDetail, segments, addOns, documents, vehicles, loading }, vehicleAssignmentsDispatch } = useVehicleAssignmentsState();
    const { AccountsDispatch, tripSettings: { tripSettings } } = useAccountState();

    useEffect(() => {
        (async () => {
            await getTripSettings(AccountsDispatch);
            await getVehicleAssignmentDetails(vehicleAssignmentsDispatch, queryObj.bookingId, vehicleAssignmentId)
            await getListVehicleSegments(vehicleAssignmentsDispatch, vehicleAssignmentId)
            await getListAddOns(vehicleAssignmentsDispatch, queryObj.bookingId, vehicleAssignmentId)
            await getListDispatch(vehicleAssignmentsDispatch, queryObj.bookingId, vehicleAssignmentId)
            await getListDispatchDocuments(vehicleAssignmentsDispatch, vehicleAssignmentId)
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const filenameRender = (item) => {
        let content = <p>{item.fileName}</p>;
        if (item.fileUrl) content = <a href={item.fileUrl} rel="noreferrer noopener" target="_blank">{item.fileName}</a>;
        return content;
    }
    const descriptionRender = (item, key) => {
        return <p className="truncate">{item[key]}</p>;
    }

    const handleItemCollapse = (e) => {
        const { id } = e.target
        handleCollapse({ ...collapseItem, [id]: !collapseItem[id] })
    }

    const currencyViewRenderer = (item, key) => {
        if (isNaN(item[key])) return '';
        return `$${formatCurrency(item[key])}`;
    }

    const totalRenderer = (key) => {
        return `$${formatCurrency(calculateTotal(vehicles, key))}`;
    }

    const totalRenderer1 = (key) => {
        return `$${formatCurrency(calculateTotal(addOns, key))}`;
    }

    return (
        <MockupListingLayout
            title={`Route Information ${vehicleAssignmentDetail.cancelledAt ? "(Cancelled)" : ""}`}
            titleIconClass={"fas fa-calendar-check"}
            textColor={tripSettings?.textColor}
            buttons={<Button
                onClick={() => { props.history.push(`/mockup/bookings/${queryObj.bookingId}`) }}
                style={{ backgroundColor: `${tripSettings?.buttonColor}`, borderColor: 'transparent' }}
            >
                BACK TO BOOKING DETAILS
            </Button>}
        >
            <CustomAccordion
                id={1}
                title="Trip Details"
                collapseItem={collapseItem}
                handleItemCollapse={handleItemCollapse}
                sectionColor={tripSettings?.sectionColor}
                className="px-2"
            >
                <Row className={`mx-1 ${vehicleAssignmentDetail.routeDescription ? '' : 'hidden'}`}>
                    <dl className={`col-12 col-md-6 details-list  ${vehicleAssignmentDetail.routeDescription ? '' : 'hidden'}`}>
                        <FormGroup>
                            <Form.Label>Route Description</Form.Label>
                            <Form.Control
                                type="text"
                                value={vehicleAssignmentDetail.routeDescription ? vehicleAssignmentDetail.routeDescription : ""}
                                readOnly
                            />
                        </FormGroup>
                    </dl>
                </Row>
                <Card>
                    <Card.Body className='p-3'>
                        {/* Sevment Table */}
                        <SimpleTable
                            fieldNames={[
                                ["startAddress", "Start Address"],
                                ["departureTime", "Departure Time"],
                                ["destinationAddress", "Destination Address"],
                                ["arrivalTime", "Arrival Time"],
                                ["miles", "Miles", null, calculateTotal(segments, 'miles')],
                            ]}
                            mainColor={tripSettings?.mainColor}
                            data={segments.filter(data => (data.isLiveSegment === 0 || data.liveHoursMiles === 0 ? false : true))}
                            showFooter
                        />
                    </Card.Body>
                    <Card.Footer>
                        <Row className={`mx-1 ${vehicleAssignmentDetail.numPassengers || vehicleAssignmentDetail.airline || vehicleAssignmentDetail.airlineTerminal || vehicleAssignmentDetail.flightNum ? '' : 'hidden'}`}>
                            <dl className={`col-12 col-md-6 col-lg-3 details-list  ${vehicleAssignmentDetail.numPassengers ? '' : 'hidden'}`}>
                                <FormGroup>
                                    <Form.Label># Of Passengers</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={vehicleAssignmentDetail.numPassengers ? vehicleAssignmentDetail.numPassengers : ""}
                                        readOnly
                                    />
                                </FormGroup>
                            </dl>
                            <dl className={`col-12 col-md-6 col-lg-3 details-list  ${vehicleAssignmentDetail.airline ? '' : 'hidden'}`}>
                                <FormGroup>
                                    <Form.Label>Airline</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={vehicleAssignmentDetail.airline ? vehicleAssignmentDetail.airline : ""}
                                        readOnly
                                    />
                                </FormGroup>
                            </dl>
                            <dl className={`col-12 col-md-6 col-lg-3 details-list  ${vehicleAssignmentDetail.airlineTerminal ? '' : 'hidden'}`}>
                                <FormGroup>
                                    <Form.Label>Airline Terminal</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={vehicleAssignmentDetail.airlineTerminal ? vehicleAssignmentDetail.airlineTerminal : ""}
                                        readOnly
                                    />
                                </FormGroup>
                            </dl>
                            <dl className={`col-12 col-md-6 col-lg-3 details-list  ${vehicleAssignmentDetail.flightNum ? '' : 'hidden'}`}>
                                <FormGroup>
                                    <Form.Label>Flight Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={vehicleAssignmentDetail.flightNum ? vehicleAssignmentDetail.flightNum : ""}
                                        readOnly
                                    />
                                </FormGroup>
                            </dl>
                        </Row>

                        <Row className={`mx-1 ${vehicleAssignmentDetail.pickupInstructions || vehicleAssignmentDetail.destinationInstructions ? '' : 'hidden'}`}>
                            <dl className={`col-12 col-md-6 col-lg-6 details-list  ${vehicleAssignmentDetail.pickupInstructions ? '' : 'hidden'}`}>
                                <dt>Pickup Instructions</dt>
                                {/* <dd>{vehicleAssignmentDetail.pickupInstructions ? vehicleAssignmentDetail.pickupInstructions : ""}</dd> */}
                                <Form.Control
                                    type="textarea"
                                    as="textarea"
                                    id="pickupInstructions"
                                    value={vehicleAssignmentDetail.pickupInstructions ? vehicleAssignmentDetail.pickupInstructions : ""}
                                    disabled
                                />
                            </dl>
                            <dl className={`col-12 col-md-6 col-lg-6 details-list  ${vehicleAssignmentDetail.destinationInstructions ? '' : 'hidden'}`}>
                                <dt>Destination Instructions</dt>
                                <Form.Control
                                    type="textarea"
                                    as="textarea"
                                    id="destinationInstructions"
                                    value={vehicleAssignmentDetail.destinationInstructions ? vehicleAssignmentDetail.destinationInstructions : ""}
                                    disabled
                                />
                                {/* <dd>{vehicleAssignmentDetail.destinationInstructions ? vehicleAssignmentDetail.destinationInstructions : ""}</dd> */}
                            </dl>
                        </Row>

                        <Row className={`mx-1 ${vehicleAssignmentDetail.additionalRouteDetails || vehicleAssignmentDetail.additionalTripReq ? '' : 'hidden'}`}>
                            <dl className={`col-12 col-md-6 col-lg-6 details-list  ${vehicleAssignmentDetail.additionalRouteDetails ? '' : 'hidden'}`}>
                                <dt>Additional Route Details</dt>
                                <Form.Control
                                    type="textarea"
                                    as="textarea"
                                    id="additionalRouteDetails"
                                    value={vehicleAssignmentDetail.additionalRouteDetails ? vehicleAssignmentDetail.additionalRouteDetails : ""}
                                    disabled
                                />
                                {/* <dd>{vehicleAssignmentDetail.additionalRouteDetails ? vehicleAssignmentDetail.additionalRouteDetails : ""}</dd> */}
                            </dl>
                            <dl className={`col-12 col-md-6 col-lg-6 details-list  ${vehicleAssignmentDetail.additionalTripReq ? '' : 'hidden'}`}>
                                <dt>Additional Trip Requirements</dt>
                                <Form.Control
                                    type="textarea"
                                    as="textarea"
                                    id="additionalTripReq"
                                    value={vehicleAssignmentDetail.additionalTripReq ? vehicleAssignmentDetail.additionalTripReq : ""}
                                    disabled
                                />
                                {/* <dd>{vehicleAssignmentDetail.additionalTripReq ? vehicleAssignmentDetail.additionalTripReq : ""}</dd> */}
                            </dl>
                        </Row>
                    </Card.Footer>
                </Card>
            </CustomAccordion>

            <CustomAccordion
                id={2}
                title="Vehicles"
                collapseItem={collapseItem}
                handleItemCollapse={handleItemCollapse}
                sectionColor={tripSettings?.sectionColor}
                className="px-2"
            >
                <SimpleTable
                    fieldNames={[
                        ["vehicleDisplayName", "Vehicle Type"],
                        ["vehiclePrice", "Vehicle Price", (item) => currencyViewRenderer(item, "vehiclePrice"), totalRenderer('vehiclePrice')],
                        ["salesTaxDesc", "Tax Description"],
                        ["salesTax", "Tax", (item) => currencyViewRenderer(item, "salesTax"), totalRenderer('salesTax')],
                    ]}
                    mainColor={tripSettings?.mainColor}
                    data={vehicles}
                    showFooter
                />
            </CustomAccordion>

            <CustomAccordion
                id={3}
                title="Addons"
                collapseItem={collapseItem}
                handleItemCollapse={handleItemCollapse}
                sectionColor={tripSettings?.sectionColor}
                className="px-2"
            >
                <SimpleTable
                    fieldNames={[
                        ["description", "Description"],
                        ["quantity", "Quantity"],
                        ["price", "Addon Price", (item) => currencyViewRenderer(item, 'price'), totalRenderer1('price')],
                        ["salesTaxDesc", "Tax Description"],
                        ["salesTax", "Tax", (item) => currencyViewRenderer(item, 'salesTax'), totalRenderer1('salesTax')],
                    ]}
                    data={addOns}
                    mainColor={tripSettings?.mainColor}
                    showFooter
                />
            </CustomAccordion>

            <CustomAccordion
                id={4}
                title="Dispatch Documents"
                collapseItem={collapseItem}
                handleItemCollapse={handleItemCollapse}
                sectionColor={tripSettings?.sectionColor}
                className="px-2"
            >
                <SimpleTable
                    fieldNames={[
                        ["fileName", "File Name", filenameRender],
                        ["description", "Description", (item) => descriptionRender(item, 'description')]
                    ]}
                    data={documents}
                    mainColor={tripSettings?.mainColor}
                />
            </CustomAccordion>

            {
                loading &&
                <Container className="mb-5 text-center">
                    <Modal show={true} animation={false} className="loading-modal text-center">
                        <Spinner animation="border" variant="primary" />
                    </Modal>
                </Container>
            }
        </MockupListingLayout>)
}


export default BookingVehicleAssignments;
