
export const getCompanyId = () => {
    let user = getCustomerDetails();
    return user["companyID"];
}

export const getEnterpriseId = () => {
    let url = window.location.href;
    const urlObject = new URL(url);
    const hostName = urlObject.hostname;
    let enterprise = '';
    let enterprises = [
        { "id": 4, "hostname": "localhost"},
        { "id": 21, "hostname": "tbn-customer-portal-dev.web.app"},
        { "id": 21, "hostname": "opv3.tbndrives.com"},
        { "id": 4, "hostname": "tbn-customer-portal-qa.web.app"},
        { "id": 1, "hostname": "opv2customerportal.thebusnetwork.com"},
        { "id": 2, "hostname": "dattcocustomerportal.thebusnetwork.com"},
        { "id": 4, "hostname": "customerportal.tbndrives.com"},
        { "id": 4, "hostname": "samplestagelinesportal.tbndrives.com"},
        { "id": 21, "hostname": "customerportal.thebusnetwork.com"},
        { "id": 30, "hostname": "besttoursportal.tbndrives.com"},
        { "id": 17, "hostname": "customer.goanderson.com"},
        { "id": 17, "hostname": "andersonportal.tbndrives.com"},
        { "id": 43, "hostname": "hamptonjitneyportal.tbndrives.com"},
        { "id": 41, "hostname": "elitecoachportal.tbndrives.com"},
        { "id": 40, "hostname": "affordablelimogrportal.tbndrives.com"},
        { "id": 39, "hostname": "christianbrosportal.tbndrives.com"},
        { "id": 38, "hostname": "king-grayportal.tbndrives.com"},
        { "id": 35, "hostname": "voigtportal.tbndrives.com"},
        { "id": 34, "hostname": "wernercoachportal.tbndrives.com"},
        { "id": 26, "hostname": "panoramaportal.tbndrives.com"},
        { "id": 36, "hostname": "ebmeyerportal.tbndrives.com"},
        { "id": 28, "hostname": "aaahicustomerdemo.tbndrives.com"},
    ]
    enterprises.forEach((c) => {
        if (hostName === c.hostname) {
                enterprise = c.id;
        }
    })
    return enterprise;
}


export const getCustomerDetails = () => {
    return JSON.parse(localStorage.getItem('user'))
}
