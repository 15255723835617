import { getBookingVehicleAssignmentDetailsService, getBookingVehicleAssignmentsService, getDispatchFilesService, listAddonAssignments, listDispatch, listVehicleSegments } from "../../services/vehicleAssignmentsService";

export async function getVehicleAddignments(dispatch,bookingId) {
    try {
        dispatch({ type: "REQUEST_FETCH_VEHICLE_ASSIGNMENTS" });
        let response = await getBookingVehicleAssignmentsService(bookingId);
        if (response.data) {
            dispatch({ type: "FETCH_VEHICLE_ASSIGNMENTS_SUCCESS", payload:response.data });
            return response.data;
        }
        dispatch({ type: 'FETCH_VEHICLE_ASSIGNMENTS_ERROR', error: response.data.errors[0] });
    } catch (error) {
        dispatch({ type: "FETCH_VEHICLE_ASSIGNMENTS_ERROR", payload: error.message });
    }
}

export async function getVehicleAssignmentDetails(dispatch,bookingId, id) {
    try {
        dispatch({ type: "REQUEST_FETCH_VEHICLE_ASSIGNMENT_DETAILS" });
        let response = await getBookingVehicleAssignmentDetailsService(bookingId, id);
        if (response.data) {
            dispatch({ type: "FETCH_VEHICLE_ASSIGNMENT_DETAILS_SUCCESS", payload:response.data });
            return response.data;
        }
        dispatch({ type: 'FETCH_VEHICLE_ASSIGNMENT_DETAILS_ERROR', error: response.data.errors[0] });
    } catch (error) {
        dispatch({ type: "FETCH_VEHICLE_ASSIGNMENT_DETAILS_ERROR", payload: error.message });
    }
}

export async function getListVehicleSegments(dispatch,relatedVehicleAssignment) {
    try {
        dispatch({ type: "REQUEST_FETCH_VEHICLE_ASSIGNMENTS" });
        let response = await listVehicleSegments(relatedVehicleAssignment);
        if (response.data) {
            dispatch({ type: "FETCH_SEGMENTS_SUCCESS", payload:response.data });
            return response.data;
        }
        dispatch({ type: 'FETCH_VEHICLE_ASSIGNMENTS_ERROR', error: response.data.errors[0] });
    } catch (error) {
        dispatch({ type: "FETCH_VEHICLE_ASSIGNMENTS_ERROR", payload: error.message });
    }
}

export async function getListAddOns(dispatch,bookingId, id) {
    try {
        dispatch({ type: "REQUEST_FETCH_VEHICLE_ASSIGNMENT_DETAILS" });
        let response = await listAddonAssignments(bookingId, id);
        if (response.data) {
            dispatch({ type: "FETCH_ADDONS_SUCCESS", payload:response.data });
            return response.data;
        }
        dispatch({ type: 'FETCH_VEHICLE_ASSIGNMENTS_ERROR', error: response.data.errors[0] });
    } catch (error) {
        dispatch({ type: "FETCH_VEHICLE_ASSIGNMENTS_ERROR", payload: error.message });
    }
}

export async function getListDispatch(dispatch,bookingId, id) {
    try {
        dispatch({ type: "REQUEST_FETCH_VEHICLE_ASSIGNMENT_DETAILS" });
        let response = await listDispatch(bookingId, id);
        if (response.data) {
            dispatch({ type: "FETCH_VEHICLES_SUCCESS", payload:response.data });
            return response.data;
        }
        dispatch({ type: 'FETCH_VEHICLE_ASSIGNMENTS_ERROR', error: response.data.errors[0] });
    } catch (error) {
        dispatch({ type: "FETCH_VEHICLE_ASSIGNMENTS_ERROR", payload: error.message });
    }
}

export async function getListDispatchDocuments(dispatch, id) {
    try {
        dispatch({ type: "REQUEST_FETCH_VEHICLE_ASSIGNMENT_DETAILS" });
        let response = await getDispatchFilesService(id);
        if (response.data) {
            dispatch({ type: "FETCH_DOCUMENTS_SUCCESS", payload:response.data });
            return response.data;
        }
        dispatch({ type: 'FETCH_VEHICLE_ASSIGNMENTS_ERROR', error: response.data.errors[0] });
    } catch (error) {
        dispatch({ type: "FETCH_VEHICLE_ASSIGNMENTS_ERROR", payload: error.message });
    }
}