import React, { useEffect, useState } from "react";
import MockupLayout from "../../../../Components/MockupLayout";
import CustomAccordion from "../../../../Components/CustomAccordion";
import SimpleTable from "../../../../Components/SimpleTable";
import { formatCurrency, totalAmountValues } from "../../../../utils/helper";
import { getInvoices, useInvoicesState, filterInvoices, getTripSettings, useAccountState } from "../../../../Context";
import "./index.scss"
import { getCustomerDetails } from "../../../../config";
import { Col, Form, Row } from "react-bootstrap";
import AddTransaction from "../../../../Components/AddTransaction";
import Loading from "../../../../Components/Loading";
import moment from "moment";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const MockupInvoices = ({ history }) => {
    const [collapseItem, handleCollapse] = useState({ 1: false })
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const { invoice: { invoices, invoicesCopy }, invoiceDispatch } = useInvoicesState()
    const { AccountsDispatch, tripSettings: { tripSettings } } = useAccountState();
    const styles = { cancelled: { backgroundColor: "rgb(240, 128, 128)" } }
    const user = getCustomerDetails()
    const [invoiceSelectedFilter, setInvoiceSelectedFilter] = useState("last30Days");
    const [loading, setLoading] = useState(false);


    let InvoicefilterOptions = [
        { title: "Today", value: "today" },
        { title: "Yesterday", value: "yesterday" },
        { title: "Last 7 Days", value: "last7Days" },
        { title: "Last 30 Days", value: "last30Days" },
        { title: "Last 60 Days", value: "last60Days" },
        { title: "Last 180 Days", value: "last180Days" },
        { title: "All Time", value: "allTime" },
    ]

    useEffect(() => {
        (async () => {
            setLoading(true)
            await getTripSettings(AccountsDispatch);
            await getInvoices(invoiceDispatch);
            setLoading(false)
        })()
    }, [AccountsDispatch, invoiceDispatch])

    useEffect(() => {
        filterInvoices(invoiceDispatch, invoicesCopy, invoiceSelectedFilter);
    }, [invoiceDispatch, invoiceSelectedFilter, invoicesCopy]);

    const refreshInvoices = async () => {
        setLoading(true)
        await getInvoices(invoiceDispatch);
        setLoading(false)
    }

    const rowStyle = (item) => {
        return styles[item.cancelledAt ? "cancelled" : ""];
    }

    const handleItemCollapse = (e) => {
        const { id } = e.target
        handleCollapse({ ...collapseItem, [id]: !collapseItem[id] })
    }

    const renderTotal = (item, key) => {
        return item[key] ? `$${formatCurrency(Number(item[key]))}` : "$0.00";
    }

    // const onPaymentClick = (e, item) => {
    //     e.stopPropagation();
    //     setSelectedInvoice(item);
    // }

    const onPaymentClick = (e, item) => {
        window.open(`${window.location.origin}/p/pay-invoice?ts=${moment(item.createdAt).unix()}&invoiceId=${item.id}`, "_blank")
    }

    const onInvoiceFilterChange = (e) => {
        filterInvoices(invoiceDispatch, invoicesCopy, e.target.value)
        setInvoiceSelectedFilter(e.target.value)
    }

    const renderMakePayment = (item) => {
        return item.invoicedAmount - item.paidAmount > 0 && !item.disableOnlinePayment ? <i className="fa fa-credit-card" onClick={(e) => onPaymentClick(e, item)} /> : "";
    }
    const fieldNames = [
        ["invoiceNumber", "Invoice Number", "", "TOT"],
        ["invoiceTotal", "Invoice Total", ((item) => renderTotal(item, "invoiceTotal")), totalAmountValues(invoices, 'invoiceTotal')],
        ["date", "Date"],
        ["makePayment", "Make Payment", renderMakePayment]
    ]
    if (user.showParentCompany) {
        fieldNames.unshift(["parentCompany", "Parent Company"])
        fieldNames.unshift(["company", "Company"])
    } else if (user.showCompany) {
        fieldNames.unshift(["company", "Company"])
    }

    return (
        !loading && tripSettings ?

            <div className="w-100 content-section">

                <div className="px-3 mt-2">
                    <Row>
                        <Col>
                            <Form.Control
                                as="select"
                                value={invoiceSelectedFilter}
                                className="w-50 mb-2"
                                onChange={onInvoiceFilterChange}
                            >
                                {InvoicefilterOptions.map(filter => <option key={filter.value} value={filter.value}>{filter.title}</option>)}

                            </Form.Control>
                        </Col>
                    </Row>

                    {invoices.filter(item => !item?.isVoided).map((item) => (
                        <div className='d-flex list-div mt-2' onClick={() => { }}>

                            <div className='col-6'>
                                <div style={{ ...rowStyle(item) }} className={`color-line`}>

                                </div>
                                <div className='card-l'>
                                    <span className='card-lt'>{item.invoiceNumber}</span>
                                    <span className='card-lb'>{item.date}</span>
                                </div>
                            </div>
                            <div className='col-4 card-r text-right'>
                                <div className='card-r'>
                                    <span className='card-lt q-total'>{renderTotal(item, "invoiceTotal")}</span>
                                    {/* <span className='card-rb'>{item.vehiclesTotal}</span> */}
                                </div>
                            </div>
                            <div className='col-2 card-r text-right'>
                                <div className='card-r cr-card'>
                                    <span className='card-lt'>{renderMakePayment(item)}</span>
                                    {/* <span className='card-rb'>{item.vehiclesTotal}</span> */}
                                </div>
                            </div>

                        </div>
                    ))}

                    {invoices.filter(item => !item?.isVoided).length === 0 ? <div className="text-center mt-2 not-found">No Data Found</div> : ``}

                </div>
            </div>

            :
            <Loading loading={loading} />
    )
}

export default MockupInvoices;