import { getGeneralLedgerCodesService } from '../../services/generalLedgerCodeService'

export async function getGeneralLedgerCodes(dispatch) {
    try {
        dispatch({ type: "REQUEST_FETCH_GENERAL_LEDGER_CODES" });
        let response = await getGeneralLedgerCodesService();
        if (response.data) {
            dispatch({ type: "FETCH_GENERAL_LEDGER_CODES_SUCCESS", payload:response.data });
            return response.data;
        }
        dispatch({ type: 'FETCH_GENERAL_LEDGER_CODES_ERROR', error: response.data.errors[0] });
    } catch (error) {
        dispatch({ type: "FETCH_GENERAL_LEDGER_CODES_ERROR", payload: error.message });
    }
}
