import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MockupLayout from "../../../../Components/MockupLayout";
import CustomAccordion from "../../../../Components/CustomAccordion";
import SimpleTable from "../../../../Components/SimpleTable";
import Loading from "../../../../Components/Loading";
import { formatCurrency, totalAmountValues } from "../../../../utils/helper";
import { getQuotes, editQuote, useQuotesState, getTripSettings, useAccountState } from "../../../../Context";
import { Alert, Col, Row, Form, Button } from "react-bootstrap";
import { getCustomerDetails } from "../../../../config";

const MockupQuotes = ({ history }) => {
    const [collapseItem, handleCollapse] = useState({ 1: false })
    const [upcomingFilter, setUpcomingFilter] = useState("next30Days")
    const [pastFilter, setPastFilter] = useState("last30Days")
    const { quotesDispatch, quotes: { priorQuotes, upcomingQuotes, errorMessage } } = useQuotesState();
    const { AccountsDispatch, tripSettings: { tripSettings } } = useAccountState();
    const [loading, setLoading] = useState(false);
    const user = getCustomerDetails()

    useEffect(() => {
        (async () => {
            setLoading(true)
            await getTripSettings(AccountsDispatch);
            await getQuotes(quotesDispatch, false, upcomingFilter, pastFilter);
            setLoading(false)
        })()
    }, [quotesDispatch, upcomingFilter, pastFilter, AccountsDispatch]);

    const handleRequestQuote = async (e, item) => {
        e.stopPropagation();
        try {
            if (item && item.id) {
                await editQuote(quotesDispatch, item.id, { customerStatusID: 2 }, { customerStatusID: 2 })
                await getQuotes(quotesDispatch, false, upcomingFilter, pastFilter);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleRejectQuote = async (e, item) => {
        e.stopPropagation();
        try {
            if (item && item.id) {
                await editQuote(quotesDispatch, item.id, { customerStatusID: 1 }, { customerStatusID: 1 })
                await getQuotes(quotesDispatch, false, upcomingFilter, pastFilter);
            }
        } catch (error) {
            console.log(error)
        }
    }


    const handleItemCollapse = (e) => {
        const { id } = e.target
        handleCollapse({ ...collapseItem, [id]: !collapseItem[id] })
    }
    const renderTotal = (item) => {
        return `$${formatCurrency(Number(item["total"]))}`;
    }

    const onAllVariationsChange = async (e) => {
        await getQuotes(quotesDispatch, e.target.checked);
    }

    const renderQuoteLink = (item) => {
        return item.quoteID ?
            <Link className="d-block p-1 mb-0" to={`/mockup/quotes/${item.id}`} >
                <u style={{ color: 'blue' }}>
                    {item.quoteID}
                </u>
            </Link> : "";
    }

    const renderButton = (item) => {
        if (item.options > 1 && item.quoteStatusID !== 4 && ![1, 2].includes(item.customerStatusID)) {
            return <Button style={{ backgroundColor: "Blue", border: 'none' }}>Review & Accept Options</Button>
        }
        else if (item.quoteStatusID === 4) {
            return <p><i>Booked Quote</i></p>
        }
        else if (item.customerStatusID === 1) {
            return <p><i>Quote Rejected</i></p>
        }
        else if (item.customerStatusID === 2) {
            return <p><i>Quote Has Been Requested</i></p>
        } else {
            return <>
                <Button style={{ backgroundColor: "#367609", border: 'none' }}
                    onClick={(e) => handleRequestQuote(e, item)}
                >
                    Request This Quote
                </Button>
                <Button className="ml-2" style={{ backgroundColor: "#F8551F", border: 'none' }}
                    onClick={(e) => handleRejectQuote(e, item)}
                >
                    Reject This Quote
                </Button>
            </>
        }
    }

    const onRowClick = (item) => {
        history.push(`/mockup/quotes/${item.id}`)
    }

    const rowStyle = (item) => {
        if (item.customerStatusID === 2)
            return { backgroundColor: '#FFFFE0' };
        else if (item.quoteStatusID === 4)
            return { backgroundColor: '#90EE90' };
        else return { backgroundColor: '#FFF' }
    };

    return (
        tripSettings ?
            <MockupLayout
                title={"Quotes"}
                titleIconClass={"fas fa-file-alt"}
                textColor={tripSettings?.textColor}
            >
                {errorMessage && <Alert variant="danger">Some error occurred while retrieving quotes. Please try again later.</Alert>}
                <Row>
                    <Col>
                        <Form.Check
                            className="fw-normal mx-2 my-1"
                            type="checkbox"
                            id="past"
                            name="past"
                            label="Show All Variations"
                            onChange={onAllVariationsChange}
                        />
                    </Col>
                </Row>
                <CustomAccordion
                    id={1}
                    title={"Upcoming Quotes"}
                    collapseItem={collapseItem}
                    handleItemCollapse={handleItemCollapse}
                    sectionColor={tripSettings?.sectionColor}
                >
                    <div className='d-flex align-items-center mb-2' >
                        <Form.Control
                            as="select"
                            value={upcomingFilter}
                            className="w-25"
                            onChange={(e) => setUpcomingFilter(e.target.value)}
                        >
                            <option value="today">Today</option>
                            <option value="tommorow">Tomorrow</option>
                            <option value="next7Days">Next 7 Days</option>
                            <option value="next30Days">Next 30 Days</option>
                            <option value="next60Days">Next 60 Days</option>
                            <option value="next180Days">Next 180 Days</option>
                            <option value="allTime">All Time</option>
                        </Form.Control>
                    </div>
                    <SimpleTable
                        data={upcomingQuotes}
                        showFooter={true}
                        bordered={true}
                        loading={loading}
                        mainColor={tripSettings?.mainColor}
                        rowStyle={rowStyle}
                        onClick={onRowClick}
                        fieldNames={[
                            user.showParentCompany ? ["parentCompany", "Parent Company"] : [],
                            ["company", "Company"],
                            ["quoteID", "Quote ID", (item) => renderQuoteLink(item), "TOT"],
                            ["tripReference", "Trip Reference"],
                            ["firstPickupLocation", "First Pick Up Location"],
                            ["initialDeparture", "Initial Departure"],
                            ["finalArrival", "Final Arrival"],
                            ["total", "Quotation Total", renderTotal, totalAmountValues(upcomingQuotes, 'total')],
                            ["", "", renderButton],
                        ]}
                    />
                </CustomAccordion>

                <CustomAccordion
                    id={2}
                    title={"Past Quotes"}
                    collapseItem={collapseItem}
                    handleItemCollapse={handleItemCollapse}
                    sectionColor={tripSettings?.sectionColor}
                >
                    <div className='d-flex align-items-center mb-2' >
                        <Form.Control
                            as="select"
                            value={pastFilter}
                            className="w-25"
                            onChange={(e) => setPastFilter(e.target.value)}
                        >
                            <option value="today">Today</option>
                            <option value="yesterday">Yesterday</option>
                            <option value="last7Days">Last 7 Days</option>
                            <option value="last30Days">Last 30 Days</option>
                            <option value="last60Days">Last 60 Days</option>
                            <option value="last180Days">Last 180 Days</option>
                            <option value="allTime">All Time</option>
                        </Form.Control>
                    </div>
                    <SimpleTable
                        data={priorQuotes}
                        showFooter={true}
                        bordered={true}
                        loading={loading}
                        rowStyle={rowStyle}
                        mainColor={tripSettings?.mainColor}
                        onClick={onRowClick}
                        fieldNames={[
                            user.showParentCompany ? ["parentCompany", "Parent Company"] : [],
                            ["company", "Company"],
                            ["quoteID", "Quote ID", (item) => renderQuoteLink(item), "TOT"],
                            ["tripReference", "Trip Reference"],
                            ["firstPickupLocation", "First Pick Up Location"],
                            ["initialDeparture", "Initial Departure"],
                            ["finalArrival", "Final Arrival"],
                            ["total", "Quotation Total", renderTotal, totalAmountValues(priorQuotes, 'total')],
                            ["", "", renderButton],
                        ]}
                    />
                </CustomAccordion>
            </MockupLayout> :
            <Loading loading={loading} />
    )
}

export default MockupQuotes;