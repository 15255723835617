export const initialStateTransactionTypes = {
    transactionTypes: [],
    loading: false,
    errorMessage: null
};

export const TransactionTypesReducer = (state = initialStateTransactionTypes, action) => {
    switch (action.type) {
        case "REQUEST_TRANSACTION_TYPES":
            return {
                ...state,
                loading: true,
                errorMessage: null
            }
        case "TRANSACTION_TYPES_SUCCESS":
            return {
                ...state,
                loading: false,
                transactionTypes: action.payload,
            }
        case "TRANSACTION_TYPES_ERROR":
            return {
                ...state,
                loading: false,
                errorMessage: action.error
            }
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};
