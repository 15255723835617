import {
    getContactService,
    loginService,loginImageService,
    sendForgetPasswordEmailService,
    updateContactService
} from '../../services/authServices';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

toast.configure();

export async function loginUser(dispatch, loginPayload) {
    try {
        dispatch({ type: 'REQUEST_LOGIN' });
        let response = await loginService(loginPayload)
            // let data = await response.json();

        if (response.data) {
            dispatch({ type: 'LOGIN_SUCCESS', payload: response.data.user });
            localStorage.setItem('user', JSON.stringify(response.data.user));
            localStorage.setItem('token', JSON.stringify(response.data.token));
            localStorage.setItem('customerportal_version', response.headers.customerportal_version);
            return response.data;
        }

        dispatch({ type: 'LOGIN_ERROR', error: response.data.errorMessage ? response.data.errorMessage : 'Error occured while login ' });
        return response.data.errorMessage ? response.data.errorMessage : 'Error occured while login ';
    } catch (error) {
        dispatch({
            type: 'LOGIN_ERROR',
            error: error.response.data.message ?
                error.response.data.message : 'Invalid Email or password!'
        });
    }
}

export async function getContact(dispatch, data) {
    console.log("hggggggg")
    try {
        let response = await getContactService(data)

        if (response.data) {
            dispatch({ type: 'GET_CONTACT_SUCCESS', payload: response.data });
            return response.data;
        }

        dispatch({ type: 'GET_CONTACT_ERROR', error: response.data.errors[0] });
    } catch (error) {
        dispatch({ type: 'GET_CONTACT_ERROR', error: error });
    }
}

export async function updateContact(dispatch, data, contactId) {
    try {
        let response = await updateContactService(data, contactId)

        if (response.data) {
            dispatch({ type: 'UPDATE_CONTACT_SUCCESS', payload: response.data });
            return response.data;
        }

        dispatch({ type: 'UPDATE_CONTACT_ERROR', error: response.data.errors[0] });
        return;
    } catch (error) {
        dispatch({ type: 'UPDATE_CONTACT_ERROR', error: error });
    }
}

export async function sendForgetPasswordEmail(dispatch, payload) {
    try {
        let response = await sendForgetPasswordEmailService(payload);
        if (response.data) {
            if (response.data[0].statusCode === 200 || 202) {
                dispatch({ type: 'SEND_EMAIL_SUCCESS', payload: response.data });
                return;
            }
        }
        dispatch({ type: 'SEND_EMAIL_ERROR', error: response.data.errors[0] });
        return;
    } catch (error) {
        dispatch({ type: 'SEND_EMAIL_ERROR', error: error });
    }
}

export async function getLoginImage(dispatch) {
    try {
        let response = await loginImageService();
        if (response.data) {
            dispatch({ type: 'GET_LOGIN_IMAGE_SUCCESS', payload: response.data });
            return response.data;
        }

        dispatch({ type: 'GET_LOGIN_IMAGE_ERROR', error: response.data.errors[0] });
        return;
    } catch (error) {
        dispatch({ type: 'GET_LOGIN_IMAGE_ERROR', error: error });
    }
}
