import Service from './baseService';
import { getCompanyId } from '../config';

// export const getSignedContractsByBookingIDService = (bookingId) => {
//     let params = { companyId: companyId() };
//     return service.get(`/signedContracts/${bookingId}`, { params });
// };

export const addSignedContractService = (data, companyId) => {
    let params = { companyId: companyId ? companyId : getCompanyId() };
    return Service.post(`/customer/signedContracts`, { data }, { params });
};

export const editSignedContractService = (id, data, companyId) => {
    let params = { companyId: companyId ? companyId : getCompanyId(), bookingID: data.bookingId };
    return Service.patch(`/customer/signedContracts/${id}`, { data }, { params });
};