export const initialStatePayment = {
    payments: [],
    loading: false,
    errorMessage: null
};

export const MakePaymentReducer = (state = initialStatePayment, action) => {
    switch (action.type) {
        case "REQUEST_PAYMENT":
            return {
                ...state,
                loading: true,
                errorMessage: null
            };

        case "PAYMENT_SUCCESS":
            return {
                ...state,
                payments: action.payload,
                loading: false
            };

        case "PAYMENT_ERROR":
            return {
                ...state,
                errorMessage: action.error,
                loading: false
            };
        default:
            throw new Error(`Unhandled type: ${action.type}`);
    }
}