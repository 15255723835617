import { getInvoicesService } from "../../services/invoicesService";
import moment from "moment";

export async function getInvoices(dispatch, status) {
    try {
        dispatch({ type: "REQUEST_GET_INVOICES" });
        let response = await getInvoicesService(status);

        if (response.data) {
            dispatch({ type: "GET_INVOICES_SUCCESS", payload: response.data });
            return response.data;
        }

        dispatch({ type: "GET_INVOICES_ERROR", error: response.data.errors[0] });
    } catch (e) {
        dispatch({ type: "GET_INVOICES_ERROR", error: e.message });
    }
}

export function filterInvoices(dispatch, invoicesCopy, filterByType) {
    dispatch({ type: "REQUEST_GET_INVOICES" });
    let filterInvoices = invoicesCopy;

    let today = moment().set({ second: 59, minute: 59, hour: 23 })
    let todayFilter = moment().set({ second: 59, minute: 59, hour: 23 }).format('MM-DD-YYYY')
    let formattedYesterday = today.clone().subtract(1, 'days').format('MM-DD-YYYY')
    let sevenDaysBefore = today.clone().subtract(6, 'days').set({ second: 0, minute: 0, hour: 0 }).format('MM-DD-YYYY')
    let thirtyDaysBefore = today.clone().subtract(29, 'days').set({ second: 0, minute: 0, hour: 0 }).format('MM-DD-YYYY')
    let sixtyDaysBefore = today.clone().subtract(59, 'days').set({ second: 0, minute: 0, hour: 0 }).format('MM-DD-YYYY')
    let OneFundredEightyDaysBefore = today.clone().subtract(179, 'days').set({ second: 0, minute: 0, hour: 0 }).format('MM-DD-YYYY')

    if (filterByType === 'today') {
        filterInvoices = invoicesCopy.filter(invoice => moment(invoice.date).valueOf() === moment(todayFilter).valueOf())
    }
    if (filterByType === 'yesterday') {
        filterInvoices = invoicesCopy.filter(invoice => moment(invoice.date).valueOf() === moment(formattedYesterday).valueOf())
    }
    if (filterByType === 'last7Days') {
        filterInvoices = invoicesCopy.filter(invoice => moment(invoice.date).valueOf() < moment(todayFilter).valueOf() && moment(invoice.date).valueOf() >= moment(sevenDaysBefore).valueOf())
    }
    if (filterByType === 'last30Days') {
        filterInvoices = invoicesCopy.filter(invoice => moment(invoice.date).valueOf() < moment(todayFilter).valueOf() && moment(invoice.date).valueOf() >= moment(thirtyDaysBefore).valueOf())
    }
    if (filterByType === 'last60Days') {
        filterInvoices = invoicesCopy.filter(invoice => moment(invoice.date).valueOf() < moment(todayFilter).valueOf() && moment(invoice.date).valueOf() >= moment(sixtyDaysBefore).valueOf())
    }
    if (filterByType === 'last180Days') {
        filterInvoices = invoicesCopy.filter(invoice => moment(invoice.date).valueOf() < moment(todayFilter).valueOf() && moment(invoice.date).valueOf() >= moment(OneFundredEightyDaysBefore).valueOf())
    }
    if (filterByType === 'allTime') {
        filterInvoices = invoicesCopy
    }
    filterInvoices  = filterInvoices && filterInvoices.sort((a,b) => new moment(b.date).format('YYYYMMDD') - new moment(a.date).format('YYYYMMDD'))

    dispatch({ type: 'SET_FILTERED_INVOICES', payload: { filterInvoices } });


}