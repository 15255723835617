import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { Row, Col, Card, Tabs, Modal, Tab, Form, Button, Image, Container, FormGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Loading from '../Loading';
import axios from 'axios';
import { getBookingConfirmationDetailsService } from "../../services/bookingsService";
import { editSignedContractService, addSignedContractService } from "../../services/signedContractsService";
import './index.scss'
import { convertQueryStringToObject, formatCurrency, dispatchModal, formatToFixed } from "../../utils/helper";
import moment from "moment";

const ListVehicles = ({ price, description, taxDescription, salesTax }) => {
    return (
        <Col xs={12} className="booking-details p-0">
            <Row>
                <Col lg={4} md={4} sm={8} xs={12}>
                    <h4 className="booking-qty">
                        <h5 className="list-content"> {description ? description : ''}</h5>
                    </h4>
                </Col>
                <Col lg={2} md={2} sm={4} xs={12}>
                    <h4 className="booking-qty">
                        Vehicle Price:<h5 className="list-content"> {`$${price ? formatCurrency(price) : '0.00'}`}</h5>
                    </h4>
                </Col>
                {salesTax && <><Col lg={4} md={4} sm={8} xs={12}>
                    <h4 className="booking-qty">
                        Tax Type:<h5 className="list-content"> {taxDescription ? taxDescription : ''}</h5>
                    </h4>
                </Col>
                    <Col lg={2} md={2} sm={4} xs={12}>
                        <h4 className="booking-qty">
                            Tax: <h5 className="list-content">{`$${salesTax ? formatCurrency(salesTax) : '0.00'}`}</h5>
                        </h4>
                    </Col></>}
            </Row>
        </Col>
    )

}

const ListAddons = ({ price, quantity, description, taxDescription, salesTax }) => {
    return (
        <Col xs={12} className="booking-details p-0">
            <Row>
                <Col lg={3} md={6} sm={8} xs={12}>
                    <h4 className="booking-qty">
                        <h5 className="list-content"> {description ? description : ''}</h5>
                    </h4>
                </Col>
                <Col lg={3} md={2} sm={4} xs={12}>
                    <h4 className="booking-qty">
                        <h5 className="list-content"> {quantity ? quantity : 0}</h5>
                    </h4>
                </Col>
                <Col lg={2} md={2} sm={4} xs={12}>
                    <h4 className="booking-qty">
                        Addon Price:<h5 className="list-content"> {`$${price ? formatCurrency(price) : '0.00'}`}</h5>
                    </h4>
                </Col>
                {salesTax && <><Col lg={3} md={6} sm={4} xs={12}>
                    <h4 className="booking-qty">
                        Tax Type:<h5 className="list-content"> {taxDescription ? taxDescription : ''}</h5>
                    </h4>
                </Col>
                    <Col lg={1} md={4} sm={4} xs={12}>
                        <h4 className="booking-qty">
                            Tax:<h5 className="list-content"> {`$${salesTax ? formatCurrency(salesTax) : '0.00'}`}</h5>
                        </h4>
                    </Col></>}
            </Row>
        </Col>
    )

}

const ListSegments = ({ departureTime, arrivalTime, startAddress, destinationAddress }) => {
    return (
        <Col xs={12} className="p-0">
            <div className="booking-details">
                <div className="vehicle-box-info">
                    <div className="col-3 p-0">
                        <p className="booking-qty">
                            <span className="text-uppercase">{departureTime}</span>
                            <span className="text-uppercase">{arrivalTime}</span>
                        </p>
                    </div>
                    <div className="col-9  p-0">
                        <p className="booking-qty">
                            <span><b>DEPART FROM</b>  {`${startAddress}`} </span>
                            <span><b>ARRIVE AT </b>{`${destinationAddress}`}</span>
                        </p>
                    </div>
                </div>
            </div>
        </Col>
    )
}

const BookingConfirmation = (props) => {
    const history = useHistory();
    const queryObj = convertQueryStringToObject(window.location.search);
    const bookingId = queryObj.bid;
    const companyId = queryObj.companyId;
    let signedContractId;
    if (props?.location?.state?.signedContractId) signedContractId = props?.location?.state?.signedContractId
    else signedContractId = queryObj.signedContractId

    const { register, handleSubmit } = useForm();
    const [loading, setLoading] = useState(true);
    const [accept, setAccept] = useState(false);
    const [signature, setSignature] = useState('');
    const [routeData, setRouteData] = useState([]);
    const [tripSetting, setTripSetting] = useState({});
    const [bookingDetails, setBookingDetails] = useState({});
    const [contactDetails, setContactDetail] = useState({});
    const [ip, setIP] = useState('');
    const [contractId, setContractId] = useState();
    const [formData, setFormData] = useState({ name: '' });
    const [totals, setTotals] = useState({
        vehiclesTotal: null,
        addonsTotal: null,
    });

    useEffect(() => {
        (async () => {
            setLoading(true);
            let booking = await getBookingConfirmationDetailsService(bookingId);
            if (booking && Object.keys(booking.data).length > 0) {
                let bookingData = booking.data;
                setBookingDetails(bookingData.details);

                let contact = bookingData?.contactDetail
                setContactDetail(contact);

                let confirmationDetails = bookingData?.routeData
                setRouteData(confirmationDetails);

                let tripSetting = bookingData.accountSettings;
                const root = document.documentElement;
                root?.style.setProperty(
                    "--background-color", tripSetting.mainColor
                );
                root?.style.setProperty(
                    "--background-color-active", tripSetting.textColor
                );
                setTripSetting(tripSetting)
                let addonAssignment = bookingData.addonData;
                let vehicles = bookingData.dispatches;

                let addonsTotal = Array.isArray(addonAssignment) ? addonAssignment.reduce((sum, addon) => sum + Number(addon.price), 0) : 0;
                let vehiclesTotal = Array.isArray(vehicles) ? vehicles.reduce((sum, dispatch) => sum + Number(dispatch.price), 0) : 0;
                setTotals({
                    bookingTotal: addonsTotal + vehiclesTotal,
                    vehiclesTotal: formatToFixed(vehiclesTotal),
                    addonsTotal: formatToFixed(addonsTotal),
                })
            } else {
                dispatchModal("Booking Record Not Found.")
            }
            setLoading(false);
        })()
    }, [bookingId, companyId]);

    const getIpAddress = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        setIP(res.data.IPv4)
    }

    useEffect(() => {
        //passing getIpAddress method to the lifecycle method
        getIpAddress()
    }, [])

    // const handleprint = async () => {
    //     try {
    //         setLoading(true);
    //         const pdfData = await printSignedBookingService(bookingId, signedContractId ? signedContractId : contractId, bookingDetails.companyId, user);
    //         setLoading(false);
    //         let blob = new Blob([pdfData.data], { type: 'application/pdf' })
    //         let link = document.createElement('a')
    //         link.href = window.URL.createObjectURL(blob)

    //         link.download = 'Booking (' + bookingDetails.bookingId + ')'
    //         link.click()
    //     }
    //     catch (e) {
    //         setLoading(false);
    //         console.log(e)
    //     }
    // }

    const handleAccept = async (e) => {
        e.preventDefault();
        await handleSubmit(async (data) => {
            try {
                setLoading(true);
                setFormData({ name: data.name })
                let sign = `<i style="margin-bottom: 0;font-size:19px; font-family: 'Brush Script MT';" >${data.name} </i>`;
                if (signedContractId) {
                    await editSignedContractService(signedContractId, {
                        bookingConfirmationSignature: data.name,
                        bookingConfirmationSignedAt: moment().format("YYYY-MM-DDTHH:mm:00"),
                        ipAddress: ip,
                        bookingID: bookingId,
                        notification: true
                    }, bookingDetails.companyId)
                }
                else {
                    let signedData = {
                        bookingID: bookingId,
                        termsConditionsAutoAccepted: 1,
                        bookingConfirmationSignature: data.name,
                        bookingConfirmationSignedAt: moment().format("YYYY-MM-DDTHH:mm:00"),
                        termsConditionsAutoAcceptedAt: moment().format("YYYY-MM-DDTHH:mm:00"),
                        disclaimerAutoAccepted: 1,
                        disclaimerAutoAcceptedAt: moment().format("YYYY-MM-DDTHH:mm:00"),
                        notification: true
                    }
                    let signedContract = await addSignedContractService(signedData, bookingDetails.companyId);
                    if (signedContract && signedContract.data && signedContract.data.id) setContractId(signedContract.data.id)
                }
                setSignature(sign)
                setAccept(false)
                setLoading(false);
            } catch (e) {
                console.log(e);
                setLoading(false);
                setAccept(false);
            }
        })()
    }

    const confirmation = () => {
        return (
            <Modal className="rounded warning-modal custom-modal" animation={false} size="lg" show={true} onHide={() => setAccept(false)} centered>
                <Modal.Header className="header-box  text-white" style={{ backgroundColor: `${tripSetting.sectionColor}` }}>
                    <Modal.Title>
                        <i className="fas fa-edit" style={{ fontSize: "30px", paddingLeft: "10px" }} />
                        <span className="accept-title pl-4" style={{ fontWeight: "800" }}> ACCEPT THIS BOOKING</span>
                    </Modal.Title>
                    <i className="fas fa-times-circle text-white closeButton" onClick={() => setAccept(false)} />
                </Modal.Header>
                <Modal.Body className="model-paragraph">
                    <div className="body-content" style={{ fontWeight: "800" }}>I have reviewed the details of the booking and agree that they are correct. confirm that I would like to book this trip. I understand that the price may be subject to changes and additions based on changes, additions, and the actual operation of the trip. also agree that any direct travel expenses will be in addition to the price of the trip.
                    </div>
                    <Form.Label className="modal-label" style={{ fontWeight: "800" }}>TYPE YOUR NAME HERE TO AGREE</Form.Label>
                    <Row>
                        <Col xs={6}>
                            <FormGroup className="d-flex">
                                <div className="d-flex flex-row align-items-center payment-form-disscount w-100">
                                    <Form.Control
                                        type="text"
                                        id="name"
                                        name="name"
                                        {...register('name', { required: true })}
                                    />
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                    <div className="agree-btn" >
                        <Button className="m-1"
                            style={{
                                backgroundColor: `${loading ? '#646262' : tripSetting.buttonColor}`,
                                borderColor: 'transparent'
                            }}
                            onClick={handleAccept}
                            disabled={loading}>
                            Agree & Continue
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

    return (
        !loading ?
            <div>
                <>
                    <div className="top-header">
                        <div className="top-header-box d-flex justify-content-between align-items-center" style={{ backgroundColor: `${tripSetting.sectionColor}` }}>
                            <div className="booking-title text-white">
                                {tripSetting && tripSetting.companyLogoUrl ?
                                    <Image src={tripSetting.companyLogoUrl} className="images-logo m-0 p-0" /> : ''}
                                <div className="booking-heading" style={{ color: `${tripSetting.textColor}` }}>
                                    {`BOOKING ${bookingDetails.bookingId ? bookingDetails.bookingId : ''}`}
                                </div>
                            </div>
                            <div className="button-head">
                                <Button className="text-white btn-confirm"
                                    style={{ backgroundColor: `${loading ? '#646262' : tripSetting.buttonColor}`, borderColor: 'transparent' }}
                                >
                                    <i className="fas fa-check-square mr-2"></i>CONFIRM BOOKING
                                </Button>
                            </div>
                        </div>
                        <div className="background-box-color ">
                            <div className="background-wrapper" style={{ backgroundColor: `${tripSetting.sectionColor}` }}>
                                <Container fluid>
                                    <>
                                        <Row>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <div className="w-100 payment-form-main">
                                                    <div className="payment-form-desc-main" style={{ backgroundColor: `${tripSetting.mainColor}` }}>
                                                        <Form.Label className=" text-uppercase payment-form-headding" style={{ color: `${tripSetting.textColor}` }}>Customer Information</Form.Label>
                                                        <div className="info-wrapper">
                                                            <div>
                                                                <span className="text-white "><h4 className="title-info">{contactDetails.organization ? contactDetails.organization : ''}</h4></span>
                                                            </div>
                                                            <div>
                                                                <span className="text-white"><h4 className="title-info">{contactDetails.fullName}</h4></span>
                                                            </div>
                                                            <div>
                                                                <span className="text-white"><h4 className="title-info">{contactDetails.jobTitle ? contactDetails.jobTitle : ''}</h4></span>
                                                            </div>
                                                            <div>
                                                                <span className="text-white"><h4 className="title-info">{contactDetails.department ? contactDetails.department : ''}</h4></span>
                                                            </div>
                                                            <div>
                                                                <span className="text-white"><h4 className="title-info">{contactDetails.email}</h4></span>
                                                            </div>
                                                            <div>
                                                                <span className="text-white"><h4 className="title-info">{contactDetails.phoneWork ? contactDetails.phoneWork : contactDetails.phoneMobile ? contactDetails.phoneMobile :
                                                                    contactDetails.phoneAlt ? contactDetails.phoneAlt : contactDetails.phoneHome ? contactDetails.phoneHome : ''}</h4></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col >

                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Row>
                                                    <Col xl={6} lg={12} md={12} sm={12} className="content-waraper">
                                                        <div className="h-100">
                                                            <div className="payment-form-main">
                                                                <div className=" payment-form-desc-main" style={{ backgroundColor: `${tripSetting.mainColor}` }}>
                                                                    <Form.Label className="text-uppercase title-text"
                                                                        style={{ color: `${tripSetting.textColor}` }}>
                                                                        Booking Total</Form.Label>
                                                                    <div className="info-wrapper">
                                                                        <span className="total-text"><p>{`$${formatCurrency(totals.bookingTotal)}`}</p></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xl={6} lg={12} md={12} sm={12} className="content-waraper">
                                                        <div className="h-100">
                                                            <div className="payment-form-main">
                                                                <div className="payment-form-desc-main" style={{ backgroundColor: `${tripSetting.mainColor}`, minHeight: '128px' }}>
                                                                    <Form.Label className=" text-uppercase title-text" style={{ color: `${tripSetting.textColor}` }}>Trip Reference</Form.Label>
                                                                    <div className="info-wrapper">
                                                                        <span className="total-text"><p>{bookingDetails.tripReference ? bookingDetails.tripReference : ''}</p></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xl={6} lg={12} md={12} sm={12} className="content-waraper">
                                                        <div className="h-100">
                                                            <div className="payment-form-main">
                                                                <div className=" payment-form-desc-main" style={{ backgroundColor: `${tripSetting.mainColor}` }}>
                                                                    <Form.Label className=" text-uppercase title-text" style={{ color: `${tripSetting.textColor}` }}>Total Vehicle Price</Form.Label>
                                                                    <div className="info-wrapper">
                                                                        <span className="total-text"><p>{`$${formatCurrency(totals.vehiclesTotal)}`}</p></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xl={6} lg={12} md={12} sm={12} className="content-waraper">
                                                        <div className="h-100">
                                                            <div className="payment-form-main">
                                                                <div className=" payment-form-desc-main" style={{ backgroundColor: `${tripSetting.mainColor}` }}>
                                                                    <Form.Label className=" text-uppercase title-text" style={{ color: `${tripSetting.textColor}` }}>Total Addon Price</Form.Label>
                                                                    <div className="info-wrapper">
                                                                        <span className="total-text"><p>{`$${formatCurrency(totals.addonsTotal)}`}</p></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col >
                                        </Row >

                                        <Card className="card-wrapper">
                                            <Card.Body className="card-wrapper-box" style={{ backgroundColor: `${tripSetting.sectionColor}` }}>
                                                <Tabs className="card-header-tabs-box"
                                                    id="controlled-tab-example"
                                                    defaultActiveKey="1"
                                                    style={{ backgroundColor: `${tripSetting.sectionColor}` }}>
                                                    {
                                                        routeData.map((data, i) =>
                                                            <Tab
                                                                key={i + 1}
                                                                eventKey={i + 1}
                                                                style={{ backgroundColor: `${tripSetting.sectionColor}` }}
                                                                title={` ${data.routeDescription ? data.routeDescription : `Route ${i + 1}`}`}>
                                                                {
                                                                    <Col xs={12} className="d-flex justify-content-center p-0">
                                                                        <div className="w-100 payment-form-main">
                                                                            <div className=" payment-form-desc-main" style={{ backgroundColor: `${tripSetting.mainColor}` }}>
                                                                                <Form.Label className=" text-uppercase payment-form-headding mb-2" style={{ color: `${tripSetting.textColor}` }}>Vehicle(S)</Form.Label>
                                                                                {Array.isArray(data.vehicleData) && data.vehicleData.length > 0 ? data.vehicleData.map(vehicle => {
                                                                                    return <ListVehicles
                                                                                        key={vehicle.id}
                                                                                        description={vehicle.vehicleType}
                                                                                        price={vehicle.price}
                                                                                        taxDescription={vehicle.taxDescription}
                                                                                        salesTax={vehicle.salesTax}
                                                                                    />
                                                                                }) :
                                                                                    <div className="text-white" >NO DETAILS FOUND </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </Col >
                                                                }

                                                                {

                                                                    <Col xs={12} className="d-flex justify-content-center p-0">
                                                                        <div className="w-100 payment-form-main">
                                                                            <div className=" payment-form-desc-main" style={{ backgroundColor: `${tripSetting.mainColor}` }}>
                                                                                <Form.Label className=" text-uppercase payment-form-headding mb-2" style={{ color: `${tripSetting.textColor}` }}>ADD ONS & EXTRAS</Form.Label>
                                                                                <div className="booking-wrap">
                                                                                    {Array.isArray(data.addonData) && data.addonData.length > 0 ? data.addonData.map(addon => {
                                                                                        return <ListAddons
                                                                                            key={addon.id}
                                                                                            quantity={addon.cost}
                                                                                            taxDescription={addon.taxDescription}
                                                                                            description={addon.description}
                                                                                            price={addon.price}
                                                                                            salesTax={addon.salesTax}
                                                                                        />
                                                                                    }) : <div className="text-white" >NO DETAILS FOUND </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Col >
                                                                }

                                                                {

                                                                    <Col xs={12} className="d-flex justify-content-center p-0">
                                                                        <div className="w-100 payment-form-main">
                                                                            <div className=" payment-form-desc-main" style={{ backgroundColor: `${tripSetting.mainColor}` }}>
                                                                                <Form.Label className=" text-uppercase payment-form-headding mb-2" style={{ color: `${tripSetting.textColor}` }}>Itinerary</Form.Label>
                                                                                <div className="booking-wrap">
                                                                                    {Array.isArray(data.segmentData) && data.segmentData.length > 0 ? data.segmentData.map(segment => {
                                                                                        return <ListSegments
                                                                                            key={segment.id}
                                                                                            departureTime={segment.departureTime}
                                                                                            arrivalTime={segment.arrivalTime}
                                                                                            startAddress={segment.startAddress}
                                                                                            destinationAddress={segment.destinationAddress}
                                                                                        />
                                                                                    }) : <div className="text-white" >NO DETAILS FOUND </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Col >
                                                                }
                                                            </Tab>)
                                                    }
                                                </Tabs>
                                            </Card.Body>
                                        </Card>
                                        {formData && !formData.name ? <div className="button-head">
                                            <Button className="mt-3"
                                                style={{
                                                    backgroundColor: `${loading ? '#646262' : tripSetting.buttonColor}`,
                                                    borderColor: 'transparent',
                                                }}
                                                onClick={() => setAccept(true)}>
                                                ACCEPT & CONTINUE
                                            </Button>
                                        </div> :
                                            <>
                                                <div className="accept-title m-2">
                                                    <h4 className="title" style={{ fontWeight: "500" }}>
                                                        I have reviewed the details above and agree that they are correct. I confirm that I would like to request that this booking. I accept the above price and understand that it may be subject to changes and additions if details of the trip change or if direct travel expenses are incurred.
                                                    </h4>
                                                </div>
                                                <div className="sign-box">

                                                    <div className="w-100 payment-form-main">
                                                        <div className=" payment-form-desc-main" style={{ backgroundColor: `${tripSetting.mainColor}` }}>
                                                            <Form.Label className="text-uppercase payment-form-headding" style={{ color: `${tripSetting.textColor}` }}>Signature</Form.Label>
                                                            <div className="booking-wrap text-white"
                                                                dangerouslySetInnerHTML={{ __html: signature }} />
                                                        </div>
                                                    </div>
                                                    <div className="w-100 payment-form-main">
                                                        <div className=" payment-form-desc-main" style={{ backgroundColor: `${tripSetting.mainColor}` }}>
                                                            <Form.Label className="text-uppercase payment-form-headding" style={{ color: `${tripSetting.textColor}` }}>Printed Name</Form.Label>
                                                            <div className="booking-wrap text-white">
                                                                {formData.name}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="w-100 payment-form-main">
                                                        <div className=" payment-form-desc-main" style={{ backgroundColor: `${tripSetting.mainColor}` }}>
                                                            <Form.Label className="text-uppercase payment-form-headding" style={{ color: `${tripSetting.textColor}` }}>Date</Form.Label>
                                                            <div className="booking-wrap text-white">
                                                                {moment().format("MM/DD/YYYY")}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="print-btn mt-2">

                                                    <Button
                                                        className="btn-primary ml-2"
                                                        style={{
                                                            backgroundColor: `${loading ? '#646262' : tripSetting.buttonColor}`,
                                                            borderColor: 'transparent'
                                                        }}
                                                        onClick={() => {
                                                            history.push(`/p/booking-payment?bid=${bookingId}&ts=${new Date().valueOf()}&contarctId=${signedContractId ? signedContractId : contractId}`)
                                                        }}
                                                    >
                                                        CHECKOUT PAGE
                                                    </Button>
                                                </div>
                                            </>
                                        }
                                        {
                                            accept && confirmation()
                                        }
                                    </>
                                </Container>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end p-4">
                        <Image src={'https://storage.googleapis.com/the-bus-network-bucket/default/Powered-By-TBN-Logo.png'} className="m-0 p-0" />
                    </div>
                </>
            </div> : <Loading loading={loading} />
    )
}

export default React.memo(BookingConfirmation, () => true);
