import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, FormGroup, Image, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Loading from "../Loading";
import { getInvoicePaymentDetailsService, printInvoiceService } from "../../services/invoicesService";
import { createInvoiceTransactionService, deleteInvoiceTransactionService } from "../../services/transactionsService";
import { convertQueryStringToObject, formatCurrency } from "../../utils/helper";
import swal from 'sweetalert';
import './index.scss'
import { makeBookingPortalPaymentService } from "../../services/authorizeNetService";

const InvoicePayment = () => {
    const queryObj = convertQueryStringToObject(window.location.search);
    const ts = queryObj.ts;
    const invoiceId = queryObj.invoiceId;

    const { register, handleSubmit, formState: { errors } } = useForm();
    const [loading, setLoading] = useState(true);
    const [companyDetails, setCompanyDetails] = useState({})
    const [tripSetting, setTripSetting] = useState({});
    const [invoice, setInvoice] = useState({});
    const [states, setStates] = useState([]);
    const [countries, setCountries] = useState([]);
    const [invoiceTotal, setInvoiceTotal] = useState();
    const [invoiceAmount, setInvoiceAmount] = useState();
    const [amountOutstanding, setAmountOutstanding] = useState();
    const [displayAmountOutstanding, setDisplayAmountOutstanding] = useState();
    const [ip, setIP] = useState('');
    const [cardType, setCardType] = useState('');
    const [disablePayment, setDisablePayment] = useState(false);
    const [formState, setFormState] = useState({ stage: 0, transactionId: "", amount: "" });

    useEffect(() => {
        (async () => {
            setLoading(true);
            let allData = await getInvoicePaymentDetailsService(invoiceId, ts)
            if (allData && allData.data) {
                allData = allData.data;
                setInvoice(allData.invoice)
                setInvoiceTotal(allData.invoice.invoicedAmount)

                //remainingAmount
                let invoicedAmount = allData.invoice.invoicedAmount;
                let amountPaid = allData.invoice.paidAmount
                let amountOutstanding = parseFloat(invoicedAmount - amountPaid).toFixed(2);
                if (allData.invoice.disableOnlinePayment) setDisablePayment(true)
                setAmountOutstanding(amountOutstanding > 0 ? amountOutstanding : '')
                setDisplayAmountOutstanding(amountOutstanding > 0 ? amountOutstanding : '')
                setTripSetting(allData.accountSettings)
                setCompanyDetails(allData.company)
                setStates(allData.states)
                setCountries(allData.countries)
                setLoading(false);
            }
        })()
    }, [invoiceId, ts]);

    const handleViewInvoice = async () => {
        try {
            setLoading(true);
            const pdfData = await printInvoiceService(invoiceId, companyDetails.id);
            setLoading(false);
            let blob = new Blob([pdfData.data], { type: 'application/pdf' })
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `Invoice ${invoice.invoiceNumber}.pdf`
            link.click()
        }
        catch (e) {
            setLoading(false);
            console.log(e)
        }
    }

    const getIpAddress = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        setIP(res.data.IPv4)
    }

    useEffect(() => {
        //passing getIpAddress method to the lifecycle method
        getIpAddress()
    }, [])

    const handleSubmitPayment = async (e) => {
        e.preventDefault();
        await handleSubmit(async (data) => {
            try {
                setLoading(true);
                if (data && data.cardNumber && data.cardNumber.length < 15) {
                    setLoading(false);
                    return swal('Card Number Error', 'Please enter a valid Card Number', 'error');
                }
                let user = JSON.parse(localStorage.getItem('user'));
                let transactionData = {
                    ...data,
                    invoiceID: invoiceId,
                    transactionTypeID: 3,
                    amount: amountOutstanding,
                    userID: user?.id || null,
                    date: moment().format("YYYY-MM-DD")
                };

                if (Number(invoiceAmount) > Number(invoiceTotal)) {
                    setLoading(false);
                    swal("Invalid Payment Amount", "Invoice Payment amount can not be greater than Invoice Total", "error");
                }
                else {
                    let transaction = await createInvoiceTransactionService(transactionData, companyDetails.id);
                    transaction = transaction.data;

                    if (Number(transactionData.transactionTypeID) === 3 || Number(transactionData.transactionTypeID) === 4) {
                        transactionData.orderID = transaction.id;
                        transactionData.invoice = invoiceId;
                        transactionData.productRefNum = invoiceId;
                        transactionData.unitPrice = transaction.amount;
                        transactionData.ecomind = 'E';
                        transactionData.customerID = ip;
                        transactionData.companyID = companyDetails.id;

                        let response = await makeBookingPortalPaymentService(transactionData, companyDetails.id)
                        response = response.data;
                        if (typeof response == 'undefined' || response.Result == 'AxiosError') {
                            window.swal("Transaction Failed", "We're Sorry. We have experienced an unknown error. Please wait a few minutes and try again. If the issue persists, please get in touch with us.", "error");
                            await deleteInvoiceTransactionService(transaction.id, companyDetails.id);
                            setLoading(false);
                            return;
                        }
                        else if (response.errors && response.errors.length > 0) {
                            swal("Transaction Declined", response.errors[0].msg, "error");
                            await deleteInvoiceTransactionService(transaction.id, companyDetails.id);
                            setLoading(false);
                            return;
                        }
                        else if (response.error) {
                            swal("Transaction Declined", response.error, "error");
                            await deleteInvoiceTransactionService(transaction.id, companyDetails.id);
                            setLoading(false);
                            return;
                        }
                        else if (response.Result === 'Declined') {
                            swal("Transaction Declined", response.Status, "error");
                            await deleteInvoiceTransactionService(transaction.id, companyDetails.id);
                            setLoading(false);
                            return;
                        }
                    }
                    let allData = await getInvoicePaymentDetailsService(invoiceId, ts)
                    if (allData && allData.data) {
                        allData = allData.data;
                        setInvoice(allData.invoice)
                        setInvoiceTotal(allData.invoice.invoicedAmount)

                        //remainingAmount
                        let invoicedAmount = allData.invoice.invoicedAmount;
                        let amountPaid = allData.invoice.paidAmount
                        let amountOutstanding = parseFloat(invoicedAmount - amountPaid).toFixed(2);
                        setAmountOutstanding(amountOutstanding > 0 ? amountOutstanding : '')
                        setDisplayAmountOutstanding(amountOutstanding > 0 ? amountOutstanding : '')
                        setLoading(false);
                    }
                    setFormState({
                        ...formState,
                        stage: 1,
                        transactionId: transaction.id,
                        amount: transaction.amount,
                    });
                }
                setLoading(false);
            } catch (e) {
                console.log(e);
            }
        })()
    }

    const DisablePaymentModal = () => {
        return (
            <div className="model-box">
                <div>
                    <div className="confirm-logo mt-2">
                        {tripSetting && tripSetting.companyLogoUrl ?
                            <Image src={tripSetting.companyLogoUrl} className="m-0 p-0 img-fluid" /> : ''}
                    </div>
                </div>

                <div className="pricelist-heading">
                    <div className="d-flex justify-content-center align-items-center flex-column">
                        <h5>Online Payments Disabled</h5>
                        <h6 className="disable-title">{`Online payments have been disabled for this Invoice. Please contact ${companyDetails.displayName} at ${companyDetails.companyPhone ? companyDetails.companyPhone : '123456789'} in order to arrange payment.`}</h6>
                    </div>
                    <div className=" d-flex align-items-center justify-content-center my-2">
                        <Image src={'https://storage.googleapis.com/the-bus-network-bucket/default/Powered-By-TBN-Logo.png'} className="m-0 p-0" />
                    </div>
                </div>
            </div>
        )
    }

    const confirmation = () => {
        return (
            <div className="model-box">
                <div>
                    <div className="confirm-logo mt-2">
                        {tripSetting && tripSetting.companyLogoUrl ?
                            <Image src={tripSetting.companyLogoUrl} className="m-0 p-0 img-fluid" /> : ''}
                    </div>
                </div>

                <div className="pricelist-heading">
                    <div className="d-flex justify-content-center align-items-center flex-column">
                        <h4>{`You Paid $${formatCurrency(formState.amount)}`}</h4>
                        <h6 className="title-price">{`${companyDetails.displayName} for Invoice ${invoice.invoiceNumber}`}</h6>
                    </div>

                    <div className="payment-method d-flex align-items-center justify-content-between my-3">
                        <span className="title-text">Payment Method</span>
                        <span className="number">{cardType ? cardType : 'Credit Card'}</span>
                    </div>
                    <div className="payment-method d-flex align-items-center justify-content-between my-3">
                        <span className="title-text">Transaction ID</span>
                        <span className="number">{`${formState.transactionId}`}</span>
                    </div>
                    <div className="border-line"></div>
                    <div className="payment-method d-flex align-items-center justify-content-between my-3">
                        <span className="title-text">Due Date</span>
                        <span className="number">{`${invoice.invoiceDueDate ? moment(invoice.invoiceDueDate).format('ll') : ''}`}</span>
                    </div>
                    <div className="payment-method d-flex align-items-center justify-content-between my-3">
                        <span className="title-text">Invoice Total</span>
                        <span className="number">{`$${formatCurrency(invoiceTotal)}`}</span>
                    </div>
                    <div className="payment-method d-flex align-items-center justify-content-between my-3">
                        <span className="title-text">Remaining Balance</span>
                        <span className="number">{`$${formatCurrency(amountOutstanding)}`}</span>
                    </div>

                    <div className=" d-flex align-items-center justify-content-center my-2">
                        <Image src={'https://storage.googleapis.com/the-bus-network-bucket/default/Powered-By-TBN-Logo.png'} className="m-0 p-0" />
                    </div>
                </div>
            </div>
        )
    }

    const paymentForms = () => {
        return <Row>
            <Col lg={8} md={7} xs={12} className="d-flex justify-content-center">
                <div className="left-panel-card">
                    <div>
                        <form onSubmit={(e) => handleSubmitPayment(e)}>
                            <div>
                                <h5 className="heading-first">Thank you for your business. Please use the following form to make your payment. </h5>
                                <h3 className="invoice-title">{`Invoice Total : $${formatCurrency(invoiceTotal)}`}</h3>

                                <FormGroup className="payment-card">
                                    <Form.Label className="label-payment">Payment Amount</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="amount"
                                        placeholder="Payment Amount"
                                        value={amountOutstanding}
                                        onChange={(e) => {
                                            setInvoiceAmount(Number(e.target.value))
                                            setAmountOutstanding(Number(e.target.value))
                                        }}
                                        // {...register('amount',{ required: 'Please enter a payment amount' })}
                                        isInvalid={!amountOutstanding}
                                    />
                                    <Form.Control.Feedback id="companyID" type="invalid">
                                        {'Please enter a payment amount'}
                                    </Form.Control.Feedback>
                                </FormGroup>

                                <FormGroup className="name-card-box">
                                    <Form.Label>Name on Card</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        placeholder="Card Name"
                                        {...register("name", { required: 'Please Enter a Card Name' })}
                                        isInvalid={!!errors["name"]}
                                        className="payment-form-input"
                                    />
                                    <Form.Control.Feedback id="companyID" type="invalid">
                                        {errors["name"]?.message}
                                    </Form.Control.Feedback>
                                </FormGroup>

                                <FormGroup>
                                    <Form.Label className="text-dark">Address Line 1</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="street"
                                        name="street"
                                        required={true}
                                        placeholder="Address Line 1"
                                        {...register('street', { required: 'Street Address is required' })}
                                        isInvalid={!!errors["street"]}
                                    />
                                    <Form.Control.Feedback id="companyID" type="invalid">
                                        {errors["street"]?.message}
                                    </Form.Control.Feedback>
                                </FormGroup>

                                <FormGroup>
                                    <Form.Label className="text-dark">Address Line 2</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="street2"
                                        name="street2"
                                        placeholder="Address Line 2"
                                        {...register('street2', { required: false })}
                                    />
                                    <Form.Control.Feedback id="companyID" type="invalid">
                                        {errors["street2"]?.message}
                                    </Form.Control.Feedback>
                                </FormGroup>

                                <div className="main-title-box d-flex   align-items-center">
                                    <FormGroup className="exp-date-title">
                                        <Form.Label className="text-dark">City</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="city"
                                            name="city"
                                            placeholder="City"
                                            {...register("city", { required: 'City is required' })}
                                            isInvalid={!!errors["city"]}
                                        />
                                        <Form.Control.Feedback id="companyID" type="invalid">
                                            {errors["city"]?.message}
                                        </Form.Control.Feedback>
                                    </FormGroup>

                                    <FormGroup className="cvv-code">
                                        <Form.Label className="text-dark">State</Form.Label>
                                        <Form.Control
                                            as="select"
                                            id="state"
                                            name="state"
                                            {...register("state", { required: 'State is required' })}
                                            placeholder="State"
                                            isInvalid={!!errors["state"]}
                                        >
                                            {states.length && states.map((r, i) =>
                                                <option key={i} value={r.abbreviation}>{r.abbreviation}</option>)
                                            }
                                        </Form.Control>
                                        <Form.Control.Feedback id="companyID" type="invalid">
                                            {errors["state"]?.message}
                                        </Form.Control.Feedback>
                                    </FormGroup>

                                    <FormGroup className="postal-code">
                                        <Form.Label className="text-dark">Zip</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="zip"
                                            name="zip"
                                            required={true}
                                            placeholder="Zip"
                                            {...register("zip", { required: 'Zip is required' })}
                                            isInvalid={!!errors["zip"]}
                                        />
                                        <Form.Control.Feedback id="companyID" type="invalid">
                                            {errors["zip"]?.message}
                                        </Form.Control.Feedback>
                                    </FormGroup>
                                </div>

                                <FormGroup>
                                    <Form.Label className="text-dark">Country</Form.Label>
                                    <Form.Control
                                        as="select"
                                        id="country"
                                        name="country"
                                        {...register("country", { required: 'Country is required' })}
                                        placeholder="country"
                                        isInvalid={!!errors["country"]}
                                    >
                                        {countries && countries.length > 0 && countries.map((r, i) =>
                                            <option key={i} value={r.alpha2Code}>{r.name}</option>)
                                        }
                                    </Form.Control>
                                    <Form.Control.Feedback id="companyID" type="invalid">
                                        {errors["country"]?.message}
                                    </Form.Control.Feedback>
                                </FormGroup>

                                <FormGroup>
                                    <Form.Label className="text-dark">Company</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="company"
                                        name="company"
                                        placeholder="Company"
                                        {...register('company', { required: false })}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Form.Label className="text-dark">Email</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="email"
                                        name="email"
                                        isInvalid={!!errors["email"]}
                                        placeholder="Email"
                                        {...register("email", { required: 'Email Address is required' })}
                                    />
                                    <Form.Control.Feedback id="companyID" type="invalid">
                                        {errors["email"]?.message}
                                    </Form.Control.Feedback>
                                </FormGroup>

                                <FormGroup className="card-number">
                                    <Form.Label>Card Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="cardNumber"
                                        required={true}
                                        maxLength={16}
                                        placeholder="Card Number"
                                        {...register("cardNumber", { required: 'Please Enter a Card Number' })}
                                        isInvalid={!!errors["cardNumber"]}
                                    />
                                    <Form.Control.Feedback id="companyID" type="invalid">
                                        {errors["cardNumber"]?.message}
                                    </Form.Control.Feedback>
                                </FormGroup>

                                <FormGroup className="card-number">
                                    <Form.Label>Card Type</Form.Label>
                                    <Form.Control as="select"
                                        id="cardType"
                                        name="cardType"
                                        isInvalid={!!errors["cardType"]}
                                        onChange={(e) => setCardType(e.target.value)}
                                        {...register("cardType", { required: 'Please select card type' })} >
                                        <option value=''>No Selection</option>
                                        <option value='American Express'>American Express</option>
                                        <option value='Discover'>Discover</option>
                                        <option value='Mastercard'>Mastercard</option>
                                        <option value='Visa'>Visa</option>
                                    </Form.Control>
                                    <Form.Control.Feedback id="companyID" type="invalid">
                                        {errors["cardType"]?.message}
                                    </Form.Control.Feedback>
                                </FormGroup>

                                <div className="main-title-box d-flex   align-items-center">
                                    <FormGroup className="exp-date-title">
                                        <Form.Label>Exp. Date</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="cardExpiration"
                                            name="cardExpiration"
                                            required={true}
                                            placeholder="Expiration Date(MMYY)"
                                            maxLength={4}
                                            {...register("cardExpiration", { required: 'Card Expiration Date is required' })}
                                            isInvalid={!!errors["cardExpiration"]}
                                        />
                                        <Form.Control.Feedback id="companyID" type="invalid">
                                            {errors["cardExpiration"]?.message}
                                        </Form.Control.Feedback>
                                    </FormGroup>

                                    <FormGroup className="cvv-code">
                                        <Form.Label>CVV Code</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="cvv"
                                            required={true}
                                            placeholder="CVV"
                                            maxLength={3}
                                            {...register("cvv", { required: 'CVV is required' })}
                                            isInvalid={!!errors["cvv"]}
                                        />
                                        <Form.Control.Feedback id="companyID" type="invalid">
                                            {errors["cvv"]?.message}
                                        </Form.Control.Feedback>
                                    </FormGroup>

                                    <FormGroup className="postal-code">
                                        <Form.Label>Postal Code</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="postalCode"
                                            required={true}
                                            placeholder="Postal Code"
                                            {...register("postalCode", { required: 'Postal Code is required' })}
                                            isInvalid={!!errors["postalCode"]}
                                            className="payment-form-select"
                                        />
                                        <Form.Control.Feedback id="companyID" type="invalid">
                                            {errors["postalCode"]?.message}
                                        </Form.Control.Feedback>
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="button-box d-flex justify-content-center">
                                <Button onClick={handleSubmitPayment}> <span className="mx-2"> <i className="fas fa-lock"></i> </span>Accept & Pay</Button>
                            </div>
                            <div className="payment-title-decline d-flex justify-content-center">
                                <span>Decline Payment</span>
                            </div>
                            <div className="comment-paragraph">
                                <p>
                                    {`By selecting accept and pay, I accept the terms of service and have read and acknowledge the privacy statement. I also allow ${companyDetails.displayName ? companyDetails.displayName : ''} to charge $${formatCurrency(invoiceAmount || amountOutstanding)} to my card on ${moment().format('ll')}`}
                                </p>
                            </div>
                        </form>
                    </div>
                </div>

            </Col >
            <Col lg={4} md={5} xs={12} className="d-flex flex-column justify-content-between">
                <div className="right-panel-card">
                    <div className="right-panel-one">
                        <div className="logo-images mt-2">
                            {tripSetting && tripSetting.companyLogoUrl ?
                                <Image src={tripSetting.companyLogoUrl} className="m-0 p-0" /> : ''}
                        </div>
                        <div className="sample-box">
                            <span className="title mt-3">{`${companyDetails.displayName ? companyDetails.displayName : ''}`}</span>
                        </div>
                        <div>
                            <div className="d-flex align-items-center justify-content-between my-2 invoice-title">
                                <span className="title">Invoice Number</span>
                                <span className="number">{`${invoice.invoiceNumber ? invoice.invoiceNumber : ''}`}</span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between my-2  invoice-title">
                                <span className="title">Due Date</span>
                                <span className="number">{`${invoice.invoiceDueDate ? moment(invoice.invoiceDueDate).format('ll') : ''}`}</span>
                            </div>
                        </div>
                    </div>

                    <div className="right-panel-two ">
                        <div className="d-flex align-items-center justify-content-between invoice-title my-2">
                            <span className="title">Invoice Amount</span>
                            <span className="number">{`${invoiceTotal ? `$${formatCurrency(invoiceTotal)}` : '$0.00'}`}</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between invoice-title my-2">
                            <span className="title">Amount Outstanding</span>
                            <span className="number">{`${displayAmountOutstanding ? `$${formatCurrency(displayAmountOutstanding)}` : '$0.00'}`}</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between invoice-title my-2">
                            <span className="title">Payment Amount</span>
                            <span className="number">{`${invoiceAmount ? `$${formatCurrency(invoiceAmount)}` : amountOutstanding ? `$${formatCurrency(amountOutstanding)}` : '$0.00'}`}</span>
                        </div>
                    </div>

                    <div className="right-panel-three">
                        <div className="button-view">
                            <Button onClick={handleViewInvoice}>View Invoice</Button>
                        </div>
                    </div>

                    <div className="my-4">
                        <span className="mr-2"><i className="fas fa-lock"></i></span> <span className="info-title">Information is protected and kept confidential</span>
                    </div>
                </div>
            </Col>
        </Row >
    }

    return (
        <>
            {!loading ?
                <div style={{ backgroundColor: `${tripSetting.sectionColor}` }}>
                    <div className="d-flex justify-content-end  align-items-center mini-navbar" style={{ backgroundColor: `${tripSetting.textColor}` }}>
                        <div className="d-flex  p-4">
                            <Image src={'https://storage.googleapis.com/the-bus-network-bucket/default/Powered-By-TBN-Logo.png'} className="m-0 p-0" />
                        </div>
                    </div>
                    <div >
                        <div className="main-wrap-box">
                            <Container>
                                {!disablePayment ? Number(formState.stage) === 1 ? confirmation() : paymentForms() : DisablePaymentModal()}
                            </Container>
                        </div>
                    </div>
                </div>
                : <Loading loading={loading} />}
        </>
    )
}

export default InvoicePayment;
