import {
    makePaymentService
} from '../../services/authorizeNetService';


export async function makePayment(dispatch, data, companyID) {
    try {
        dispatch({ type: 'REQUEST_PAYMENT' });

        let response = await makePaymentService(data, companyID);

        if (response.data) {
            dispatch({ type: 'PAYMENT_SUCCESS', payload: response.data });
            return response.data;
        }

        dispatch({ type: 'PAYMENT_ERROR', error: response.data.errors[0] });
        return;
    } catch (error) {
        dispatch({ type: 'PAYMENT_ERROR', error: error });
    }
}
