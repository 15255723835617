import { getTransactionTypesService } from '../../services/transactionTypesService';

export async function getTransactionTypes(dispatch) {
    try {
        dispatch({ type: "REQUEST_TRANSACTION_TYPES" });
        let response = await getTransactionTypesService();

        if (response.data) {
            dispatch({ type: 'TRANSACTION_TYPES_SUCCESS', payload: response.data });
            return response.data;
        }

        dispatch({ type: 'TRANSACTION_TYPES_ERROR', error: response.data.errors[0] });
    } catch (error) {
        dispatch({ type: 'TRANSACTION_TYPES_ERROR', error: error.message });
    }
}
