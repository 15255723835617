import { addTransactionService, getStatesService,getCountriesService,createTransactionService ,deleteTransactionService} from '../../services/transactionsService'

export async function addTransaction(dispatch, data) {
    try {
        dispatch({ type: "REQUEST_ADD_TRANSACTION" });
        let response = await addTransactionService(data);
        if (response.data) {
            dispatch({ type: "ADD_TRANSACTION_SUCCESS", payload:response.data });
            return response.data;
        }
        dispatch({ type: 'HANDLE_ERROR', error: response.data.errors[0] });
    } catch (error) {
        dispatch({ type: "HANDLE_ERROR", payload: error.message });
    }
}

export async function createTransaction(dispatch, data) {
    try {
        dispatch({ type: "REQUEST_CREATE_TRANSACTION" });
        let response = await createTransactionService(data);

        if(response.data) {
            dispatch({ type: "CREATE_TRANSACTION_SUCCESS", payload: response.data });
            return response.data;
        }

        dispatch({ type: "CREATE_TRANSACTION_ERROR", error: response.data.errors[0] });
    } catch (e) {
        dispatch({ type: "CREATE_TRANSACTION_ERROR", error: e.message });
    }
}

export async function deleteTransaction(dispatch, id,invoiceId) {
    try {
        dispatch({ type: "REQUEST_DELETE_TRANSACTION" });
        await deleteTransactionService(id,invoiceId);
        dispatch({ type: "DELETE_TRANSACTION_SUCCESS" });
    } catch (e) {
        dispatch({ type: "DELETE_TRANSACTION_ERROR", error: e.message });
    }
}

export async function getStates(dispatch, data) {
    try {
        let response = await getStatesService(data);
        if (response.data) {
            dispatch({ type: "GET_STATE_SUCCESS", payload:response.data });
            return response.data;
        }
        dispatch({ type: 'HANDLE_ERROR', error: response.data.errors[0] });
    } catch (error) {
        dispatch({ type: "HANDLE_ERROR", payload: error.message });
    }
}

export const getCountries = async (dispatch) => {
    try {
        let response = await getCountriesService();
        if(response.data) {
            dispatch({ type: "GET_COUNTRIES_SUCCESS", payload: response.data });
            return response.data;
        }
        dispatch({ type: "HANDLE_ERROR", error: response.data.errors[0] });
    } catch (error) {
        dispatch({ type: "HANDLE_ERROR", error: error.response?.data?.error });
    }
}