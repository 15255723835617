import React, { useEffect, useState } from "react";
import { Modal, Row, Col, FormGroup, Form, Button, Container, Spinner, InputGroup } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import axios from 'axios';
import {
    addTransaction,
    getTransactionTypes,
    useTransactionsState,
    useTransactionTypesState,
    useMakePaymentState,
    makePayment,
    getStates,
    deleteTransaction,
    useCompanyState,
    getCompanyDetails, getCountries, useAccountState, getTripSettings
} from "../../Context";
import { formatCurrency } from "../../utils/helper";
import {
    DatePicker
} from "react-tempusdominus-bootstrap";

const AddTransaction = ({
    onCloseClick = () => { },
    invoiceDetails,
    invoiceId = 1234,
    contact,
    transactions,
    refreshInvoices
}) => {
    const [formState, setFormState] = useState({ stage: 0, paymentType: "", amount: "", id: "" });
    const [invoiceData, setInvoiceData] = useState({
        invoiceTotal: 0,
        outstandingAmount: 0
    });
    const [generalLedgerCode, setGeneralLedgerCode] = useState('')
    const { register, handleSubmit, formState: { errors }, control, watch, setValue } = useForm({
        defaultValues: {
            date: new Date(),
            amount: parseFloat(Number(invoiceDetails.invoicedAmount) - Number(invoiceDetails.paidAmount)).toFixed(2)
        }
    });
    const { AccountsDispatch, tripSettings: { tripSettings } } = useAccountState();
    const [loading, setLoading] = useState(true);
    const { transactionTypesDispatch } = useTransactionTypesState();
    const { paymentDispatch } = useMakePaymentState();
    const transactionType = watch("transactionTypeID");
    const [TransactionType, setTransactionType] = useState([]);
    const { companyDispatch, companies: { companyDetails } } = useCompanyState();
    const { transactionsDispatch, transactions: { states, countries } } = useTransactionsState();

    const [ip, setIP] = useState('');
    const [disable, setDisable] = useState(false);
    const payments = invoiceDetails.paidAmount;

    useEffect(() => {
        let bookingsTotal = 0, transactionsTotal = 0;
        bookingsTotal = invoiceDetails.invoicedAmount;
        transactionsTotal = invoiceDetails.paidAmount;

        setInvoiceData({
            invoiceTotal: bookingsTotal,
            outstandingAmount: (bookingsTotal - transactionsTotal >= 0) ? bookingsTotal - transactionsTotal : 0
        });
        if (tripSettings?.defaultPaymentsGeneralLedgerCodeID) {
            setGeneralLedgerCode(tripSettings.defaultPaymentsGeneralLedgerCodeID)
        }
    }, [invoiceDetails.bookings, invoiceDetails.invoicedAmount, invoiceDetails.paidAmount, setValue, transactions, tripSettings]);

    useEffect(() => {
        let transactionTypeWatcher = Number(transactionType);
        if ((transactionTypeWatcher === 3 || transactionTypeWatcher === 4) && !companyDetails.paymentProcessorTypeID) {
            window.swal("No Payment Processor", "Your account has not been set up with a payment processor.If you would like to use this feature please contact your admin to help you with the setup process.", "info");
            setDisable(true)
        } else setDisable(false)
    }, [companyDetails.paymentProcessorTypeID, transactionType])

    useEffect(() => {
        (async () => {
            setLoading(true);
            await getStates(transactionsDispatch)
            await getCountries(transactionsDispatch)
            await getTripSettings(AccountsDispatch);
            let transaction = await getTransactionTypes(transactionTypesDispatch);
            transaction = transaction.filter(t => t.description !== "Refund" && t.description !== "Customer Credit" && t.description !== "Invoice" && t.description !== "Invoice (Tax)")
            setTransactionType(transaction);
            await getCompanyDetails(companyDispatch, invoiceDetails.companyID)
            setLoading(false);
        })()
    }, [companyDispatch, transactionTypesDispatch, AccountsDispatch, transactionsDispatch, invoiceDetails.companyID]);

    const getIpAddress = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        setIP(res.data.IPv4)
    }

    useEffect(() => {
        //passing getIpAddress method to the lifecycle method
        getIpAddress()
    }, [])

    const handleSubmitPayment = async (e) => {
        e.preventDefault();
        await handleSubmit(async (data) => {
            try {
                setLoading(true);
                let transactionData = {
                    ...data,
                    invoiceID: invoiceId,
                    companyID: invoiceDetails.companyID,
                    generalLedgerCodeID: generalLedgerCode ? generalLedgerCode : null
                    // userID: userDetails.id
                };
                if (Number(transactionData.amount) > (Number(invoiceData.invoiceTotal) - Number(payments))) {
                    window.swal("Amount Paid Exceeds Balance", "The value entered in the Amount Paid field is greater than the current Amount Outstanding for this Invoice. Please enter a value that is less than or equal to the balance due.", "warning");
                    setLoading(false);
                }
                else {
                    let transaction = await addTransaction(transactionsDispatch, transactionData);

                    if (Number(transactionData.transactionTypeID) === 3 || Number(transactionData.transactionTypeID) === 4) {
                        transactionData.orderID = transaction.id;
                        transactionData.invoice = invoiceDetails.invoiceNumber;
                        transactionData.productRefNum = invoiceDetails.invoiceNumber;
                        transactionData.unitPrice = transaction.amount;
                        transactionData.ecomind = 'T';
                        transactionData.customerID = ip;

                        let response = await makePayment(paymentDispatch, transactionData)
                        if (response.errors && response.errors.length > 0) {
                            window.swal("Transaction Declined", response.errors[0].msg, "error");
                            await deleteTransaction(transactionsDispatch, transaction.id);
                            setLoading(false);
                            return;
                        }
                        else if (response.error) {
                            window.swal("Transaction Declined", response.error, "error");
                            await deleteTransaction(transactionsDispatch, transaction.id);
                            setLoading(false);
                            return;
                        }
                        else if (response.Result === 'Declined') {
                            window.swal("Transaction Declined", response.Status, "error");
                            await deleteTransaction(transactionsDispatch, transaction.id);
                            setLoading(false);
                            return;
                        }
                    }
                    await refreshInvoices();

                    setFormState({
                        ...formState,
                        stage: 2,
                        id: transaction.id,
                        amount: transaction.amount,
                    });
                    setLoading(false);
                }
            } catch (e) {
                console.log(e);
            }
        })()
    }

    const paymentForms = <Row>
        <Col xs={4} className="bg-white text-center d-flex flex-column justify-content-between">
            <div>
                <p className="font-weight-bold mb-0 mt-3">Invoice ID</p>
                <h3 className="text-dark-blue">{invoiceDetails.invoiceNumber}</h3>
                <p className="font-weight-bold mb-0 mt-3">Invoice Total</p>
                <h3 className="text-dark-blue">{`$${formatCurrency(invoiceData.invoiceTotal)}`}</h3>
                <p className="font-weight-bold mb-0 mt-3">Payment(s)</p>
                <h3 className="text-dark-blue">{`$${formatCurrency(payments)}`}</h3>
                <hr />
                <p className="font-weight-bold mb-0 my-auto">Outstanding Balance</p>
                <h3 className="text-dark-blue">{`$${Number(invoiceData.invoiceTotal) - Number(payments) > 0 ? formatCurrency(Number(invoiceData.invoiceTotal) - Number(payments)) : formatCurrency(0.00)}`}</h3>
            </div>
        </Col>
        <Col xs={8} className="light-gray-bg d-flex justify-content-center align-items-center">
            <div className="m-3 w-100">
                <form onSubmit={(e) => handleSubmitPayment(e)}>
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <Form.Label className="text-dark">Transaction Type</Form.Label>
                                <Form.Control
                                    as="select"
                                    id="transactionTypeID"
                                    name="transactionTypeID"
                                    placeholder="Transaction Type"
                                    {...register('transactionTypeID')}
                                >
                                    {TransactionType.map(type => <option key={type.id} value={type.id}>{type.description}</option>)}
                                </Form.Control>
                            </FormGroup>
                        </Col>
                        {
                            (Number(transactionType) === 3 || Number(transactionType) === 4) &&
                            <>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Form.Label className="text-dark">Card Number</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="cardNumber"
                                            name="cardNumber"
                                            required={true}
                                            maxLength={16}
                                            placeholder="XXXX-XXXX-XXXX-XXXX"
                                            {...register('cardNumber')}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={4}>
                                    <FormGroup>
                                        <Form.Label className="text-dark">Card Type</Form.Label>
                                        <Form.Control as="select"
                                            id="cardType"
                                            name="cardType"
                                            {...register('cardType')} >
                                            <option value=''></option>
                                            <option value='American Express'>American Express</option>
                                            <option value='Discover'>Discover</option>
                                            <option value='Mastercard'>Mastercard</option>
                                            <option value='Visa'>Visa</option>
                                        </Form.Control>
                                    </FormGroup>
                                </Col>
                                <Col xs={4}>
                                    <FormGroup>
                                        <Form.Label className="text-dark">CVV</Form.Label>
                                        <Form.Control
                                            type="number"
                                            id="cvv"
                                            name="cvv"
                                            required={true}
                                            placeholder="XXX"
                                            maxLength={3}
                                            {...register('cvv')}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={4}>
                                    <FormGroup>
                                        <Form.Label className="text-dark">Card Expiration</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="cardExpiration"
                                            name="cardExpiration"
                                            required={true}
                                            placeholder="MMYY"
                                            maxLength={4}
                                            {...register('cardExpiration')}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={6}>
                                    <FormGroup>
                                        <Form.Label className="text-dark">First Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="firstName"
                                            name="firstName"
                                            required={true}
                                            placeholder="First Name"
                                            {...register('firstName')}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={6}>
                                    <FormGroup>
                                        <Form.Label className="text-dark">Last Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="lastName"
                                            name="lastName"
                                            required={true}
                                            placeholder="Last Name"
                                            {...register('lastName')}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Form.Label className="text-dark">Address Line 1</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="street"
                                            name="street"
                                            required={true}
                                            placeholder="Address Line 1"
                                            {...register('street')}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Form.Label className="text-dark">Address Line 2</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="street2"
                                            name="street2"
                                            placeholder="Address Line 2"
                                            {...register('street2')}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={4}>
                                    <FormGroup>
                                        <Form.Label className="text-dark">City</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="city"
                                            name="city"
                                            required={true}
                                            placeholder="City"
                                            {...register('city')}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={4}>
                                    <FormGroup>
                                        <Form.Label className="text-dark">State</Form.Label>
                                        <Form.Control
                                            as="select"
                                            id="state"
                                            name="state"
                                            placeholder="State"
                                        >
                                            {states.length && states.map((r, i) =>
                                                <option key={i} value={r.abbreviation}>{r.abbreviation}</option>)
                                            }
                                        </Form.Control>
                                    </FormGroup>
                                </Col>
                                <Col xs={4}>
                                    <FormGroup>
                                        <Form.Label className="text-dark">Zip</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="zip"
                                            name="zip"
                                            required={true}
                                            placeholder="Zip"
                                            {...register('zip')}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Form.Label className="text-dark">Country</Form.Label>
                                        <Form.Control
                                            as="select"
                                            id="country"
                                            name="country"
                                            {...register('country')}
                                            placeholder="country"
                                        >
                                            {countries && countries.length > 0 && countries.map((r, i) =>
                                                <option key={i} value={r.alpha2Code}>{r.name}</option>)
                                            }
                                        </Form.Control>
                                    </FormGroup>
                                </Col>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Form.Label className="text-dark">Company</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="company"
                                            name="company"
                                            required={true}
                                            placeholder="Company"
                                            {...register('company')}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Form.Label className="text-dark">Email</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="email"
                                            name="email"
                                            required={true}
                                            placeholder="Email"
                                            {...register('email')}
                                        />
                                    </FormGroup>
                                </Col>
                            </>
                        }

                        <Col xs={12}>
                            <FormGroup>
                                <Form.Label className="text-dark">Date</Form.Label>
                                <Controller
                                    render={() =>
                                        <div className="test">
                                            <DatePicker
                                                readOnly
                                                date={new Date()}
                                                className={'w-100'}
                                            />
                                        </div>}
                                    name='date'
                                    control={control}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Form.Label className="text-dark">
                                    {Number(transactionType) === 8 ? 'Amount Invoiced' : 'Amount Paid'} <span className="text-danger">*</span>
                                </Form.Label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>$</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        type="number"
                                        step="0.01"
                                        placeholder="XXX.XX"
                                        name="amount"
                                        min="0.00"
                                        readOnly={Number(transactionType) === 8}
                                        isInvalid={errors?.amount?.message}
                                        {...register('amount', { required: "Amount is required", min: { value: 1, message: "Amount should be greater than 0" } })}
                                    />
                                </InputGroup>
                                <p className="text-danger">
                                    {errors?.amount?.message}
                                </p>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Button block type="submit" disabled={disable}>
                        <i className="fas fa-pen" /> Make a Payment
                    </Button>
                </form>
            </div>
        </Col>
    </Row>

    const confirmation = <div className="light-gray-bg p-3 w-100 text-center">
        <i className="fa fa-thumbs-up text-dark fa-4x mt-2" />
        <h3 className="text-primary mb-2">Your Transaction is Successful</h3>
        <hr />
        <p className="mb-0 mt-3">Confirmation Number</p>
        <h4 className="text-primary mb-3">{formState.id}</h4>
        <p className="mb-0 mt-3">Amount Paid</p>
        <h4 className="text-primary mb-3">{`$${formatCurrency(formState.amount)}`}</h4>

        <p className="mb-0 my-3">A confirmation email has also been sent to the address on file.</p>
    </div>

    return (
        <Modal className="rounded warning-modal" animation={false} size="lg" show={true} onHide={onCloseClick} centered>
            <Modal.Header className="bg-primary  text-white">
                <Modal.Title>
                    <i className="fa fa-credit-card-alt text-dark mx-2" />
                    Record Payment
                </Modal.Title>
                <i className="fas fa-times-circle text-white closeButton" onClick={onCloseClick} />
            </Modal.Header>
            <Modal.Body>
                {
                    loading ?
                        <Container className="mb-5 text-center">
                            <Spinner animation="border" variant="primary" className="mt-5" />
                        </Container>
                        : formState.stage === 2 ? confirmation : paymentForms
                }
            </Modal.Body>
        </Modal>
    )
}

export default React.memo(AddTransaction, () => true);
