import _ from 'lodash';
import { render } from 'react-dom';
import { Button, Modal } from 'react-bootstrap';
import { getCustomerDetails } from '../config'
import * as dayjs from "dayjs";
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
dayjs.extend(utc)
dayjs.extend(timezone)

const contactDetails = getCustomerDetails()
export const formatCurrency = (currency) => {
  currency = Number(currency).toFixed(2);
  let cur = currency.toString().split('.');
  return `${cur[0].toString().replace(/(\d)(?=(\d{3})+$)/g, "$1,")}.${cur[1] ? cur[1] : '00'}`;
}

export const totalAmountValues = (data, key) => {
  return `$${formatCurrency(_.sumBy(data, (b) => Number(b[key])))}`;
}

export const calculateTotal = (dataSet, field) => {
  let total = _.sumBy(dataSet, function (rec) {
    return Number(rec[`${field}`] ? rec[`${field}`] : 0);
  });
  return total.toFixed(2);
}

export const formatToFixed = (value) => {
  if (typeof value === "undefined" || value === null || value === "") return "";
  else {
    let formattedValue = value.toString().replace(/[$,%]/g, '')
    formattedValue = parseFloat(formattedValue).toFixed(2);
    return formattedValue > 0 ? formattedValue : 0.00;
  }
}

export const formatToMMDDYYYY = (date) => {
  return contactDetails && dayjs.utc(date).tz(contactDetails.timeZoneDescription).format("MM/DD/YYYY");
}

const handleModal = (callback) => {

  const node = document.getElementsByName('message-modal')
  node[0].parentNode.classList.remove('modal-open')
  node[0].parentNode.removeAttribute('style');
  node[0].parentNode.removeChild(node[0]);
  const childNode = document.getElementsByClassName('fade modal show')
  childNode[0].parentNode.removeChild(childNode[0]);
  const childNodeBackDrop = document.getElementsByClassName('fade modal-backdrop show')
  childNodeBackDrop[0].parentNode.removeChild(childNodeBackDrop[0]);
  if (callback) callback();
}

export const dispatchModal = (message, callback = null) => {
  let div = document.getElementsByName('message-modal');
  if (!div.length) {
    const containerDomNode = document.createElement('div')
    containerDomNode.setAttribute('name', 'message-modal')
    document.body.appendChild(containerDomNode)
    render(
      <Modal show={true} onHide={() => handleModal()}>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleModal(callback)}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>,
      containerDomNode
    )

    return null
  }
}

export const removeAuthInfoFromLocalStorage = () => {
  localStorage.removeItem("customerportal_version");
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  sessionStorage.removeItem('lastTimeStamp');
}

export const convertQueryStringToObject = (searchQuery) => {
  // search can be get as [props.]location.search
  if (!searchQuery) return {};
  let search = searchQuery.toString().substring(1);
  return JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) { return key === "" ? value : decodeURIComponent(value) })
}