import React, { useEffect, useState } from "react";
import { Row, Col, FormGroup, Form, Button, Image, Container, FormControl } from "react-bootstrap";
import { useForm } from "react-hook-form";
import moment from 'moment';
import axios from 'axios';
import swal from 'sweetalert';
import Loading from '../Loading';
import { getBookingPaymentDetailsService, printSignedBookingService } from "../../services/bookingsService";
import { createBookingPortalTransactionService, deleteBookingPortalTransactionService } from "../../services/transactionsService";
import { makeBookingPortalPaymentService } from "../../services/authorizeNetService";
import { convertQueryStringToObject, formatCurrency, dispatchModal, formatToFixed } from "../../utils/helper";
import './index.scss'
let user = JSON.parse(localStorage.getItem('user'));

const ListVehicles = ({ price, quantity, description, tripSetting }) => {
    return (
        <Row noGutters>
            <Col xs={12}>
                <div className="booking-details">
                    <p className="booking-desc" style={{ color: `${tripSetting.textColor}` }}>{description}</p>
                    <div className="row">
                        <div className="col-7">
                            <p className="booking-qty">
                                QTY :<span className="text-white">  {quantity ? quantity : 0}</span>
                            </p>
                        </div>
                        <div className="col-5 text-right">
                            <p className="booking-price">
                                {`$${price ? formatCurrency(price) : '0.00'}`}
                            </p>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    )

}

const ListAddons = ({ price, quantity, description, priceTypeId, tripSetting }) => {
    return (
        <Row noGutters>
            <Col xs={12}>
                <div className="booking-details">
                    <div className="row">
                        <div className="col-7">
                            <p className="booking-qty" >
                                {(priceTypeId === 3 || priceTypeId === 8 || priceTypeId === 9) ?
                                    <span className="text-white" >{`${description} (${quantity ? quantity : 0})`}</span>
                                    : <span className="text-white" >{description}</span>}
                            </p>
                        </div>
                        <div className="col-5 text-right">
                            <p className="booking-price">
                                {`$${price ? formatCurrency(price) : '0.00'}`}
                            </p>
                        </div>
                    </div>
                </div>
            </Col>
        </Row >
    )

}

const ListDiscountCodes = ({ displayName, amount }) => {
    return (
        <Row noGutters>
            <Col xs={12}>
                <div className="booking-details">
                    <div className="row">
                        <div className="col-7">
                            <p className="booking-qty">
                                <span className="text-gray-light">{displayName}</span>
                            </p>
                        </div>
                        <div className="col-5 text-right">
                            <p className="booking-price">
                                {`-$${amount ? formatCurrency(amount) : '0.00'}`}
                            </p>
                        </div>
                    </div>
                </div>
            </Col>
        </Row >
    )
}

const BookingTransaction = (props) => {
    const queryObj = convertQueryStringToObject(window.location.search);
    const bookingId = queryObj.bid;
    const contarctId = queryObj.contarctId;

    const { register, handleSubmit } = useForm();
    const [loading, setLoading] = useState(true);
    const [states, setStates] = useState([]);
    const [countries, setCountries] = useState([]);
    const [addonAssignments, setAddonAssignments] = useState([]);
    const [discountCodes, setDiscountcodes] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [bookingID, setBookingID] = useState('');
    const [bookingDetails, setBookingDetail] = useState({});
    const [tripSetting, setTripSetting] = useState({});
    const [filterByType, setFilterByType] = useState("payDeposit");
    const [ip, setIP] = useState('');
    const [formState, setFormState] = useState({ stage: 0, paymentType: "", amount: "", id: "" });
    const [totals, setTotals] = useState({
        vehiclesTax: null,
        addonsTax: null,
    });
    const [errors, setError] = useState([]);


    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                let booking = await getBookingPaymentDetailsService(bookingId);
                if (booking && Object.keys(booking.data).length > 0) {
                    setBookingDetail(booking.data?.details)
                    setBookingID(booking.data?.details.bookingId)

                    let states = await booking.data?.states
                    setStates(states)
                    let addonAssignment = await booking.data?.addonData
                    setAddonAssignments(addonAssignment)
                    let discountCode = await booking.data?.discountCodeBookings
                    setDiscountcodes(discountCode)
                    let vehicles = await booking.data?.dispatches
                    setVehicles(vehicles);
                    let tripSetting = await booking.data?.accountSettings
                    setTripSetting(tripSetting)
                    let country = await booking.data?.countries
                    setCountries(country)

                    let addonsTax = Array.isArray(addonAssignment) ? addonAssignment.reduce((sum, addon) => sum + Number(addon.tax), 0) : 0;
                    let vehiclesTax = Array.isArray(vehicles) ? vehicles.reduce((sum, dispatch) => sum + Number(dispatch.tax), 0) : 0;
                    let addonsTotal = Array.isArray(addonAssignment) ? addonAssignment.reduce((sum, addon) => sum + Number(addon.price), 0) : 0;
                    let vehiclesTotal = Array.isArray(vehicles) ? vehicles.reduce((sum, dispatch) => sum + Number(dispatch.price), 0) : 0;
                    let discountsTotal = Array.isArray(discountCode) ? discountCode.reduce((sum, discount) => sum + Number(discount.amount), 0) : 0;
                    let subTotal = addonsTotal + vehiclesTotal - discountsTotal;
                    let tax = addonsTax + vehiclesTax;
                    let total = subTotal + tax;
                    let depositDueAmount = Number(bookingDetails.depositAmount) - Number(bookingDetails.amountPaid)
                    let finalPaymentDueAmount = Number(total) - Number(bookingDetails.amountPaid)
                    if (depositDueAmount <= 0) setFilterByType("payBalanceDue");

                    setTotals({
                        subTotal,
                        tax,
                        total,
                        depositDueAmount,
                        finalPaymentDueAmount,
                        amountPaid: Number(bookingDetails.amountPaid),
                        depositDue: bookingDetails.depositDue,
                        finalPaymentDue: bookingDetails.finalPaymentDue,
                        depositAmount: Number(bookingDetails.depositAmount),
                        vehiclesTax: formatToFixed(addonsTax),
                        addonsTax: formatToFixed(vehiclesTax),
                        vehiclesTotal: formatToFixed(addonsTotal),
                        addonsTotal: formatToFixed(vehiclesTotal),
                    })

                } else {
                    dispatchModal("Booking Record Not Found.")
                }
                setLoading(false);
            } catch (e) {
                console.log("e", e)
                setLoading(false);
            }
        })()
    }, [bookingDetails.amountPaid, bookingDetails.depositAmount, bookingDetails.depositDue, bookingDetails.finalPaymentDue, bookingId, totals.amountPaid, totals.depositAmount]);

    const getIpAddress = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        setIP(res.data.IPv4)
    }

    useEffect(() => {
        //passing getIpAddress method to the lifecycle method
        getIpAddress()
    }, [])

    const handleSubmitPayment = async (e) => {
        e.preventDefault();
        await handleSubmit(async (data) => {
            try {
                setLoading(true);
                const arr = [];
                if (!data.firstName) arr.push("firstName")
                if (!data.lastName) arr.push("lastName")
                if (!data.email) arr.push("email")
                if (!data.street) arr.push("street")
                if (!data.city) arr.push("city")
                if (!data.state) arr.push("state")
                if (!data.zip) arr.push("zip")
                if (!data.cardExpiration) arr.push("cardExpiration")
                if (!data.cardNumber) arr.push("cardNumber")
                if (!data.cvv) arr.push("cvv")

                setError([...arr]);

                if (!arr.length) {
                    if (data && data.cardNumber && data.cardNumber.length < 15) {
                        setLoading(false);
                        return swal({
                            title: "Card Number Error",
                            text: "Please enter a valid Card Number",
                            icon: "error",
                        });
                    }
                    let transactionData = {
                        ...data,
                        bookingID: bookingId,
                        companyID: bookingDetails.companyId,
                        transactionTypeID: 3,
                        userID: user && user.id ? user.id : null,
                        date: moment().format("YYYY-MM-DD")
                    };
                    if (filterByType === "payDeposit") {
                        transactionData.amount = String(totals.depositDueAmount);
                    }
                    else if (filterByType === "payBalanceDue") {
                        transactionData.amount = String(totals.finalPaymentDueAmount);
                    }
                    else if (filterByType === "payOtherAmount") {
                        if (!transactionData.otherAmount || Number(transactionData.otherAmount) <= 0) {
                            window.swal("Invalid Payment Amount", "Please select an amount to pay that is greater than $0.00", "error");
                        } else {
                            transactionData.amount = String(transactionData.otherAmount);
                        }
                    }

                    if (Number(parseFloat(transactionData.amount).toFixed(2)) > Number(bookingDetails.amountOutstanding)) {
                        setLoading(false);
                    } else {
                        let transaction = await createBookingPortalTransactionService(transactionData, bookingDetails.companyId);
                        transaction = transaction.data;

                        if (Number(transactionData.transactionTypeID) === 3 || Number(transactionData.transactionTypeID) === 4) {
                            transactionData.companyID = transaction.companyID;
                            transactionData.orderID = transaction.id;
                            transactionData.invoice = bookingId;
                            transactionData.productRefNum = bookingId;
                            transactionData.unitPrice = transaction.amount;
                            transactionData.ecomind = 'E';
                            transactionData.customerID = ip;
                            transactionData.PONum = bookingDetails.purchaseOrder ? bookingDetails.purchaseOrder : '';
                            let response = await makeBookingPortalPaymentService(transactionData, transactionData.companyID)
                            response = response.data;
                            if (typeof response == 'undefined' || response.Result == 'AxiosError') {
                                window.swal("Transaction Failed", "We're Sorry. We have experienced an unknown error. Please wait a few minutes and try again. If the issue persists, please get in touch with us.", "error");
                                await deleteBookingPortalTransactionService(transaction.id, bookingDetails.companyId);
                                setLoading(false);
                                return;
                            }
                            else if (response.errors && response.errors.length > 0) {
                                swal({ title: "Transaction Declined", text: response.errors[0].msg, icon: "error", });
                                await deleteBookingPortalTransactionService(transaction.id, bookingDetails.companyId);
                                setLoading(false);
                                return;
                            }
                            else if (response.error) {
                                swal({ title: "Transaction Declined", text: response.errors[0].msg, icon: "error", });
                                await deleteBookingPortalTransactionService(transaction.id, bookingDetails.companyId);
                                setLoading(false);
                                return;
                            }
                            else if (response.Result === 'Declined') {
                                swal({ title: "Transaction Declined", text: response.Status, icon: "error", });
                                await deleteBookingPortalTransactionService(transaction.id, bookingDetails.companyId);
                                setLoading(false);
                                return;
                            }
                        }
                        setFormState({
                            ...formState,
                            stage: 2,
                            id: transaction.id,
                            amount: transaction.amount,
                        });
                    }
                }
                setLoading(false);
            } catch (e) {
                console.log(e);
            }
        })()
    }

    const paymentForms = () => {
        return <Row>
            <Col md={8} xs={12} className="d-flex justify-content-center">
                <div className="w-100 payment-form-main" >
                    <form className="payment-form-wrapper" onSubmit={(e) => { handleSubmitPayment(e) }}>
                        <Form.Label className=" text-uppercase payment-form-headding" style={{ color: `${tripSetting.textColor}` }}>Billing Information</Form.Label>
                        <div className="payment-form-desc-main" style={{ backgroundColor: `${tripSetting.mainColor}` }}>
                            <Row>
                                <Col xs={12}>
                                    <FormGroup className="d-flex payment-form-group">
                                        <Form.Label className="payment-form-label" style={{ color: `${tripSetting.textColor}` }}>NAME*</Form.Label>
                                        <div className="d-flex flex-column payment-form-inpu-group w-100">
                                            <Form.Control
                                                type="text"
                                                id="firstName"
                                                name="firstName"
                                                placeholder="First Name"
                                                {...register('firstName')}
                                                isInvalid={errors.includes("firstName")}
                                                className="payment-form-input"
                                            />
                                            <FormControl.Feedback id="description" type="invalid">
                                                First Name is required
                                            </FormControl.Feedback>

                                            <Form.Control
                                                type="text"
                                                id="lastName"
                                                name="lastName"
                                                placeholder="Last Name"
                                                {...register('lastName')}
                                                isInvalid={errors.includes("lastName")}
                                                className="payment-form-input"
                                            />
                                            <FormControl.Feedback id="description" type="invalid">
                                                Last Name is required
                                            </FormControl.Feedback>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col xs={12}>
                                    <FormGroup className="d-flex payment-form-group">
                                        <Form.Label className="payment-form-label" style={{ color: `${tripSetting.textColor}` }}>COMPANY</Form.Label>
                                        <div className="d-flex flex-column payment-form-inpu-group w-100">
                                            <Form.Control
                                                type="text"
                                                id="company"
                                                name="company"
                                                placeholder="Company/Organization"
                                                {...register('company')}
                                                className="payment-form-input"
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col xs={12}>
                                    <FormGroup className="d-flex payment-form-group">
                                        <Form.Label className="payment-form-label" style={{ color: `${tripSetting.textColor}` }}>EMAIL*</Form.Label>
                                        <div className="d-flex flex-column payment-form-inpu-group w-100">
                                            <Form.Control
                                                type="text"
                                                id="email"
                                                name="email"
                                                placeholder="Email"
                                                {...register('email')}
                                                isInvalid={errors.includes("email")}
                                                className="payment-form-input"
                                            />
                                            <FormControl.Feedback id="description" type="invalid">
                                                Email is required
                                            </FormControl.Feedback>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col xs={12}>
                                    <FormGroup className="d-flex payment-form-group">
                                        <Form.Label className="payment-form-label" style={{ color: `${tripSetting.textColor}` }}>BILLING ADDRESS*</Form.Label>
                                        <div className="d-flex flex-column payment-form-inpu-group w-100">
                                            <Form.Control
                                                type="text"
                                                id="street"
                                                name="street"
                                                required={true}
                                                placeholder="Address Line 1"
                                                {...register('street')}
                                                isInvalid={errors.includes("street")}
                                                className="payment-form-input"
                                            />
                                            <FormControl.Feedback id="description" type="invalid">
                                                Street Address is required
                                            </FormControl.Feedback>

                                            <Form.Control
                                                type="text"
                                                id="street2"
                                                name="street2"
                                                placeholder="Address Line 2"
                                                {...register('street2')}
                                                className="payment-form-input"
                                            />
                                            <Form.Control
                                                type="text"
                                                id="city"
                                                name="city"
                                                required={true}
                                                placeholder="City"
                                                {...register('city')}
                                                isInvalid={errors.includes("city")}
                                                className="payment-form-input"
                                            />
                                            <FormControl.Feedback id="description" type="invalid">
                                                City is required
                                            </FormControl.Feedback>

                                            <div className="d-flex payment-form-select-group">
                                                <Form.Control
                                                    as="select"
                                                    id="state"
                                                    name="state"
                                                    placeholder="State"
                                                    className="payment-form-select"
                                                    {...register('state')}
                                                    isInvalid={errors.includes("state")}
                                                >
                                                    {states.length && states.map((r, i) =>
                                                        <option key={i} value={r.abbreviation}>{r.abbreviation}</option>)
                                                    }
                                                </Form.Control>
                                                <FormControl.Feedback id="description" type="invalid">
                                                    State is required
                                                </FormControl.Feedback>

                                                <Form.Control
                                                    type="text"
                                                    id="zip"
                                                    name="zip"
                                                    required={true}
                                                    placeholder="Zip"
                                                    {...register('zip')}
                                                    isInvalid={errors.includes("zip")}
                                                    className="payment-form-select"
                                                />
                                                <FormControl.Feedback id="description" type="invalid">
                                                    Zip Name is required
                                                </FormControl.Feedback>

                                                <Form.Control
                                                    as="select"
                                                    id="country"
                                                    name="country"
                                                    className="payment-form-select"
                                                    placeholder="country"
                                                    {...register('country')}
                                                >
                                                    {countries && countries.length > 0 && countries.map((r, i) =>
                                                        <option key={i} value={r.alpha2Code}>{r.name}</option>)
                                                    }
                                                </Form.Control>
                                            </div>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                        {!(totals.amountPaid >= totals.total) ?
                            <> <Form.Label className=" text-uppercase payment-form-headding" style={{ color: `${tripSetting.textColor}` }}>Payment Information</Form.Label>
                                <div className="payment-form-desc-main" style={{ backgroundColor: `${tripSetting.mainColor}` }}>
                                    <Row>
                                        <Col xs={3}>
                                            <Form.Label className="payment-form-label" style={{ color: `${tripSetting.textColor}` }}>CREDIT CARD*</Form.Label>
                                        </Col>
                                        <Col xs={9}>
                                            <Row>
                                                <Col xs={12}>
                                                    <FormGroup className="d-flex payment-form-group mb-1">
                                                        <div className="d-flex flex-column payment-form-inpu-group w-100">
                                                            <Form.Control
                                                                type="text"
                                                                id="cardNumber"
                                                                name="cardNumber"
                                                                required={true}
                                                                maxLength={16}
                                                                placeholder="Card Number*"
                                                                {...register('cardNumber')}
                                                                isInvalid={errors.includes("cardNumber")}
                                                                className="payment-form-input"
                                                            />
                                                            <FormControl.Feedback id="description" type="invalid">
                                                                Please Enter a Card Number
                                                            </FormControl.Feedback>
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={12}>
                                                    <div className="d-flex justify-content-between mb-3">
                                                        <div className="w-100">
                                                            <Form.Control
                                                                type="text"
                                                                id="cardExpiration"
                                                                name="cardExpiration"
                                                                required={true}
                                                                placeholder="Expiration Date* (MMYY)"
                                                                maxLength={4}
                                                                {...register('cardExpiration')}
                                                                isInvalid={errors.includes("cardExpiration")}
                                                                className="form-sub-input"
                                                            />
                                                            <FormControl.Feedback id="description" type="invalid">
                                                                Card Expiration Date is required
                                                            </FormControl.Feedback>
                                                        </div>

                                                        <div className="pl-1">
                                                            <Form.Control
                                                                type="text"
                                                                id="cvv"
                                                                name="cvv"
                                                                required={true}
                                                                placeholder="CVV*"
                                                                maxLength={3}
                                                                {...register('cvv')}
                                                                isInvalid={errors.includes("cvv")}
                                                                className="form-cvv-input"
                                                            />
                                                            <FormControl.Feedback id="description" type="invalid">
                                                                CVV is required
                                                            </FormControl.Feedback>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={9}>
                                        </Col>
                                        <Col xs={12}>
                                            <Row>
                                                <Col xs={3}>
                                                    <Form.Label className="payment-form-label" style={{ color: `${tripSetting.textColor}` }}>DISCOUNT CODE</Form.Label>
                                                </Col>
                                                <Col xs={9}>
                                                    <FormGroup className="d-flex payment-form-group">
                                                        <div className="d-flex flex-row align-items-center payment-form-disscount w-100">
                                                            <Form.Control
                                                                type="text"
                                                                id="discountCode"
                                                                name="discountCode"
                                                                placeholder="Discount Code"
                                                                {...register('discountCode')}
                                                                className="payment-form-input"
                                                            />
                                                            <Button className="m-1 btn-apply"
                                                                style={{
                                                                    backgroundColor: `${loading ? '#646262' : tripSetting.buttonColor}`,
                                                                    borderColor: 'transparent'
                                                                }}
                                                                disabled={loading}>
                                                                APPLY
                                                            </Button>
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div></> : ''}
                        <div>
                            <p className="payment-form-required-title text-white mt-2">*Field Required</p>
                        </div>
                    </form>
                </div>

            </Col >
            <Col md={4} xs={12} className="d-flex flex-column justify-content-between bokking-details-main">
                <h3 className="booking-details-headding">BOOKING {bookingID}</h3>
                <div className="light-gray-bg booking-details-wrapper" style={{ backgroundColor: `${tripSetting.mainColor}` }}>
                    <div className="booking-wrap">
                        {vehicles && vehicles.map((vehicle, i) => {
                            return <ListVehicles
                                key={i}
                                quantity={vehicle.count}
                                description={vehicle.vehicleType}
                                price={vehicle.price}
                                tripSetting={tripSetting}
                            />
                        })
                        }
                    </div>

                    {addonAssignments && addonAssignments.length > 0 &&
                        <div className="booking-wrap">
                            <p className="booking-desc" style={{ color: `${tripSetting.textColor}` }}>ADD ONS & EXTRAS</p>
                            {addonAssignments.map((addon, i) => {
                                return <ListAddons
                                    key={i}
                                    quantity={addon.quantity}
                                    priceTypeId={addon.priceTypeId}
                                    description={addon.description}
                                    price={addon.price}
                                    tripSetting={tripSetting}
                                />
                            })
                            }
                        </div>}
                    {discountCodes && discountCodes.length > 0 &&
                        <div className="booking-wrap">
                            <p className="booking-desc" style={{ color: `${tripSetting.textColor}` }}>DISCOUNT CODE</p>
                            {discountCodes && discountCodes.map(code => {
                                return code.amount > 0 ?
                                    <ListDiscountCodes
                                        key={code.id}
                                        displayName={code.displayName}
                                        amount={code.amount}
                                        tripSetting={tripSetting}
                                    /> : ''
                            })
                            }
                        </div>}
                    <div className="booking-total">
                        <div className="d-flex align-items-center justify-content-between total-wrapper">
                            <span className="total-text">SUBTOTAL:</span>
                            <span className="total-amt">{`$${formatCurrency(totals.subTotal)}`}</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between total-wrapper">
                            <span className="total-text">TAX:</span>
                            <span className="total-amt">{`$${formatCurrency(totals.tax)}`}</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between total-wrapper">
                            <span className="total-text">TOTAL:</span>
                            <span className="total-amt">{`$${formatCurrency(totals.total)}`}</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between total-wrapper">
                            <span className="total-text">PAID:</span>
                            <span className="total-amt">{`$${formatCurrency(totals.amountPaid)}`}</span>
                        </div>
                    </div>
                </div>
                <div className="booking-deatils-box" style={{ backgroundColor: `${tripSetting.mainColor}` }}>
                    <div className="row">
                        <div className="w-100">
                            <div style={{ display: `${(totals.amountPaid < totals.depositAmount) ? 'flex' : 'none'}`, justifyContent: 'space-between', padding: '12px' }}>
                                {(totals.amountPaid < totals.depositAmount) ? <p className="deposite-text-title text-white  h6 mb-0 text-uppercase">
                                    <span className="deposite-text text-white">DEPOSIT DUE:</span>
                                    <span className="deposite-date-title text-white"> {totals.depositDue ? `DUE ON OR BEFORE ${totals.depositDue} ` : ''}</span>
                                </p> : ''}
                                {(totals.amountPaid < totals.depositAmount) ? <p className="form-text-first text-white font-weight-bold h6 mb-0 text-uppercase" >
                                    {`$${totals.depositDueAmount > 0 ? formatCurrency(totals.depositDueAmount) : 0.00}`}
                                </p> : ''}
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '12px' }}>
                                {!(totals.amountPaid >= totals.total) ? <p className="deposite-text-title text-white font-weight-bold h6 mb-0 mt-2 text-uppercase">
                                    <span className="balance-text" style={{ color: `${tripSetting.textColor}` }}>BALANCE DUE:</span>
                                    <span className="deposite-date-title text-white mb-2">  {totals.finalPaymentDue ? `DUE ON OR BEFORE ${totals.finalPaymentDue}` : ''} </span>
                                </p> : ''}
                                {!(totals.amountPaid >= totals.total) ?
                                    <p
                                        className='form-text-box font-weight-bold h6 mb-0 text-uppercase'
                                        style={{ color: `${tripSetting.textColor}`, paddingTop: '6px' }}>
                                        {`$${totals.finalPaymentDueAmount > 0 ? formatCurrency(totals.finalPaymentDueAmount) : 0.00}`}
                                    </p> : ''}
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="form-leftside">
                                <div className="radio-button-group">
                                    {!(totals.amountPaid >= totals.depositAmount) ?
                                        <Form.Check
                                            className="balance-radio"
                                            label="PAY DEPOSIT"
                                            name="payDeposit"
                                            type="radio"
                                            id="payDeposit"
                                            checked={filterByType === "payDeposit"}
                                            onChange={() => setFilterByType("payDeposit")}
                                        /> : ''}
                                    {!(totals.amountPaid >= totals.total) ?
                                        <Form.Check
                                            className="balance-radio"
                                            label="PAY BALANCE DUE"
                                            name="payBalanceDue"
                                            type="radio"
                                            id="payBalanceDue"
                                            checked={filterByType === "payBalanceDue"}
                                            onChange={() => setFilterByType("payBalanceDue")}
                                        /> : ''}
                                    <Form.Check
                                        className="balance-radio"
                                        label="PAY OTHER AMOUNT"
                                        name="payOtherAmount"
                                        type="radio"
                                        id="payOtherAmount"
                                        checked={filterByType === "payOtherAmount"}
                                        onChange={() => setFilterByType("payOtherAmount")}
                                    />
                                </div>

                                <div className="radio-button-form ">
                                    <FormGroup className="mb-0">
                                        <Form.Control
                                            type="number"
                                            name="otherAmount"
                                            placeholder="Enter $ Amount"
                                            disabled={filterByType === "payBalanceDue" || filterByType === "payDeposit"}
                                            key="otherAmount"
                                            {...register('otherAmount')}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="button-wrapper mt-3">
                    <div className="button-of-first">
                        <Button className="mb-2"
                            disabled={!bookingId || (totals.amountPaid >= totals.total)}
                            onClick={handleSubmitPayment}
                            style={{
                                backgroundColor: `${loading ? '#646262' : tripSetting.buttonColor}`,
                                borderColor: 'transparent'
                            }}
                        >
                            <i className="fas fa-lock  mr-2 "></i> SUBMIT PAYMENT
                        </Button>
                    </div>
                </div>
            </Col>
        </Row >
    }

    const confirmation = () => {
        return (
            <><div className="background-box-wrapper" style={{ backgroundColor: `${tripSetting.sectionColor}` }}>
                <p className="booking-title text-white">{`BOOKING ${bookingID}`}</p>
                <div className="light-gray-bg  w-100 text-center inner-box">
                    <div className="images-logo">
                        <Image src={tripSetting.companyLogoUrl} className="m-0 p-0" />
                    </div>
                    <div className="card-payment-box" style={{ backgroundColor: `${tripSetting.textColor}` }}>
                        <i className="fa fa-credit-card-alt text-dark mx-2" /><span className="payment-title">Payment Success</span>
                    </div>
                    <i className="fa fa-thumbs-up text-dark fa-4x mt-3" />
                    <h3 className="title-processed mb-2 mt-2" style={{ color: `${tripSetting.textColor}` }}>
                        Your Payment has been successfully processed
                    </h3>
                    <p className="mb-0 mt-3">Confirmation Number</p>
                    <h4 className="title-amount mb-2" style={{ color: `${tripSetting.textColor}` }} >{formState.id}</h4>
                    <p className="mb-0 mt-3">Amount Paid</p>
                    <h4 className="title-amount mb-5 pb-5" style={{ color: `${tripSetting.textColor}` }}>{`$${formatCurrency(formState.amount)}`}</h4>
                </div>
            </div>
            </>)
    }

    const handleprint = async () => {
        try {
            setLoading(true);
            const pdfData = await printSignedBookingService(bookingId, contarctId, bookingDetails.companyId, user);
            setLoading(false);
            let blob = new Blob([pdfData.data], { type: 'application/pdf' })
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)

            link.download = 'Booking (' + bookingDetails.bookingId + ')'
            link.click()
        }
        catch (e) {
            setLoading(false);
            console.log(e)
        }
    }

    return (
        !loading && tripSetting ?
            <div>
                <>
                    <div className="top-header">
                        <div className="top-header-box d-flex justify-content-between align-items-center" style={{ backgroundColor: `${tripSetting.sectionColor}` }}>
                            <div className="booking-title text-white">
                                {tripSetting && tripSetting.companyLogoUrl ?
                                    <Image src={tripSetting.companyLogoUrl} className="images-logo m-0 p-0" /> : ''}
                                <div className="booking-heading">{`BOOKING ${bookingID}`}</div>
                            </div>
                            <div className="button-head">
                                {!contarctId ? <Button className="text-white"
                                    style={{
                                        backgroundColor: `${loading ? '#646262' : tripSetting.buttonColor}`,
                                        borderColor: 'transparent'
                                    }}>
                                    <i className="fas fa-check-square mr-2"></i>BOOKING PAYMENTS
                                </Button>
                                    :
                                    <Button
                                        className="btn-primary ml-2"
                                        style={{
                                            backgroundColor: `${loading ? '#646262' : tripSetting.buttonColor}`,
                                            borderColor: 'transparent'
                                        }}
                                        onClick={handleprint}>
                                        VIEW SIGNED CONFIRMATION
                                    </Button>}
                            </div>
                        </div>
                        <div className="background-box-color p-3">
                            <div className="background-wrapper" style={{ backgroundColor: `${tripSetting.sectionColor}` }}>
                                <Container fluid>
                                    {Number(formState.stage) === 2 ? confirmation() : paymentForms()}
                                </Container>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end p-4">
                        <Image src={'https://storage.googleapis.com/the-bus-network-bucket/default/Powered-By-TBN-Logo.png'} className="m-0 p-0" />
                    </div>
                </>
            </div> : <Loading loading={loading} />
    )
}

export default React.memo(BookingTransaction, () => true);
