import { Redirect, Route, Switch } from "react-router-dom";
import { useReducer, useEffect } from "react";

// Routes
import MockupRoutes from "./MockupRoutes";
import PublicRoute from './publicRoute';

import Login from '../Containers/views/Login';
import { AuthReducer, initialStateAuth } from "../Context/reducer/authReducer";
import { AuthStateContext } from "../Context"
import ForgotPassword from "../Containers/views/ForgotPassword/index";
import Activation from "../Containers/views/Activation";
import BookingTransaction from "../Components/BookingTransaction";
import BookingConfirmation from "../Components/BookingConfirmation";
import InvoicePayment from "../Components/InvoicePayment";
import BookingDesclaimer from "../Components/BookingDisclaimer";

const Routes = () => {
    const [authUser, authDispatch] = useReducer(AuthReducer, initialStateAuth);
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : ''


    useEffect(() => {
        let publicRouteRegex = /\/p\//gmi;
        if (!publicRouteRegex.exec(window.location.href)) {
            // only reseting user auth if it's coming from routes not containing /p/
            if (localStorage.getItem('user')) {
                if (!sessionStorage.getItem('lastTimeStamp')) {
                    localStorage.clear();
                    window.location.href = '/login';
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])


    return <Switch>

        {/* "p" stands for public routs that don't need auth, can accessed from externally like from email */}
        <Route path="/p/booking-payment" exact component={BookingTransaction} />
        <Route path="/p/booking-confirmation" exact component={BookingConfirmation} />
        <Route path="/p/booking-desclaimer" exact component={BookingDesclaimer} />
        <Route path="/p/pay-invoice" exact component={InvoicePayment} />

        <AuthStateContext.Provider value={{ authUser, authDispatch }}>
            <Route path="/mockup/:path?/:id?" exact>
                <MockupRoutes />
            </Route>
            <Route path="/:id?" exact>
                <PublicRoute path="/login" exact component={Login} />
                <PublicRoute path="/forgot-password" exact component={ForgotPassword} />
                <PublicRoute path="/activation" exact component={Activation} />
                <PublicRoute path="/" exact component={() => <Redirect to="/login" />} />
            </Route>
        </AuthStateContext.Provider>
    </Switch>

}

export default Routes;
