export const initialStateSettings = {
    tripSettings: {
        extraTimeAllowance: 0,
        unloadingTime: 0,
        vehicleSpotTime: 0
    },
    loading: false,
    errorMessage: null
};

export const AccountSettingReducer = (state = initialStateSettings, action) => {
    switch (action.type) {
        case "GET_SETTINGS_SUCCESS":
            return {
                ...state,
                tripSettings: action.payload,
                loading: false
            };
        case "GET_SETTINGS_ERROR":
            return {
                ...state,
                tripSettings: false,
                errorMessage: action.error
            };
        case "EDIT_SETTINGS_SUCCESS":
            return {
                ...state,
                tripSettings: { ...state.tripSettings, ...action.payload},
                loading: false
            };
        case "EDIT_SETTINGS_ERROR":
            return {
                ...state,
                tripSettings: false,
                errorMessage: action.error
            };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};
