import React, { useEffect, useState } from 'react';
import { Navbar, Image } from "react-bootstrap";
import './style.scss';
import { useHistory } from 'react-router-dom';
import { Link } from "react-router-dom";
import { checkPassword } from '../../services/authServices';
import { useAuthState, useAccountState, getTripSettings } from '../../Context';
import UpdatePasswordModal from "../UpdatePasswordModal";
import { toast } from "react-toastify";
import { updateContact } from '../../Context/actions/authActions'
import Loading from "../Loading";


function UserOptions({ handlePasswordToggleModal, handleLogout }) {
    return <>
        <a className="dropdown-item" href="#" onClick={() => handlePasswordToggleModal(true)} ><i className="fas fa-lock mr-2" />Change Password</a>
        <a className="dropdown-item" href="#" onClick={handleLogout}><i className="fas fa-sign-out-alt mr-2" />Logout</a>
    </>
}

// const OptionsDropdown = ({ name, children, icon, activeNav, navChange, uuid, id }) => {
//     return (
//         <div className={`dropdown ${activeNav.nav === uuid ? 'active' : ''}`}>
//             {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
//             <a
//                 className={`nav-link px-4 justify-content-start align-items-center d-flex`}
//                 href="#"
//                 id={id}
//                 role="button" data-toggle="dropdown"
//                 aria-haspopup="true"
//                 aria-expanded="false"
//             >
//                 <span className="justify-content-start align-items-center d-flex">
//                     <i className={`fas ${icon} mr-1`} aria-hidden="true" /> {name}
//                 </span>
//                 <i className="fas fa-caret-down ml-auto" aria-hidden="true" />
//             </a>
//             <div className="dropdown-menu m-0 p-0" aria-labelledby="operations">
//                 {
//                     children.map((menu, index) => (
//                         <Link
//                             className={`dropdown-item text-decoration-none text-wrap ${activeNav.subNav === menu.uuid ? 'active' : ''}`}
//                             key={index}
//                             id={menu.id}
//                             to={`${process.env.PUBLIC_URL}${menu.webUrl}`}
//                             onClick={() => navChange(uuid, menu.uuid)}
//                         >
//                             <i className={`fas ${menu.icon} mr-2`} />{menu.name}
//                         </Link>
//                     ))
//                 }
//             </div>
//         </div>
//     )
// }

const MockupHeader = ({ menuItems = [], logo }) => {
    const [activeNav, setActiveNav] = useState("");
    const user = JSON.parse(localStorage.getItem('user'))
    const [togglePasswordModal, handlePasswordToggleModal] = useState(false);
    const [userAvatarOpen, setUserAvatarOpen] = useState(false);
    const [activeMenu, setActiveMenu] = useState(menuItems.find(menu => menu.active));
    const [activeMenuCount, setActiveMenuCount] = useState(menuItems.reduce((count, item) => ({ ...count, [item.name]: 0 }), {}));
    const { authDispatch } = useAuthState();
    const [loading, setLoading] = useState(false);
    const { AccountsDispatch, tripSettings: { tripSettings } } = useAccountState();
    const history = useHistory();

    useEffect(() => {
        configureActiveNav();
        (async () => {
            setLoading(true)
            await getTripSettings(AccountsDispatch);
            setLoading(false)
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [AccountsDispatch]);

    // hook that set count and set state for active menu items in each menu
    useEffect(() => {
        let count = 0, activeCount = { ...activeMenuCount };
        configureActiveNav()
        // let sortedActiveMenuItemCount = Object.values(activeCount).sort();
        // if(sortedActiveMenuItemCount[sortedActiveMenuItemCount.length - 1] === 0) {
        for (let menuItem of menuItems) {
            count = 0;
            if (menuItem.children.length > 0) {
                // eslint-disable-next-line no-loop-func
                menuItem.children.forEach(subMenu => {
                    if (subMenu.children.length > 0) { // menu with peta(sub-sub) menus
                        subMenu.children.forEach(petaMenu => {
                            count++;
                        })
                    } else { // menu with sub menus
                        count++;
                    }
                })
            }
            activeCount = {
                ...activeCount,
                [menuItem.name]: count
            }
        }
        setActiveMenuCount(activeCount);
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuItems])

    const subNavChange = (nav, subNav = "") => {
        setActiveNav({ nav, subNav });
    }

    const configureActiveNav = () => {
        let uuid = "";
        if (window.location.pathname.includes('profile'))
            uuid = "9bf95200-613b-40d9-a130-ec1fe779b6e5";
        if (window.location.pathname.includes('notifications'))
            uuid = "3109779d-00de-4e51-8ea3-f92e3935f41a";
        if (window.location.pathname.includes('assignments'))
            uuid = "9f8db025-bec6-45a7-8c9e-f58c7e12662b";

        for (let menuItem of menuItems) {
            if (menuItem.children && menuItem.children.length > 0) {
                menuItem.children.forEach(subMenu => {
                    if (subMenu.children.length > 0) { // menu with peta(sub-sub) menus
                        subMenu.children.forEach(petaMenu => {
                            if (window.location.pathname.includes(petaMenu.path) || petaMenu.uuid === uuid) {
                                setActiveMenu(menuItem);
                                setActiveNav({ nav: subMenu.uuid, subNav: petaMenu.uuid });
                            }
                        })
                    } else { // menu with only sub menus
                        if (window.location.pathname.includes(subMenu.path) || subMenu.uuid === uuid) {
                            setActiveMenu(menuItem);
                            setActiveNav({ nav: subMenu.uuid, subNav: "" });
                        }
                    }
                })
            } else if (window.location.pathname.includes(menuItem.path)) {
                setActiveMenu(menuItem);
            }
        }
    }

    const handleUpdatePassword = async (data) => {
        try {
            await updateContact(authDispatch, data, user.id);
            toast.success('Updated Password Successfully');
        } catch (e) {
            toast.error('Update Password Failed');
        }
        // setUserObj({})
        handlePasswordToggleModal(false);
    }

    const handleLogout = async () => {
        await localStorage.clear();
        history.push('/login')
    }
    return (
        !loading ?
            <>
                <div className='headerDiv' style={{ backgroundColor: `${tripSettings.mainColor}` }}>
                    <Navbar
                        variant='inherit'
                        className="navbar navbar-expand-xlg p-0"
                        style={{ backgroundColor: `${tripSettings.mainColor}` }}
                        expand="xlg"
                    >


                        <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01"
                            aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">

                            <i className="fa fa-bars menu-icon" aria-hidden="true" />
                        </button>
                        {/* <Navbar.Toggle className='navbar-toggler' aria-controls="collapse navbar-collapse" >
                            <i className="fa fa-bars menu-icon" aria-hidden="true" />
                        </Navbar.Toggle> */}

                        <div className="ml-2 ml-lg-0 rightSide">
                            <div className={`dropdown px-lg-2 py-2 px-4 ${userAvatarOpen ? 'show' : ''}`} id="avatar"
                                style={{ backgroundColor: `${tripSettings.mainColor}` }}>
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <a
                                    className={`dropdown-toggle justify-content-start align-items-center d-flex text-decoration-none`}
                                    href="#" id="user" role="button"
                                    aria-haspopup="true" aria-expanded={userAvatarOpen}
                                    onClick={() => { setUserAvatarOpen(!userAvatarOpen); }}
                                >
                                    Welcome <span className="font-weight-bold text-uppercase ml-2 adminName" style={{ color: `${tripSettings.textColor}` }}>
                                        {user ? `${user.firstName} ${user.lastName}` : ""}</span>
                                    <span className="justify-content-center align-items-center d-flex rounded-circle ml-3 mr-2" style={{ color: '#000' }}>{user && user.firstName[0].toUpperCase()}</span>
                                </a>
                                <div className={`dropdown-menu dropdown-menu-right p-0 m-0 ${userAvatarOpen ? 'show' : ''}`}
                                    style={{ backgroundColor: `${tripSettings.mainColor}` }}
                                    aria-labelledby="user">
                                    <UserOptions
                                        handlePasswordToggleModal={handlePasswordToggleModal}
                                        handleLogout={handleLogout}
                                    />
                                </div>
                            </div>
                        </div>
                        { // modal that triggers to change password
                            togglePasswordModal
                                ? <UpdatePasswordModal
                                    showCurrent={true}
                                    passwordCheck={checkPassword}
                                    user={user}
                                    updatePassword={handleUpdatePassword}
                                    handlePasswordToggleModal={handlePasswordToggleModal}
                                />
                                : ""
                        }
                    </Navbar>
                </div >
            </> :
            <Loading loading={loading} />
    )
}

export default MockupHeader;
