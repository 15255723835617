import service from './baseService';
import { getCompanyId } from '../config';

export const getTripSettingsService = (companyId) => {
    return service.get(`/customer/accountSettings`, {  params: {  companyId: companyId || getCompanyId() } });
}

export const editAccountSettingsService = (data) => {
    return service.put(
        `/accountSettings`, 
        data, 
        { 
            params: { companyId: getCompanyId() },
            headers : { 'Content-Type':'multipart/form-data' }
        }
    );
}
