import { getBookingsService, getBookingDetailsService,getBookingPaymentDetailsService } from '../../services/bookingsService'

export async function getBookings(dispatch, upcomingFilter = "next30Days", pastFilter = "last30Days") {
    try {
        dispatch({ type: "REQUEST_FETCH_BOOKINGS" });
        let response = await getBookingsService(upcomingFilter, pastFilter);
        if (response.data) {
            dispatch({ type: "FETCH_BOOKINGS_SUCCESS", prior: response.data.prior, upcoming: response.data.upcoming });
            return response.data;
        }
        dispatch({ type: 'FETCH_BOOKINGS_ERROR', error: response.data.errors[0] });
    } catch (error) {
        dispatch({ type: "FETCH_BOOKINGS_ERROR", payload: error.message });
    }
}

export async function getBookingDetails(dispatch, id) {
    try {
        dispatch({ type: "REQUEST_BOOKING_DETAILS" });
        let response = await getBookingDetailsService(id);
        if (response.data) {
            dispatch({ type: "FETCH_BOOKING_DETAILS_SUCCESS", details: response.data.details, addonAssignments: response.data.addonAssignments, dispatches: response.data.dispatches });
            return response.data;
        }
        dispatch({ type: 'FETCH_BOOKING_DETAILS_ERROR', error: response.data.errors[0] });
    } catch (error) {
        dispatch({ type: "FETCH_BOOKING_DETAILS_ERROR", payload: error.message });
    }
}

export async function getBookingPaymentDetails(dispatch, id) {
    try {
        dispatch({ type: "REQUEST_BOOKING_DETAILS" });
        let response = await getBookingPaymentDetailsService(id);
        if (response.data) {
            dispatch({ type: "FETCH_BOOKING_PAYMENT_DETAILS_SUCCESS", payload: response.data });
            return response.data;
        }
        dispatch({ type: 'FETCH_BOOKING_PAYMENT_DETAILS_ERROR', error: response.data.errors[0] });
    } catch (error) {
        dispatch({ type: "FETCH_BOOKING_PAYMENT_DETAILS_ERROR", payload: error.message });
    }
}