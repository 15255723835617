import React, { useState } from 'react';
import { Button, Container, Form, Col, Row, Modal, Spinner } from 'react-bootstrap';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { useAuthState } from "../../Context";
import {getContact, updateContact} from '../../Context/actions/authActions';
import crypto from 'crypto'
const password = 'AvbSkj3BVbf4o6mdlAofDp0/SD0susEWo0pKdmqas',
    salt = 'ABj4PQgf3j5gblQ0iDp0/Gb07ukQWo0a',
    iv = 'aAB1jhPQ89o=f619'

function createHashPassword(){
    let nodeCrypto = crypto.pbkdf2Sync(Buffer.from(password), Buffer.from(salt), 65536, 32, 'sha1');
    return nodeCrypto || nodeCrypto.toString('hex');
};

const key = createHashPassword();

function decrypt(text) {
    try {
        let encryptedText = Buffer.from(text, 'hex');
        let decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from(key), iv);
        let decrypted = decipher.update(encryptedText);
        decrypted = Buffer.concat([decrypted, decipher.final()]);
        if(decrypted) {
            return decrypted.toString();
        } else return
    } catch(error) {
        return
    }
}

const Activation = (props) => {
    const schema = yup.object().shape({
        password: yup
            .string()
            .required("Password is required"),
        confirmPassword: yup
            .string()
            .required("Confirm Password is required")
            .test('password', 'Passwords must match', function(value){
                return this.parent.password === value
            }),
    });

    const { register, handleSubmit } = useForm(schema);
    const [loading, setLoadingState] = useState(false);
    const [error, setError] = useState(false)
    const { authDispatch } = useAuthState()

    const onSubmit = async(data) => {
        const { password = '', confirmPassword = '' } = data;
        setLoadingState(true);
        const id = decrypt(props.location.search.replace('?',''))
        try {
            if(password!==confirmPassword) {
                setError(true)
            } else {
                setError(false)
                if(id) {
                    let response = await getContact(authDispatch, {...data, id});
                    if(response) {
                        const updatedContact = await updateContact(authDispatch,{ password }, response.id);
                        if(updatedContact && updatedContact.length){
                            props.history.push('/login')
                        }
                    }
                }

            }
        } catch (error) {
            console.log(error)
        }
        setLoadingState(false);
    }

    return <div className="mt-5">
        <Container>
            {loading ? <Modal transition="false" show={true} className="loading-modal text-center">
                <Spinner animation="border" variant="primary" />
            </Modal> : ""}
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col xs={12} className="yellow-spot-bg left-spot small-spot alt-spot">
                        <h1 className="green"> Active Account </h1>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col xs={12} lg={8} >
                        <div className="rounded-40 green-alt-bg pr-5 pl-5 text-left card-body">
                                <Form.Group className="row pt-lg-3 mt-2">
                                    <Form.Label className="col-sm-4 col-lg-3 col-form-label green">Password</Form.Label>
                                    <Col sm={8} lg={9}>
                                        <Form.Control
                                            required
                                            name="password"
                                            type="password"
                                            {...register('password')}
                                        />
                                    </Col>
                                </Form.Group>
                            <Form.Group className="row pt-lg-3 mt-2">
                                <Form.Label className="col-sm-4 col-lg-3 col-form-label green">Confirm Password</Form.Label>
                                <Col sm={8} lg={9}>
                                    <Form.Control
                                        name="confirmPassword"
                                        type="password"
                                        required
                                        autoComplete="off"
                                        {...register('confirmPassword')}
                                    />
                                </Col>
                                {error && <p className={'text-small center text-danger'}>Password and Confirm Password must be same!</p>}
                            </Form.Group>
                        </div>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col xs={12} lg={6}>
                        <Button
                            id="login"
                            className="btn-float btn-primary float-right"
                            type="submit"
                            variant="info"
                        >
                            Submit
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Container>
    </div>
}


export default Activation
