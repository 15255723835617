import Service from './baseService';
import { getCompanyId }  from '../config';

export const getInvoicesService = (status) => {
    return Service.get('/customer/invoices', { params: { companyId: getCompanyId(), status }});
}

export const getInvoicePaymentDetailsService = (id, ts) => {
    return Service.get(`/customer/invoices/payment/${id}`, { params: { ts }});
}

export const printInvoiceService = (invoiceId, companyID) => {
    let params = {};
    if (companyID) params.companyId = companyID
    else params.companyId = getCompanyId()

    return Service.post(`/customer/invoices/get-Print/${invoiceId}`, {}, { params, responseType: 'arraybuffer' });
}
