import Service from './baseService';
import { getCompanyId } from '../config';

export const getQuotesService = (allVariations, upcomingFilter, pastFilter) => {
    let params = { companyId: getCompanyId(), upcomingFilter, pastFilter };
    if (allVariations) params.allVariations = allVariations;
    return Service.get('/customer/quotes', { params });
}
export const getQuoteDetailsService = (id) => {
    return Service.get(`/customer/quotes/${id}`, { params: { companyId: getCompanyId() } });
}

export const editQuoteService = (id, data) => {
    return Service.post(`/customer/quotes/edit/${id}`, data, { params: { companyId: getCompanyId() } });
}